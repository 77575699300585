interface Props {
	color?: string
	logoColor?: string
	width?: string
	height?: string
}

/**
 * Component to display the Logo
 *
 * @param color Color of the text
 * @param logoColor Color of the logo
 * @param width width of svg
 * @param height height of svg
 */
export const Logo = ({
	color = '#051D2E',
	logoColor = '#00B7BD',
	width = '538.54',
	height = '92.56'
}: Props) => {
	return (
		<svg
			x="0px"
			y="0px"
			viewBox="0 0 538.54 92.56"
			width={width}
			height={height}
		>
			<style type="text/css"></style>
			<g>
				<g>
					<path
						fill={logoColor}
						d="M91.79,38.19c-0.12-0.67-0.19-1.33-0.34-2C85.87,11.25,61.13-4.45,36.19,1.13
			C11.25,6.7-4.45,31.44,1.13,56.38C1.54,58.23,2.08,60,2.7,61.74C9.93,52.31,43.69,7.6,91.79,38.19z"
					/>
					<path
						fill={logoColor}
						d="M27.71,88.61c8.72,3.84,18.67,5.07,28.68,2.83C73.99,87.51,86.95,74,91.11,57.63
			C63.78,6.97,35.06,58.85,27.71,88.61z"
					/>
				</g>
				<g>
					<path
						fill={color}
						d="M307.14,75.85c-2.89,0-10.16-0.37-13.99-1.31V69.1c4.49,0.47,10.39,0.97,13.99,0.97
			c5.08,0,7.74-1.33,7.74-5.55c0-2.34-1.41-3.83-5.86-6.48l-7.89-4.69c-4.77-2.89-7.97-6.25-7.97-11.17
			c0-8.13,6.09-10.39,14.14-10.39c4.38,0,13.6,1.18,13.6,1.18v5.38c-3.81-0.2-8.3-0.7-13.6-0.7c-4.38,0-7.27,0.55-7.27,4.53
			c0,2.34,1.8,3.83,5.39,5.94l7.73,4.61c6.48,3.83,8.59,7.19,8.59,11.8C321.75,72.49,315.89,75.85,307.14,75.85z"
					/>
					<path
						fill={color}
						d="M413.12,75.85c-11.48,0-17.35-6.94-17.35-22.02s5.86-22.04,17.35-22.04c11.49,0,17.42,6.98,17.42,22.04
			S424.6,75.85,413.12,75.85z M413.12,37.8c-7.27,0-10.47,4.46-10.47,16.02s3.12,16.02,10.47,16.02c7.27,0,10.55-4.47,10.55-16.02
			S420.46,37.8,413.12,37.8z"
					/>
					<path
						fill={color}
						d="M445.52,35.54v-3.2h-6.64V75.3h6.88V41c5.6-3.74,11.13-3.28,12.42-3.28v-5.94
			C452.82,31.72,450.42,32.33,445.52,35.54z"
					/>
					<path
						fill={color}
						d="M271.58,31.79c-4.61,0-8.13,1.17-12.42,3.59v-3.05h-6.88V75.3h6.88V40.77c3.59-1.8,7.27-3.05,10.55-3.05
			c5.94,0,8.59,2.42,8.59,10.31V75.3h6.88V48.04C285.17,37.65,280.95,31.79,271.58,31.79z"
					/>
					<path
						fill={color}
						d="M190.34,31.79c-4.61,0-8.13,1.17-12.42,3.59v-3.05h-6.88V75.3h6.88V40.77c3.59-1.8,7.27-3.05,10.55-3.05
			c5.94,0,8.59,2.42,8.59,10.31V75.3h6.88V48.04C203.93,37.65,199.71,31.79,190.34,31.79z"
					/>
					<polygon
						fill={color}
						points="147.25,23.38 147.25,17.25 114.59,17.25 114.59,75.3 121.7,75.3 121.7,48.19 141.48,48.19
			141.48,42.1 121.7,42.1 121.7,23.38 		"
					/>
					<path
						fill={color}
						d="M521.35,31.79c-11.17,0-17.35,6.31-17.35,22.03s4.98,22.04,17.74,22.04c8.44,0,15.55-1.72,15.55-1.72V68.7
			c0,0-6.87,1.2-15.55,1.13c-6.56-0.05-10.86-1.97-10.86-13.83h27.66v-5.94C538.54,38.97,532.75,31.79,521.35,31.79z M510.88,50.77
			c0-8.44,3.75-12.97,10.47-12.97c6.88,0,10.34,3.47,10.24,12.97H510.88z"
					/>
					<rect
						x="154.62"
						y="32.33"
						fill={color}
						width="6.88"
						height="42.97"
					/>
					<path
						fill={color}
						d="M158.06,25.79c2.36,0,4.27-1.91,4.27-4.27s-1.91-4.27-4.27-4.27c-2.36,0-4.27,1.91-4.27,4.27
			S155.7,25.79,158.06,25.79z"
					/>
					<path
						fill={color}
						d="M227.64,31.79c-5.31,0-13.75,1.21-13.75,1.21v5.41c0,0,7.64-0.74,13.75-0.76c7.99-0.02,8.49,4.02,8.36,11.95
			h-5.78c-13.91,0-18.83,4.07-18.83,13.13s4.65,13.13,12.74,13.13c4.31,0,8.17-1.6,11.88-3.74v3.19h6.88V46.47
			C242.88,37.25,238.5,31.79,227.64,31.79z M236,67.1c-3.75,2.03-6.95,3.13-10.55,3.13c-4.53,0-7.19-1.36-7.19-7.5
			c0-6.24,2.5-8.2,11.95-8.2H236V67.1z"
					/>
					<path
						fill={color}
						d="M380.48,17.25v47.61c0,0-24.92-47.26-25.04-47.61h-9.18V75.3h7.11c0-0.56,0-47.68,0-47.68l25.11,47.68h9.11
			V17.25H380.48z"
					/>
					<path
						fill={color}
						d="M493.8,37.72c1.61,2.14,2.79,3.95,2.79,8.55c0,9.47-5.31,13.87-17.35,13.87c-4.14,0-6.8-0.63-9.06-1.56
			c-2.78,1.49-2.48,4.44,0.47,5.16l11.64,2.97c7.66,1.95,15,3.91,15,11.8c0,7.95-5,12.42-17.81,12.42
			c-12.34,0-17.97-4.75-17.97-12.42c0-2.86,0.65-7.53,4.92-10.63c-6.35-2.59-4.49-10.38-0.94-12.03c-2.42-2.27-3.59-4.87-3.59-9.58
			c0-7.08,3.91-13.94,17.35-13.94h18.9l0.01,5.39L493.8,37.72z M490.41,78.49c0-3.49-2.5-4.6-9.53-6.39l-8.83-2.27
			c0,0-3.83,2-3.83,8.67c0,4.39,2.58,7.11,11.25,7.11C488.38,85.62,490.41,83.23,490.41,78.49z M489.79,46.28
			c0-4.6-2.11-8.47-10.55-8.47c-8.75,0-10.55,3.87-10.55,8.48c0,4.19,1.56,8.48,10.55,8.48C487.68,54.76,489.79,51.7,489.79,46.28z"
					/>
				</g>
			</g>
		</svg>
	)
}
