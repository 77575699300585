import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BeneficiaryType } from 'types/BeneficiaryType'
import { LifeCertificateType } from 'types/LifeCertificateType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { displayFormatDate } from 'utils/displayFormatDate'

import { Button } from 'components/company-components/button/Button'

import { ValidateLCModal } from 'pages/company-pages/ValidateLCModal'

import { CommentListTextItem } from '../CommentListTextItem'
import { LifeCertificateDetails } from '../LifeCertificateDetails'

interface Props {
	beneficiary: BeneficiaryType
	lifeCertificate: LifeCertificateType
	linkSource: string
	fileName: string
	onBeneficiaryChange: () => void
}

/**
 * Case 3 - The life certificate is uploaded manually by the beneficiary
 *
 * @param props.beneficiary The beneficiary
 * @param props.lifeCertificate the LC
 * @param props.linkSource link to LC to open
 * @param props.fileName the name of the file
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const CaseUploaded = ({
	beneficiary,
	lifeCertificate,
	linkSource,
	fileName,
	onBeneficiaryChange
}: Props) => {
	const { t } = useTranslation(['common', 'company'])
	const { isAdmin } = useIsAdminRole()

	const [modalOpen, setModalOpen] = useState(false)

	const displayComment = () => {
		if (
			beneficiary.lifeCertificate === null ||
			beneficiary.lifeCertificate.comment === null
		)
			return null
		return (
			<CommentListTextItem comment={beneficiary.lifeCertificate.comment} />
		)
	}

	/**
	 * If company is logged in add text for waiting for approval
	 */
	const listItemText =
		displayFormatDate(lifeCertificate?.timestamp) +
		(!isAdmin
			? t('company:beneficiary.cert.invalidUploaded.adminAwaiting')
			: '')

	const displayVerificationButton = () => {
		if (!isAdmin) return null
		return (
			<>
				<Button
					text={t('company:beneficiary.cert.invalidUploaded.button')}
					icon={{ name: 'FileSignature' }}
					onClick={() => setModalOpen(true)}
				/>
				{linkSource !== null && linkSource && (
					<ValidateLCModal
						modalOpen={modalOpen}
						onClose={() => setModalOpen(false)}
						base64String={linkSource}
						fileName={fileName}
						beneficiaryName={beneficiary.name}
						nin={beneficiary.nationalIdentityNumber}
						onRegisterSuccess={onBeneficiaryChange}
					/>
				)}
			</>
		)
	}

	return (
		<LifeCertificateDetails
			status={t('common:status.invalid')}
			listItems={[
				{
					title: t('company:beneficiary.cert.invalidUploaded.title'),
					text: listItemText
				}
			]}
			beneficiary={beneficiary}
		>
			{displayComment()}
			{displayVerificationButton()}
		</LifeCertificateDetails>
	)
}
