import { ReactNode } from 'react'
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { UnstyledButton } from 'components/company-components/button/UnstyledButton'

import { LOGIN_PATH } from 'navigation/navigationURLs'

/**
 * Login page, triggers a login window using Active Directory
 */
export const LogoutButton = ({
	text,
	children,
	...rest
}: {
	children?: ReactNode
	text?: string
}) => {
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0])
	const isAuthenticated = useIsAuthenticated()
	const navigate = useNavigate()

	const handleLogout = async () => {
		if (!isAuthenticated) return
		try {
			instance.logoutPopup({ account })
			console.log('Logged out successfully! Navigating to login page')
			navigate(LOGIN_PATH)
		} catch (e) {
			console.error(e)
			toast.error(<div className="dismissToast">Klarte ikke å logge ut</div>)
		}
	}

	return (
		<UnstyledButton onClick={handleLogout} {...rest}>
			{text ?? children}
		</UnstyledButton>
	)
}
