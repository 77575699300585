/**
 * Function to check that a string is a valid uuid
 *
 * @param uuid the string to test
 *
 * @return a boolean for if the uuid is valid
 */
export const isValidUUID = (uuid: string): boolean => {
	const regex =
		/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
	return regex.test(uuid)
}
