import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { LES_MER_OM_BANKID_ID_SJEKK_URL } from 'navigation/externalLinkURLs'
import { ELECTRONIC_PROCESS_START_PAGE_PATH } from 'navigation/navigationURLs'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'

import { BackAndForwardButton } from '../BackAndForwardButtons'
import { InformationBox } from '../InformationBox'
import { AlternativeSubmissionButton } from './AlternativeSubmissionButton'
import { Strong } from 'components/text/Strong'

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ContentWrapper = styled.div`
	margin-top: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: flex;
		justify-content: space-between;
		margin-top: 2vh;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const InformationBoxLeft = styled(InformationBox)`
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 48%;
		margin-bottom: 0;
	}
`

const InformationBoxWrapperRight = styled.div`
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 48%;
	}
`

const ButtonWrapper = styled.div``

interface Props {
	onClickSwitchTab: (tabIndex: number, fromTab?: number) => void
}

/**
 * Details about step 1 in the walk-through process
 *
 * @param props.onClickSwitchTab 	function that navigates to another tab index page. Optional parameter fromTab is to let the
 * 									browser know which page we came from so it moves back to the correct page
 */
export const Tab1 = ({ onClickSwitchTab }: Props) => {
	const navigate = useNavigate()
	const { t } = useTranslation('private')

	// Get company name and uuid from the url data
	const { companyName, uuid } = useParams()

	return (
		<>
			<TopWrapper>
				<HeaderText1>{t('tabs.1.title')}</HeaderText1>
				<ContentWrapper>
					<InformationBoxLeft
						icon={{ name: 'BankIdLogo' }}
						title={t('tabs.1.bankID.title')}
						text={
							<Trans i18nKey="private:tabs.1.bankID.text">
								Legitimering og innhenting av leveattest skjer
								elektronisk gjennom appen. BankID sin ID-sjekk tjeneste
								kan benyttes i hele verden{' '}
								<Strong>uten norsk bankforbindelse.</Strong> Last ned
								BankID-appen fra App Store eller Play Store. Når du har
								lastet ned appen, trenger du{' '}
								<Strong>
									ikke å bruke mer tid på å aktivere den. Du finner mer
									informasjon om hvordan du går frem i appen.
								</Strong>
							</Trans>
						}
					/>
					<InformationBoxWrapperRight>
						<InformationBox
							icon={{ name: 'PhonePrivate' }}
							title={t('tabs.1.phone.title')}
							text={t('tabs.1.phone.text')}
							externalLinkText={t('tabs.1.phone.linkText')}
							href={LES_MER_OM_BANKID_ID_SJEKK_URL}
						/>
						<InformationBox
							icon={{ name: 'Passport' }}
							title={t('tabs.1.identification.title')}
							text={t('tabs.1.identification.text')}
						/>
					</InformationBoxWrapperRight>
				</ContentWrapper>
				<AlternativeSubmissionButton
					onClick={() => onClickSwitchTab(5, 1)}
					text={t('private:tabs.alternative')}
				/>
			</TopWrapper>
			<ButtonWrapper>
				<BackAndForwardButton
					hasForwardButton
					hasBackButton
					backButtonText={t('tabs.nav.backFirst')}
					onClickBackButton={() =>
						navigate(
							`${ELECTRONIC_PROCESS_START_PAGE_PATH}/${companyName}/${uuid}`
						)
					}
					onClickForwardButton={() => {
						onClickSwitchTab(2)
					}}
				/>
			</ButtonWrapper>
		</>
	)
}
