import { ReactNode } from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { Header } from './Header'
import { Footer } from './Footer'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	max-width: 100%;
`

const Body = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-block: 10px;
	margin-inline: 20px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-block: 5vh;
		margin-inline: 15%;
	}
`

interface Props {
	children: ReactNode
	hasCompany?: boolean
}

/**
 * Layout component to display the Header and body for the private pages
 *
 * @param props.children React children
 * @param props.hasCompany Optional flag if the header should display the company name
 */
export const Layout = ({ children, hasCompany = false }: Props) => {
	return (
		<Wrapper>
			<Header hasCompany={hasCompany} />
			<Body>{children}</Body>
			<Footer />
		</Wrapper>
	)
}

interface LayoutOutletProps {
	hasCompany?: boolean
}

/**
 * Layout outlet component to display the layout with outlet.
 * Layout component for use in a React Router Route component. Renders an outlet instead of children.
 *
 * @param props.hasCompany Optional flag if the header should display the company name
 */
export const LayoutOutlet = ({ hasCompany = false }: LayoutOutletProps) => {
	return (
		<Layout hasCompany={hasCompany}>
			<Outlet />
		</Layout>
	)
}
