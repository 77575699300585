import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/private-components/button/Button'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'
import { buttonColorDark } from 'styles/button'

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`

const ButtonWrapper = styled.div`
	width: 45%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 152px;
	}
`

const ForwardButton = styled(Button)`
	&:hover {
		${(props) => props.disabled && buttonColorDark}
	}
`

interface Props {
	hasBackButton?: boolean
	backButtonText?: string
	onClickBackButton?: () => void
	hasForwardButton?: boolean
	disableForwardButton?: boolean
	onClickForwardButton?: () => void
}

/**
 * Component to display the back and forward button at the bottom of the
 * walk through pages before accessing the Bank ID app.
 *
 * @param props.hasBackButton If the component has a back button
 * @param props.backButtonText Text of the back button - default set to 'Forrige'
 * @param props.onClickBackButton On click function for back button
 * @param props.hasForwardButton If the component has a forward button
 * @param props.disableForwardButton If the component should have the forward button disabled
 * @param props.onClickForwardButton On click function for forward button
 */
export const BackAndForwardButton = ({
	hasBackButton,
	backButtonText,
	onClickBackButton,
	hasForwardButton,
	disableForwardButton,
	onClickForwardButton
}: Props) => {
	const { t } = useTranslation('private')
	const backText = backButtonText ?? t('tabs.nav.back')
	return (
		<ButtonContainer>
			<ButtonWrapper>
				{hasBackButton && (
					<Button
						text={backText ?? 'Neste'}
						fillWidth
						color="white"
						onClick={onClickBackButton}
					/>
				)}
			</ButtonWrapper>
			<ButtonWrapper>
				{hasForwardButton && (
					<ForwardButton
						text={t('tabs.nav.next')}
						fillWidth
						onClick={onClickForwardButton}
						disabled={disableForwardButton}
					/>
				)}
			</ButtonWrapper>
		</ButtonContainer>
	)
}
