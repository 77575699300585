import { ReactNode } from 'react'
import { useMsal } from '@azure/msal-react'
import { toast } from 'react-toastify'

import { loginRequest } from 'auth/authConfig'

import { UnstyledButton } from 'components/company-components/button/UnstyledButton'

/**
 * Login page, triggers a login window using Active Directory
 */
export const UnstyledLoginButton = ({
	text,
	children,
	...rest
}: {
	children?: ReactNode
	text?: string
}) => {
	const { instance } = useMsal()

	const handleLogin = async () => {
		try {
			instance.loginPopup(loginRequest)
			console.log('Logged in successfully!')
		} catch (e) {
			console.error(e)
			toast.error('Klarte ikke å logge inn')
		}
	}

	return (
		<UnstyledButton onClick={handleLogin} {...rest}>
			{text ?? children}
		</UnstyledButton>
	)
}
