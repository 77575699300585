import styled, { css, keyframes } from 'styled-components'

import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'

import { COLOR_MAIN_DARK_BLUE } from 'styles/baseColors'
import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

const SubHeaderText = styled(BaseSubHeaderText)`
	margin-bottom: 10px;
`

const loadingAnimation = keyframes`
  	0% {
    	transform: rotate(0deg);
  	}
  	100% {
    	transform: rotate(360deg);
  	}
`

interface IStyledSpinner {
	size: number
	speed: number
}

const Spin = styled.div<IStyledSpinner>`
	${(props) => css`
		height: ${props.size}em;
		width: ${props.size}em;
	`};
	background: ${(props) => props.color};
	background: ${(props) => `linear-gradient(
		to top right,
		${props.color} 30%,
		rgba(255, 255, 255, 0) 92%
	);`};
	border-radius: 50%;
	font-size: 10px;
	margin: 0;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	cursor: progress;

	&:before {
		background: ${(props) => props.color};
		border-radius: 100% 0 0 0;
		content: '';
		height: 50%;
		left: 0;
		position: absolute;
		top: 0;
		width: 50%;
	}

	&:after {
		background: var(--color-white);
		border-radius: 50%;
		bottom: 0;
		content: '';
		height: 75%;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
		width: 75%;
	}

	${(props) => css`
		@media (prefers-reduced-motion: no-preference) {
			animation: ${loadingAnimation} ${props.speed}s infinite linear;
		}
	`};

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin: 50px auto;
	}
`
const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

interface Props {
	color?: string
	size?: number
	speed?: number
	title?: string
}

/**
 * Spinner component
 *
 * @param props.color the color
 * @param props.size the size
 * @param props.speed the speed
 * @param props.title the title
 */
export const Spinner = ({
	color = COLOR_MAIN_DARK_BLUE,
	size = 10,
	speed = 2,
	title
}: Props) => {
	return (
		<Wrapper>
			{title && <SubHeaderText>{title}</SubHeaderText>}
			<Spin color={color} size={size} speed={speed} />
		</Wrapper>
	)
}
