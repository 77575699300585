/**
 * Transforms a time delta into a readable format
 *
 * @param deltaSeconds - A time in milliseconds
 *
 * @example
 * transformTimeDeltaIntoReadableChunks(1996)
 * //response:
 * {
 * 	days: 0,
 * 	hours: 0,
 * 	minutes: 33,
 * 	seconds: 16
 * }
 */
export const transformTimeDeltaIntoReadableChunks = (deltaSeconds: number) => {
	const delta = deltaSeconds * 1000
	const days = Math.floor(delta / MS_IN_DAY)
	const hours = Math.floor((delta % MS_IN_DAY) / MS_IN_HOUR)
	const minutes = Math.floor((delta % MS_IN_HOUR) / MS_IN_MINUTE)
	const seconds = Math.floor((delta % MS_IN_MINUTE) / MS_IN_SECOND)

	return { days, hours, minutes, seconds }
}

const MS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MS_IN_MINUTE = MS_IN_SECOND * SECONDS_IN_MINUTE
const MINUTES_IN_HOUR = 60
const MS_IN_HOUR = MS_IN_MINUTE * MINUTES_IN_HOUR
const HOURS_IN_DAY = 24
const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY
