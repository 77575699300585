import React from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { BeneficiaryType } from 'types/BeneficiaryType'
import {
	AddOrEditBeneficiaryType,
	AddOrEditBeneficiaryInputType
} from 'types/AddOrEditBeneficiaryType'

import { Spinner } from 'components/Spinner'
import { Button as BaseButton } from 'components/company-components/button/Button'
import { TextInput } from 'components/inputs/TextInput'
import { CountryInput } from 'components/inputs/CountryInput/CountryInput'
import { PhoneNumberInput } from 'components/inputs/PhoneNumberInput'
import { Text } from 'components/text/Text'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'

import { isValidNIN } from 'utils/validators/isValidNIN'
import { isValidEmail } from 'utils/validators/isValidEmail'
import { setBeneficiaryValuesBeforeUpdate } from 'utils/setBeneficiaryValuesBeforeUpdate'

import { buttonColorLight } from 'styles/button'

const Form = styled.form`
	width: 100%;
	padding-inline: 50px;
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	.full {
		width: 100%;
	}

	.left-wrapper {
		width: 60%;
	}

	.right-wrapper {
		width: 30%;
	}
`

const SubHeaderTextContact = styled(BaseSubHeaderText)`
	margin-top: 30px;
	margin-bottom: 5px;
`

const RowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: 30px;

	> * {
		width: 45%;
	}
`

interface IButtonWrapper {
	buttonPosition: 'center' | 'flex-end'
}

const ButtonWrapper = styled.div<IButtonWrapper>`
	display: flex;
	justify-content: ${(props) => props.buttonPosition};
	width: 100%;
	margin-top: 30px;
`

interface IButton {
	minWidth: string
}

const Button = styled(BaseButton)<IButton>`
	min-width: ${(props) => props.minWidth};

	&:hover {
		${(props) => props.disabled && buttonColorLight}
	}
`

interface Props {
	onSave: (beneficiary: AddOrEditBeneficiaryType) => void
	beneficiary: BeneficiaryType | null
	buttonPosition: 'center' | 'flex-end'
	buttonMinWidth: string
	buttonText: string
	isLoading: boolean
	companyName: string
	isEdit: boolean
}

/**
 * Component to display the fields for adding or editing beneficiary info
 *
 * @param props.onSave function to handle onsave
 * @param props.beneficiary the beneficiary
 * @param props.buttonPosition position of the button
 * @param props.buttonMinWidth the min width of submit button
 * @param props.buttonText the button text
 * @param props.isLoading flag for if loading or not
 * @param props.companyName the name of the company
 * @param props.isEdit flag for the usage of the content
 */
export const AddOrEditBeneficiaryContent = ({
	onSave,
	beneficiary,
	buttonPosition,
	buttonMinWidth,
	buttonText,
	isLoading,
	companyName,
	isEdit
}: Props) => {
	const { t } = useTranslation('company')

	const { handleSubmit, watch, control } =
		useForm<AddOrEditBeneficiaryInputType>({
			defaultValues: {
				beneficiaryName: beneficiary?.name ?? '',
				nationalIdentityNumber: beneficiary?.nationalIdentityNumber ?? '',
				country: beneficiary?.contact?.country ?? '',
				streetAddress: beneficiary?.contact?.streetAddress ?? '',
				postcode: beneficiary?.contact?.postcode ?? '',
				city: beneficiary?.contact?.city ?? '',
				phoneNumber: beneficiary?.contact?.phoneNumber,
				emailAddress: beneficiary?.contact?.emailAddress ?? ''
			}
		})

	// Watch the input values
	const beneficiaryName: string = watch('beneficiaryName') ?? ''
	const nin: string = watch('nationalIdentityNumber') ?? ''
	const country: string = watch('country') ?? ''
	const address: string = watch('streetAddress') ?? ''
	const postCode: string = watch('postcode') ?? ''
	const city: string = watch('city') ?? ''
	const phone: string = watch('phoneNumber') ?? ''
	const email: string = watch('emailAddress') ?? ''

	const onSubmit = (data: unknown) => {
		const inputs: AddOrEditBeneficiaryType = data as AddOrEditBeneficiaryType

		const emailSelectedAndInvalid =
			inputs.emailAddress !== '' && !isValidEmail(inputs.emailAddress)

		const inputNumber = inputs.phoneNumber ?? ''
		const phoneNumberSelectedAndInvalid =
			inputNumber !== '' && !(inputNumber[0] === '+')
		const atLeastOneLocationProvided =
			inputs.city !== '' ||
			inputs.country !== '' ||
			inputs.postcode !== '' ||
			inputs.streetAddress !== ''
		const notAllLocationsProvided =
			inputs.city === '' ||
			inputs.country === '' ||
			inputs.postcode === '' ||
			inputs.streetAddress === ''

		const atLeastOneButNotAllLocationFieldsAreProvided =
			atLeastOneLocationProvided && notAllLocationsProvided
		if (!isValidNIN(inputs.nationalIdentityNumber)) {
			toast.error(
				<div className="dismissToast">
					{t('dash.register.modal.form.errors.nin')}
				</div>
			)
		}
		if (emailSelectedAndInvalid) {
			toast.error(
				<div className="dismissToast">
					{t('dash.register.modal.form.errors.email')}
				</div>
			)
		}
		if (phoneNumberSelectedAndInvalid) {
			toast.error(
				<div className="dismissToast">
					{t('dash.register.modal.form.errors.phone')}
				</div>
			)
		}
		if (atLeastOneButNotAllLocationFieldsAreProvided) {
			toast.error(
				<div className="dismissToast">
					{t('dash.register.modal.form.errors.mustHaveAllOrNoLocations')}
				</div>
			)
		}
		if (
			isValidNIN(inputs.nationalIdentityNumber) &&
			!emailSelectedAndInvalid &&
			!phoneNumberSelectedAndInvalid &&
			!atLeastOneButNotAllLocationFieldsAreProvided
		) {
			onSave(setBeneficiaryValuesBeforeUpdate(inputs, companyName))
		}
	}

	const onError = (e: any) => {
		console.log({ e })
		console.error(e)
		toast.error(
			Object.values<any>(e)?.[0]?.message ||
				t('dash.register.modal.form.errors.generic')
		)
	}

	/**
	 * Function to check if the button should be disabled.
	 * It is disabled until the required fields (name, dob, nin) and at least one of
	 * the contact information fields (full address, email or phone number) are filled out
	 */
	const getButtonDisabled = () => {
		// Const add checks that the date is valid
		const isRequiredValid = beneficiaryName !== '' && nin !== ''
		const isAddressValid =
			country !== '' && address !== '' && postCode !== '' && city !== ''

		const isPhoneNumberValid =
			phone !== '' && phone !== null && phone[0] === '+'

		const isEmailValid = email !== ''
		const isContactInfoValid =
			isAddressValid || isPhoneNumberValid || isEmailValid

		return !(isRequiredValid && isContactInfoValid)
	}

	const displayNinBox = () => {
		if (isEdit) return null
		return (
			<Controller
				name="nationalIdentityNumber"
				control={control}
				rules={{
					required: t('dash.register.modal.form.nin.required'),
					minLength: {
						value: 11,
						message: t('dash.register.modal.form.nin.min')
					},
					maxLength: {
						value: 11,
						message: t('dash.register.modal.form.nin.max')
					}
				}}
				render={({ field: { value, onChange, name } }) => (
					<TextInput
						title={t('dash.register.modal.form.nin.title')}
						placeholder={t('dash.register.modal.form.nin.placeholder')}
						wrapperProps={{ className: 'right-wrapper' }}
						maxLength={11}
						value={value}
						onChange={onChange}
						name={name}
					/>
				)}
			/>
		)
	}

	if (isLoading) {
		return <Spinner />
	}

	return (
		<Form onSubmit={handleSubmit(onSubmit, onError)}>
			<TopWrapper>
				<Controller
					name="beneficiaryName"
					control={control}
					rules={{ required: t('dash.register.modal.form.name.required') }}
					render={({ field: { value, onChange, name } }) => (
						<TextInput
							title={t('dash.register.modal.form.name.title')}
							placeholder={t(
								'dash.register.modal.form.name.placeholder'
							)}
							wrapperProps={{
								className: isEdit ? 'full' : 'left-wrapper'
							}}
							value={value}
							onChange={onChange}
							name={name}
						/>
					)}
				/>
				{displayNinBox()}
			</TopWrapper>
			<SubHeaderTextContact as="h2">
				{t('dash.register.modal.form.contact.title')}
			</SubHeaderTextContact>
			<Text>{t('dash.register.modal.form.contact.text')}</Text>
			<RowWrapper>
				<Controller
					name="country"
					control={control}
					render={({ field: { value, onChange, name } }) => (
						<CountryInput
							title={t('dash.register.modal.form.country.title')}
							value={value}
							onSelect={onChange}
							name={name}
						/>
					)}
				/>
				<Controller
					name="streetAddress"
					control={control}
					render={({ field: { value, onChange, name } }) => (
						<TextInput
							title={t('dash.register.modal.form.address.title')}
							placeholder={t(
								'dash.register.modal.form.address.placeholder'
							)}
							value={value}
							onChange={onChange}
							name={name}
						/>
					)}
				/>
			</RowWrapper>
			<RowWrapper>
				<Controller
					name="postcode"
					control={control}
					render={({ field: { value, onChange, name } }) => (
						<TextInput
							title={t('dash.register.modal.form.zip.title')}
							placeholder={t('dash.register.modal.form.zip.placeholder')}
							value={value}
							onChange={onChange}
							name={name}
						/>
					)}
				/>
				<Controller
					name="city"
					control={control}
					render={({ field: { value, onChange, name } }) => (
						<TextInput
							title={t('dash.register.modal.form.city.title')}
							placeholder={t(
								'dash.register.modal.form.city.placeholder'
							)}
							value={value}
							onChange={onChange}
							name={name}
						/>
					)}
				/>
			</RowWrapper>
			<RowWrapper>
				<Controller
					name="phoneNumber"
					control={control}
					render={({ field: { value, onChange, name } }) => (
						<PhoneNumberInput
							title={t('dash.register.modal.form.phone.title')}
							placeholder="+"
							onChange={(value) => {
								onChange(value)
							}}
							value={value ?? undefined}
							name={name}
						/>
					)}
				/>
				<Controller
					name="emailAddress"
					control={control}
					render={({ field: { value, onChange, name } }) => (
						<TextInput
							title={t('dash.register.modal.form.email.title')}
							placeholder={t(
								'dash.register.modal.form.email.placeholder'
							)}
							value={value}
							onChange={onChange}
							name={name}
						/>
					)}
				/>
			</RowWrapper>
			<ButtonWrapper buttonPosition={buttonPosition}>
				<Button
					minWidth={buttonMinWidth}
					text={buttonText}
					type="submit"
					disabled={getButtonDisabled()}
				/>
			</ButtonWrapper>
		</Form>
	)
}
