import React, { useState } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { postUploadFileByUUID } from 'api/services/LifeCertificateService'

import { Modal } from 'components/common/Modal'
import { Spinner } from 'components/Spinner'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Button as BasePrivateButton } from 'components/private-components/button/Button'
import { Strong } from 'components/text/Strong'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'
import { buttonStyleLight } from 'styles/button'

import { ErrorWrapper } from '../ErrorWrapper'

const HeaderText2 = styled(BaseHeaderText2).attrs({ as: 'p' })`
	text-align: center;
`

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-block: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: flex;
		justify-content: space-around;
		flex-direction: row;
		margin-top: 40px;
		margin-bottom: 20px;
	}
`

const ModalHeaderText = styled(BaseHeaderText1)`
	margin-bottom: 20px;
	text-align: center;
`

const ModalButton = styled(BasePrivateButton)`
	margin-top: 10px;
	padding-block: 12px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		min-width: 240px;
	}
`

const ConfirmButton = styled(ModalButton)`
	${buttonStyleLight}
`

const Button = styled(BasePrivateButton)`
	${buttonStyleLight}
	margin-top: 30px;
	padding-block: 12px;
	width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 400px;
	}
`

interface Props {
	files: File[]
	onReset: () => void
	onSuccess: () => void
	uuid: string
	nin: string
}

/**
 * Component to split the send button and the modal out of the file uploader component
 *
 * @param props.files files to upload
 * @param props.uuid uuid of the request
 * @param props.nin national identity number of the beneficiary
 * @param props.onReset function to handle resetting
 * @param props.onSuccess function to handle success
 */
export const UploadButtonAndModalPublic = ({
	files,
	uuid,
	nin,
	onReset,
	onSuccess
}: Props) => {
	const { t } = useTranslation(['public', 'common'])
	// To handle loading
	const [isLoading, setIsLoading] = useState(false)

	// To handle error
	const [errorMessage, setErrorMessage] = useState('')

	// To handle the modal
	const [modalOpen, setModalOpen] = useState(false)

	const closeModal = () => {
		setModalOpen(false)
		setErrorMessage('')
	}

	const reset = () => {
		onReset()
		setModalOpen(false)
	}

	/**
	 * Function to validate that a file is of type pdf
	 * @param file file to validate
	 * @return boolean
	 */
	const isFilePDF = (file: File) => {
		const validType = ['application/pdf']
		return validType.indexOf(file.type) !== -1
	}

	const onClickUploadFile = () => {
		// Get the invalid files
		const invalidFiles = files.filter((f: File) => {
			return !isFilePDF(f)
		})

		if (invalidFiles.length === 0) {
			// Upload the files
			setIsLoading(true)
			postUploadFileByUUID(uuid, files)
				.then(() => {
					reset()
					onSuccess()
					setIsLoading(false)
				})
				.catch((err) => {
					console.log({ err })
					console.error(err)
					setErrorMessage(t('common:fileUploader.backendError'))
					setIsLoading(false)
				})
		}
	}

	const displayModalContent = () => {
		if (isLoading) {
			return <Spinner title={t('common:fileUploader.sending')} />
		}
		return (
			<>
				<ModalHeaderText>{t('public:upload.confirm')}</ModalHeaderText>
				{/* TODO - make the correct words bold */}
				<HeaderText2>
					<Trans
						i18nKey="public:upload.modal.content"
						count={files.length}
					>
						Du sender nå inn <Strong>én</Strong> PDF-fil som leveattest på
						fødselsnummer <Strong>{{ nin } as any}</Strong>. Vennligst
						sjekk at fødselsnummeret stemmer før du bekrefter innsending.
					</Trans>
				</HeaderText2>
				<ButtonWrapper>
					<ModalButton
						text={t('public:upload.cancel')}
						color="white"
						onClick={closeModal}
					/>
					<ConfirmButton
						text={t('public:upload.confirm')}
						onClick={onClickUploadFile}
					/>
				</ButtonWrapper>
				{errorMessage !== '' && <ErrorWrapper errorText={errorMessage} />}
			</>
		)
	}

	return (
		<>
			<Button
				text={t('public:upload.send')}
				onClick={() => setModalOpen(true)}
			/>
			<Modal isOpen={modalOpen} onClose={closeModal}>
				{displayModalContent()}
			</Modal>
		</>
	)
}
