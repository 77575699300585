import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Modal as BaseModal } from 'components/common/Modal'
import { Text as BaseText } from 'components/text/Text'
import { Icon as BaseIcon } from 'components/icon/Icon'

const Modal = styled(BaseModal)`
	padding-inline: 10px;
`

const ModalContent = styled.div`
	padding-inline: 20px;
`

const Text = styled(BaseText)`
	font-weight: var(--font-weight-normal);
	font-family: var(--font-family-opensans);
`

const ATextMobile = styled.a`
	color: var(--color-main-dark_blue);
	text-decoration: none;
`

const AText = styled.a`
	color: var(--color-main-dark_blue);
`

const PrivateSubHeaderText = styled(BaseSubHeaderText)`
	margin-bottom: 10px;
`

const BottomWrapper = styled.div`
	display: flex;
	margin-top: 20px;
`

const Icon = styled(BaseIcon)`
	font-size: 22px;
	margin-right: 5px;
	color: var(--color-main-dark_blue);
`

const Bold = styled.strong`
	font-weight: var(--font-weight-almost-bold);
`

interface Props {
	isOpen: boolean
	onClose: () => void
}

/**
 * Component to render a modal with information about how to contact FNF.
 *
 * @param props.isOpen flag to control if the modal is open or not
 * @param props.onClose function to be executed when the modal closes
 */
export const HelpModal = ({ isOpen, onClose }: Props) => {
	const { t } = useTranslation('common')

	const email = process.env.REACT_APP_CONTACT_EMAIL ?? ''
	const phone = process.env.REACT_APP_CONTACT_PHONE ?? ''
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalContent>
				<PrivateSubHeaderText as="h1">
					{t('footer.title')}
				</PrivateSubHeaderText>
				<Text>
					<Trans i18nKey="common:footer.phone">
						Telefon:
						<ATextMobile href={`tel:${phone.split(' ').join('')}`}>
							{{ phone } as any}
						</ATextMobile>
					</Trans>
				</Text>
				<Text>
					<Trans i18nKey="common:footer.email">
						Epost:
						<AText href={`mailto:${email}`}>{{ email } as any}</AText>
					</Trans>
				</Text>
				<BottomWrapper>
					<Icon name="CustomerService" />
					<div>
						<Text>
							<Bold>{t('footer.customerService')}</Bold>
						</Text>
						<Text>{t('footer.openingHours')}</Text>
					</div>
				</BottomWrapper>
			</ModalContent>
		</Modal>
	)
}
