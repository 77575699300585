import { useTranslation } from 'react-i18next'

import { ListTextItem } from '../ListTextItem'

interface Props {
	comment: string | null
}

/**
 * Displays the List Text Item for the comment
 *
 * @param comment the comment to display
 */
export const CommentListTextItem = ({ comment }: Props) => {
	const { t } = useTranslation(['common', 'company'])

	return (
		<ListTextItem
			title={t('company:beneficiary.cert.invalidMarked.comment.title')}
			text={
				comment === null || comment === ''
					? t('company:beneficiary.cert.invalidMarked.comment.none')
					: comment
			}
			color="white"
		/>
	)
}
