import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
	putInvalidateLC,
	putValidateLC
} from 'api/services/LifeCertificateService'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { Text as BaseText } from 'components/text/Text'
import { ButtonText } from 'components/company-components/text/ButtonText'
import { Modal as BaseModal } from 'components/common/Modal'
import { AButton as BaseAButton } from 'components/company-components/button/AButton'
import { Icon as BaseIcon } from 'components/icon/Icon'
import { Button as BaseButton } from 'components/company-components/button/Button'
import { Spinner } from 'components/Spinner'

import { mapBase64StringToPdfUrl } from 'utils/fileUtils/mapBase64StringToPdfUrl'

import { buttonColorLight } from 'styles/button'

import { ValidationButtons as BaseValidationButtons } from './ValidationButtons'
import { CommentPage } from './CommentPage'

interface IModal {
	minWidth: string
	minHeight: string
}

const Modal = styled(BaseModal)<IModal>`
	position: relative;
	min-width: ${(props) => props.minWidth};
	min-height: ${(props) => props.minHeight};
`

const Text = styled(BaseText)`
	margin-top: 15px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 20px;
`

const Icon = styled(BaseIcon)`
	font-size: 25px;
`

const ModalContent = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
`
const AButton = styled(BaseAButton)`
	padding-block: 7px;
`

const Button = styled(BaseButton)`
	min-width: 200px;
	padding-block: 7px;

	&:hover {
		${(props) => props.disabled && buttonColorLight}
	}
`

const ValidationButtons = styled(BaseValidationButtons)`
	margin-top: 10px;
	margin-bottom: 30px;
`

const Embed = styled.embed`
	width: 100%;
	height: 80%;
	border: solid var(--color-black) 0.1px;
	margin-bottom: 15px;
`

interface Props {
	modalOpen: boolean
	onClose: () => void
	base64String: string
	fileName: string
	beneficiaryName: string
	nin: string
	onRegisterSuccess: () => void
}

/**
 * The Modal where a user can register a LC as valid or invalid
 *
 * @param props.modalOpen flag for if it is open or closed
 * @param props.onClose function when closing modal
 * @param props.base64String base64String for the document
 * @param props.fileName the name of the pdf file
 * @param props.beneficiaryName name of the beneficiary
 * @param props.nin the national identity number of the beneficiary
 * @param props.onRegisterSuccess optional function to handle the success
 */
export const ValidateLCModal = ({
	modalOpen,
	onClose,
	base64String,
	fileName,
	beneficiaryName,
	nin,
	onRegisterSuccess
}: Props) => {
	const { isAdmin } = useIsAdminRole()

	const [isValid, setIsValid] = useState<boolean>()
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation('company')

	const [markedAsInvalid, setMarkedAsInvalid] = useState(false)
	const [modalWidth, setModalWidth] = useState('90%')
	const [modalHeight, setModalHeight] = useState('calc(100vh - (20px * 2))')

	const linkSource = `data:application/pdf;base64,${base64String}`

	const onClickRegister = () => {
		if (isValid !== undefined) {
			if (isValid) {
				if (isAdmin) {
					setIsLoading(true)
					putValidateLC(nin)
						.then(() => {
							setIsLoading(false)
							onRegisterSuccess()
							reset()
						})
						.catch((err) => {
							console.log({ err })
							console.error(err)
							setIsLoading(false)
							toast.error(
								<div className="dismissToast">
									{t('dash.validate.error') as string}
								</div>
							)
						})
				}
			}
			if (!isValid) {
				setMarkedAsInvalid(true)
				setModalWidth('600px')
				setModalHeight('auto')
			}
		}
	}

	const reset = () => {
		onClose()
		setIsValid(undefined)
		setMarkedAsInvalid(false)
		setModalWidth('90%')
		setModalHeight('calc(100vh - (20px * 2))')
	}

	const invalidateLC = (comment: string) => {
		setIsLoading(true)
		putInvalidateLC(nin, comment)
			.then(() => {
				setIsLoading(false)
				onRegisterSuccess()
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
				toast.error(
					<div className="dismissToast">
						{t('dash.validate.invalid.error')}
					</div>
				)
				setIsLoading(false)
			})
	}

	const displayContent = () => {
		if (isLoading) {
			return (
				<Spinner size={20} title={t('dash.validate.sending') as string} />
			)
		}
		if (markedAsInvalid) {
			return <CommentPage onClickRegisterLC={invalidateLC} />
		}
		return (
			<>
				<Embed
					as="object"
					aria-label={t('dash.validate.embed')}
					data={mapBase64StringToPdfUrl(base64String)}
				/>
				<AButton download={fileName} href={linkSource}>
					<Icon name="Download" style={{ marginRight: 8 }} />
					<ButtonText color="inherit">
						{t('dash.validate.download')}
					</ButtonText>
				</AButton>
				<Text>{t('dash.validate.status', { name: beneficiaryName })}</Text>
				<ValidationButtons
					isValid={isValid}
					setIsValid={(v: boolean) => setIsValid(v)}
				/>
				<Button
					text={t('dash.validate.register')}
					onClick={onClickRegister}
					disabled={isValid === undefined}
				/>
			</>
		)
	}

	return (
		<Modal
			isOpen={modalOpen}
			onClose={reset}
			minWidth={modalWidth}
			minHeight={modalHeight}
		>
			<ModalContent>
				<HeaderText1>{t('dash.validate.title')}</HeaderText1>
				{displayContent()}
			</ModalContent>
		</Modal>
	)
}
