import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import { getCompanyName } from 'api/services/CompanyService'

import { LOGIN_PATH } from 'navigation/navigationURLs'

/**
 * Hook to get and use the company name
 */
export const useGetCompanyNameOfUser = () => {
	const { t } = useTranslation('company')

	const [companyName, setCompanyName] = useState(
		t('company.loading') as string
	)

	const [loadingCompanyName, setLoadingCompanyName] = useState(false)

	const { instance: msalInstance } = useMsal()

	const navigate = useNavigate()

	/**
	 * Get the company name of the logged in user
	 */
	useEffect(() => {
		if (companyName !== (t('company.loading') as string)) return
		setLoadingCompanyName(true)
		getCompanyName()
			.then((res) => {
				setCompanyName(res.data.length < 200 ? res.data : '') //set to empty string if server sends html as response.
				setLoadingCompanyName(false)
			})
			.catch((err: AxiosError) => {
				setCompanyName(t('company.error') as string)
				setLoadingCompanyName(false)
				console.error(err)
				console.log('company name error - ' + err)
				// eslint-disable-next-line eqeqeq
				if (err.code == '401') {
					msalInstance.logout()
					navigate(LOGIN_PATH)
				}
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyName])

	return { companyName, loadingCompanyName }
}
