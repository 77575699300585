import styled from 'styled-components'

import { Text as BaseText } from 'components/text/Text'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Icon as BaseIcon, IconProps } from 'components/icon/Icon'

import { buttonStyleWhite } from 'styles/button'

const BoxWrapper = styled.button`
	${buttonStyleWhite}

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: solid 2px var(--color-main-dark_blue);
	background-color: var(--color-box);
	border-radius: 10px;
	min-width: 250px;
	min-height: 200px;
	cursor: pointer;

	&:hover {
		border: solid 2px var(--color-main-dark_blue);
	}

	&:focus {
		border: solid 2px var(--color-main-dark_blue);
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const Icon = styled(BaseIcon)`
	font-size: 90px;
	margin-bottom: 10px;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	color: inherit;
`

const Text = styled(BaseText)`
	color: inherit;
`

interface Props {
	icon: IconProps['name']
	title: string
	text: string
	onClick: () => void
}

/**
 * Component to show the registration box in the add modal. It contains an icon, a title and text.
 *
 * @param props.icon the icon
 * @param props.title the title
 * @param props.text the text
 * @param props.onClick function to handle onclick
 */
export const RegisterBox = ({ icon, title, text, onClick }: Props) => {
	return (
		<BoxWrapper onClick={onClick}>
			<Icon name={icon} />
			<SubHeaderText>{title}</SubHeaderText>
			<Text>{text}</Text>
		</BoxWrapper>
	)
}
