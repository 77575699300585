import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'
import styled, { StyledComponent } from 'styled-components'

import { IconButton } from 'components/icon/IconButton'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

const BaseContentWrapper = styled.div`
	width: 100%;
`

const BackButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	padding-left: 10px;
	padding-top: 10px;
	font-size: var(--font-size-title1);
	cursor: pointer;
`

const CloseButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	padding-right: 10px;
	padding-top: 10px;
	font-size: var(--font-size-title1);
	cursor: pointer;
`

const StyledModal = styled(ReactModal)`
	background-color: var(--color-white);
	padding: 40px;
	width: 90%;
	max-width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 700px;
		max-height: 100%;
	}
`

const modalOverLay: React.CSSProperties = {
	background: 'rgb(0,0,0, 0.7)'
}

const modalContent: React.CSSProperties = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	display: 'flex',
	borderRadius: '10px',
	overflowY: 'auto'
}

interface Props {
	isOpen: boolean
	onClose: () => void
	onGoBack?: (() => void) | undefined
	shouldCloseOnOverlayClick?: boolean
	contentWrapper?: StyledComponent<'div', any, {}, never>
	children: ReactNode
}

/**
 * Reusable modal component
 * @param props.isOpen Whether to show the modal or not
 * @param props.onClose Function that is called from the modal when it should be closed
 * @param props.onGoBack Function that is called from the modal when it should go back to the previous content
 * @param props.shouldCloseOnOverlayClick flag to prevent close onclick on the outside of the modal
 * @param props.children React children
 */
export const Modal = ({
	isOpen,
	onClose,
	onGoBack,
	children,
	shouldCloseOnOverlayClick,
	contentWrapper: PropContentWrapper,
	...rest
}: Props) => {
	const ContentWrapper = PropContentWrapper ?? BaseContentWrapper
	return (
		<StyledModal
			isOpen={isOpen}
			onRequestClose={onClose}
			portalClassName="modal"
			style={{
				overlay: modalOverLay,
				content: modalContent
			}}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			{...rest}
		>
			<ContentWrapper>
				{onGoBack && (
					<BackButtonWrapper>
						<IconButton
							icon="ArrowBack"
							screenReaderText="Gå tilbake"
							onClick={onGoBack}
						/>
					</BackButtonWrapper>
				)}
				<CloseButtonWrapper>
					<IconButton
						icon="Close"
						screenReaderText="Lukk panelet"
						onClick={onClose}
					/>
				</CloseButtonWrapper>
				{children}
			</ContentWrapper>
		</StyledModal>
	)
}
