import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { AddOrEditBeneficiaryContent } from 'pages/company-pages/AddOrEditBeneficiaryContent/AddOrEditBeneficiaryContent'

import { postAddSingleBeneficiary } from 'api/services/BeneficiaryService'

import { YTELSESMOTTAKER_PATH } from 'navigation/navigationURLs'

import { AddOrEditBeneficiaryType } from 'types/AddOrEditBeneficiaryType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

interface Props {
	companyName: string
}

/**
 * Component to handle the display and functionality of adding a single beneficiary
 *
 * @param companyName the name of the company
 */
export const AddSingleBeneficiary = ({ companyName }: Props) => {
	const { isAdmin } = useIsAdminRole()

	// To handle loading
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation('company')

	const navigate = useNavigate()

	const onSave = (beneficiaryDetails: AddOrEditBeneficiaryType) => {
		if (!isAdmin) {
			setIsLoading(true)
			postAddSingleBeneficiary(beneficiaryDetails)
				.then((res) => {
					setIsLoading(false)
					navigate(`${YTELSESMOTTAKER_PATH}/${res.data.id}`, {
						state: {
							nin: res.data.nationalIdentityNumber,
							companyName
						}
					})
				})
				.catch((err) => {
					console.log({ err })
					console.error(err)
					if (err.response.status === 409) {
						toast.error(
							<div className="dismissToast">
								{t('dash.register.modal.single.page.errors.409')}
							</div>
						)
					} else {
						toast.error(
							<div className="dismissToast">
								{t('dash.register.modal.single.page.errors.generic')}
							</div>
						)
					}
					setIsLoading(false)
				})
		}
	}

	return (
		<AddOrEditBeneficiaryContent
			onSave={onSave}
			beneficiary={null}
			buttonPosition="center"
			buttonMinWidth="340px"
			buttonText={t('dash.register.modal.single.page.submit')}
			isLoading={isLoading}
			companyName={companyName}
			isEdit={false}
		/>
	)
}
