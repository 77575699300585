import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import {
	getBankIdSessionDetails,
	postCreateBankIDSession
} from 'api/services/BankIdService'

import {
	BankIDSessionType,
	BankIDType,
	InitialEmptyBankIDDetails
} from 'types/BankIDType'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'

import { useTimer } from 'hooks/useTimer'
import { useOnce } from 'hooks/useOnce'

import { ELECTRONIC_PROCESS_START_PAGE_PATH } from 'navigation/navigationURLs'

import { isValidUUID } from 'utils/validators/isValidUUID'
import { transformTimeDeltaIntoReadableChunks } from 'utils/transformTimeDeltaIntoReadableChunks'
import { mapJsonObjectToBankIdType } from 'utils/type-mappers/mapJsonObjectToBankIdType'

import { QRCodeBox } from './QRCodeBox'
import { BackButtonWithModal } from './BackButtonWithModal'

const Wrapper = styled.div``

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 30px;
	text-align: center;
`

const HeaderText2 = styled(BaseHeaderText2).attrs({ as: 'p' })`
	text-align: center;
	white-space: pre-line;
	margin-bottom: 20px;
	line-height: 30px;
`

const SECONDS_IN_FIVE_MINUTES = 5 * 60
const SECONDS_UNTIL_NEW_STATUS_REQUEST = 2

/**
 * Page to display the QR code generated from bank id
 */
export const QRCodePage = () => {
	const { companyName, uuid } = useParams()
	const { t } = useTranslation('private')

	// To handle the loading of the bank id session
	const [isLoading, setIsLoading] = useState(false)
	const [errorText, setErrorText] = useState<string | undefined>()

	// To store the bank id details
	const [bankIdDetails, setBankIdDetails] = useState<BankIDType>(
		InitialEmptyBankIDDetails
	)

	// To display different content based on the state of the bank id session
	const [bankIdSessionDetails, setBankIdSessionDetails] =
		useState<BankIDSessionType>({ state: 'created' })

	// To handle the countdown text
	const { time, startTimer, resetTimer } = useTimer(SECONDS_IN_FIVE_MINUTES)
	const {
		startTimer: startTimerStatus,
		resetTimer: resetTimerStatus,
		timeRanOut: timeRanOutStatus
	} = useTimer(SECONDS_UNTIL_NEW_STATUS_REQUEST)

	const { minutes, seconds } = transformTimeDeltaIntoReadableChunks(time)

	/**
	 * If the company name or uuid doesn't exist or is valid, send the user back
	 */
	useEffect(() => {
		if (!uuid || !companyName || !isValidUUID(uuid ?? '')) {
			window.location.href = ELECTRONIC_PROCESS_START_PAGE_PATH
		}
	}, [companyName, uuid])

	const createBankIDSession = async () => {
		console.log('Creating bankId session')
		setErrorText(undefined)
		setIsLoading(true)
		setBankIdDetails(InitialEmptyBankIDDetails)
		try {
			// Get and map the bank id result
			const result: unknown = await postCreateBankIDSession(uuid ?? '')
			const bankIdResult: BankIDType = mapJsonObjectToBankIdType(result)

			console.log('BankID session created!')
			resetTimer()
			setBankIdDetails(bankIdResult)
			setIsLoading(false)

			if (bankIdResult.intentUrl !== '') startTimer()
		} catch (err: unknown) {
			console.log({ err })
			console.error(err)
			setIsLoading(false)
			if (err instanceof AxiosError && err?.request?.status === 403) {
				setErrorText(t('qr.errors.expired'))
			} else {
				setErrorText(t('qr.errors.generic'))
			}
		}
	}

	/**
	 * Create bank id session when page first loads and start timer
	 */
	useOnce(() => {
		createBankIDSession() //try to start session. If successful, also starts timer, but should stop the previous one, so no problems.
	})

	/**
	 * Constantly fetch the bank ID session to update content when state changes
	 */
	useEffect(() => {
		getSessionDetails()
		// eslint-disable-next-line
	}, [bankIdDetails.handoverId, bankIdSessionDetails.state, timeRanOutStatus])

	/**
	 * Get the details about the session
	 */
	const getSessionDetails = () => {
		if (
			bankIdDetails.handoverId !== '' &&
			bankIdSessionDetails.state !== 'expired'
		) {
			getBankIdSessionDetails(bankIdDetails.handoverId)
				.then((res) => {
					resetTimerStatus()
					startTimerStatus()
					setBankIdSessionDetails(res.data as BankIDSessionType)
				})
				.catch((err) => {
					console.error(err)
					console.log('get session error: \n' + JSON.stringify(err))
					toast.error(
						<div className="dismissToast">
							{t('qr.errors.progress') as string}
						</div>
					)
				})
		}
	}

	/**
	 * Display different header and information on the page based on the
	 * state from the BankId app
	 */
	const displayPageHeaderAndInformation = () => {
		if (bankIdSessionDetails.state === 'created') {
			return (
				<>
					<HeaderText1>{t('qr.title')}</HeaderText1>
					<HeaderText2>{t('qr.text')}</HeaderText2>
				</>
			)
		}
		if (bankIdSessionDetails.state === 'completed') {
			return (
				<>
					<HeaderText1>{t('qr.completed.title')}</HeaderText1>
					<HeaderText2>{t('qr.completed.text')}</HeaderText2>
				</>
			)
		}
		return (
			<>
				<HeaderText1>{t('qr.started.title')}</HeaderText1>
				<HeaderText2>{t('qr.started.text')}</HeaderText2>
			</>
		)
	}

	/**
	 * Hide the back button when the state is completed
	 */
	const displayBackButton = () => {
		if (bankIdSessionDetails.state !== 'completed' && companyName && uuid) {
			return <BackButtonWithModal companyName={companyName} uuid={uuid} />
		}
		return null
	}

	return (
		<Wrapper>
			<ContentWrapper>
				{displayPageHeaderAndInformation()}
				<QRCodeBox
					bankIdDetails={bankIdDetails}
					isLoading={isLoading}
					errorText={errorText}
					onClickButton={createBankIDSession}
					countDownText={`${minutes.toString().padStart(2, '0')}:${seconds
						.toString()
						.padStart(2, '0')}`}
					bankIdSessionDetails={bankIdSessionDetails}
				/>
			</ContentWrapper>
			{displayBackButton()}
		</Wrapper>
	)
}
