import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BeneficiaryType } from 'types/BeneficiaryType'
import { LifeCertificateType } from 'types/LifeCertificateType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { displayFormatDate } from 'utils/displayFormatDate'

import { Button } from 'components/company-components/button/Button'

import { LifeCertificateDetails } from '../LifeCertificateDetails'
import { CommentListTextItem } from '../CommentListTextItem'

const ButtonWrapper = styled.div`
	margin-top: 10px;
`

interface Props {
	beneficiary: BeneficiaryType
	lifeCertificate: LifeCertificateType
	onClickSendNewRequest: () => void
}

/**
 * Case 4 - The life certificate has been marked as invalid
 *
 * @param props.beneficiary The beneficiary
 * @param props.lifeCertificate the LC
 * @param props.onClickSendNewRequest function to send a new request
 */

export const CaseMarkedInvalid = ({
	beneficiary,
	lifeCertificate,
	onClickSendNewRequest
}: Props) => {
	const { t } = useTranslation(['common', 'company'])
	const { isAdmin } = useIsAdminRole()

	/**
	 * Only allow companies to see the request button
	 */
	const displayRequestButton = () => {
		if (isAdmin) return null
		return (
			<ButtonWrapper>
				<Button
					text={t('company:beneficiary.cert.initial.button')}
					icon={{ name: 'SendPlane' }}
					onClick={onClickSendNewRequest}
				/>
			</ButtonWrapper>
		)
	}

	return (
		<LifeCertificateDetails
			status={t('common:status.invalid')}
			additionalTopRowItem={{
				title: t('company:beneficiary.cert.invalidMarked.date'),
				text: displayFormatDate(lifeCertificate?.timestamp)
			}}
			beneficiary={beneficiary}
		>
			<CommentListTextItem comment={lifeCertificate.comment} />
			{displayRequestButton()}
		</LifeCertificateDetails>
	)
}
