import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { WALK_THROUGH_PAGE_PATH } from 'navigation/navigationURLs'

import { Button as BaseButton } from 'components/private-components/button/Button'
import { Modal as BaseModal } from 'components/common/Modal'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	margin-top: 40px;
`

const Button = styled(BaseButton)`
	margin-top: 30px;
`

const Modal = styled(BaseModal)`
	max-height: 220px;
`

const IngressText = styled(BaseHeaderText2).attrs({ as: 'p' })`
	text-align: center;
	white-space: pre-line;
	margin-bottom: 20px;
	line-height: 30px;
`

interface Props {
	companyName: string
	uuid: string
}

/**
 * Displays a Back button with a modal to make sure the user really wants to go back
 *
 * @param companyName the name of the company
 * @param uuid the uuid of the request
 */
export const BackButtonWithModal = ({ companyName, uuid }: Props) => {
	const navigate = useNavigate()
	const { t } = useTranslation('private')

	// To handle the modal
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<>
			<Button
				text={t('qr.cancel.buttonText')}
				color="white"
				onClick={() => setModalOpen(true)}
			/>
			<Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
				<IngressText>{t('qr.cancel.modal.text')}</IngressText>
				<ButtonWrapper>
					<Button
						text={t('qr.cancel.modal.confirm')}
						color="white"
						onClick={() =>
							navigate(
								`${WALK_THROUGH_PAGE_PATH}/${companyName}/${uuid}/3`
							)
						}
					/>
					<Button
						text={t('qr.cancel.modal.cancel')}
						onClick={() => setModalOpen(false)}
					/>
				</ButtonWrapper>
			</Modal>
		</>
	)
}
