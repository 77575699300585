import styled from 'styled-components'

import { Icon, IconProps } from 'components/icon/Icon'
import { ExternalLink as BaseExternalLink } from 'components/text/ExternalLink'

const ExternalLinkText = styled(BaseExternalLink)`
	color: var(--color-main-dark_blue);

	&:hover {
		color: var(--color-main-blue_green);
		font-weight: var(--font-weight-bold);
	}
`

const TextButtonUnderline = styled.button`
	text-decoration: underline;
	cursor: pointer;
	font-weight: var(--font-weight-bold);
	color: var(--color-main-dark_blue);
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	margin: 0;
	padding: 0;
	border: none;
	background-color: transparent;
	text-align: left;

	&:hover {
		color: var(--color-main-blue_green);
		font-weight: var(--font-weight-bold);
	}
`

const TextButtonOrExternalLinkWrapper = styled.div`
	display: flex;
	align-items: stretch;
	margin-top: 10px;
	column-gap: 10px;
`

const IconWrapper = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-black);
	color: var(--color-white);
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin-block: auto;
	flex-shrink: 0;
`

interface Props {
	onClick?: () => void
	externalLinkIcon?: IconProps
	externalLinkText?: string
	href?: string
}

/**
 * Component to display text button or external link
 *
 * @param props.onClick optional on click function for the component
 * @param props.externalLinkIcon Optional external link icon
 * @param props.href Optional text to display on the external link
 * @param props.externalLinkText Optional href for external links
 */
export const TextButtonOrExternalLink = ({
	onClick,
	externalLinkIcon,
	href,
	externalLinkText
}: Props) => {
	return (
		<TextButtonOrExternalLinkWrapper>
			{externalLinkIcon && (
				<IconWrapper onClick={onClick && onClick}>
					<Icon name={externalLinkIcon.name} />
				</IconWrapper>
			)}
			{href ? (
				<ExternalLinkText
					href={href}
					target="_blank"
					rel="noopener noreferrer"
				>
					{externalLinkText}
				</ExternalLinkText>
			) : onClick ? (
				<TextButtonUnderline onClick={onClick}>
					{externalLinkText}
				</TextButtonUnderline>
			) : null}
		</TextButtonOrExternalLinkWrapper>
	)
}
