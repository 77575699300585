import React from 'react'
import { toast } from 'react-toastify'

import { postCreateBankIDSession } from 'api/services/BankIdService'

/**
 * Function that creates a session with BankID and opens the application on the phone
 *
 * @param setLoading	To display that the function is getting the data
 * @param setError		To set potential errors
 * @param uuid			The uuid of the request that is linked to the beneficiary opening the application
 */
export const openBankIDApp = (
	setLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setError: React.Dispatch<React.SetStateAction<boolean>>,
	uuid: string
) => {
	setLoading(true)
	postCreateBankIDSession(uuid)
		.then((res: any) => {
			setLoading(false)
			window.location.href = res.data.intent_url
		})
		.catch(() => {
			setError(true)
			setLoading(false)
			toast.error(
				<div className="dismissToast">
					Fikk ikke kontakt med BankID. Prøv igjen senere.
				</div>
			)
		})
}
