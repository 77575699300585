import { AddOrEditBeneficiaryType } from 'types/AddOrEditBeneficiaryType'

/**
 * Maps the nullable values to null if the string is empty
 *
 * @param beneficiary the beneficiary to update details on
 * @param companyName the name of the company
 */
export const setBeneficiaryValuesBeforeUpdate = (
	beneficiary: AddOrEditBeneficiaryType,
	companyName: string
): AddOrEditBeneficiaryType => {
	const {
		country,
		streetAddress,
		postcode,
		city,
		phoneNumber,
		emailAddress,
		...rest
	} = beneficiary

	return {
		...rest,
		country: country || null,
		streetAddress: streetAddress || null,
		postcode: postcode || null,
		city: city || null,
		phoneNumber: phoneNumber || null,
		emailAddress: emailAddress || null,
		companyName: companyName
	}
}
