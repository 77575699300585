import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { Circle } from './Circle'
import { Line } from './Line'

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 32px;
`

const CircleAndLineWrapper = styled.div`
	width: 25%;
	display: flex;
	align-items: center;
`

interface Props {
	hasConsent?: boolean
	pageId: number
	onClickSwitchTab: (tabIndex: number, fromTab?: number) => void
}

/**
 * Component to show the tabs of the walk through process
 *
 * @param hasConsent Optional flag if to see if the user has consent or not
 * @param pageId the Id of the current tab
 * @param onClickSwitchTab 	function that navigates to another tab index page. Optional parameter fromTab is to let the
 * 							browser know which page we came from so it moves back to the correct page
 */
export const Tabs = ({ hasConsent, pageId, onClickSwitchTab }: Props) => {
	const { t } = useTranslation('private')
	return (
		<Wrapper>
			<CircleAndLineWrapper>
				<Circle
					tabText={1}
					isActive={pageId === 1}
					isPast={pageId > 1}
					onClick={() => onClickSwitchTab(1)}
				/>
				<Line isPast={pageId > 1} />
			</CircleAndLineWrapper>
			<CircleAndLineWrapper>
				<Circle
					tabText={2}
					isActive={pageId === 2}
					isPast={pageId > 2}
					onClick={() => onClickSwitchTab(2)}
				/>
				<Line isPast={pageId > 2} />
			</CircleAndLineWrapper>
			<CircleAndLineWrapper>
				<Circle
					tabText={3}
					isActive={pageId === 3}
					isPast={pageId > 3}
					onClick={() => onClickSwitchTab(3)}
				/>
				<Line isPast={pageId > 3} />
			</CircleAndLineWrapper>
			<Circle
				tabText={4}
				isActive={pageId === 4}
				isPast={false}
				onClick={() => {
					if (!hasConsent)
						return toast.error(
							<div className="dismissToast">
								{t('tabs.errors.lastPage') as string}
							</div>
						)
					onClickSwitchTab(4)
				}}
			/>
		</Wrapper>
	)
}
