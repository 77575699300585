import i18n from 'language/i18n'

import { CommunicationType } from 'types/CommunicationType'
import { displayFormatChannelName } from 'utils/displayFormatChannelName'
import { displayFormatDate } from 'utils/displayFormatDate'

/**
 * Function to get the correct text of the "Forespørsel" or "Påminnelse" fields
 *
 * @param communication	- The array of communication
 * @param date			- The date to find the string on
 *
 * @returns a string with the communication types and date
 */
export const getRequestText = (
	communication: CommunicationType[],
	date: Date
): string => {
	const lang = i18n.language

	// Get the communications as a unique set to make sure duplicates are removed
	const uniqueCommunications: (
		| 'SMS'
		| 'E-post'
		| 'Email'
		| 'Post'
		| undefined
	)[] = getUniqueCommunicationString(communication, date)

	const sent: string =
		(lang === 'no' ? ' sendt ' : ' sent ') + displayFormatDate(date)

	// If only one communication type is used
	if (uniqueCommunications.length === 1) return uniqueCommunications[0] + sent
	// If there are 2, separate with the word "og"
	else if (uniqueCommunications.length === 2)
		return uniqueCommunications.join(' og ') + sent
	// Else, it must be a length of 3, separate word 1 and 2 with "," and 2 and 3 with "og"
	else
		return (
			uniqueCommunications.slice(0, 2).join(', ') +
			' og ' +
			uniqueCommunications[2] +
			sent
		)
}

/**
 * Function to get a list of unique communication strings.
 * It removes the duplicates which might occur.
 *
 * @param communication - the communication array
 * @param date			- the date we want to find the communication types on.
 *
 * @returns the unique communication string mapped to correct display format
 */
const getUniqueCommunicationString = (
	communication: CommunicationType[],
	date: Date
): ('SMS' | 'E-post' | 'Email' | 'Post' | undefined)[] => {
	const str: string[] = []
	communication.forEach((ct: CommunicationType) => {
		if (
			ct.createdOn.getTime() === date.getTime() &&
			!str.includes(ct.channel)
		) {
			str.push(ct.channel)
		}
	})
	return str.map((s: string) => {
		return displayFormatChannelName(s as 'email' | 'sms' | 'post')
	})
}
