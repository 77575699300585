import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { COMPANYLISTPAGE_PATH } from 'navigation/navigationURLs'

import { Button as BaseButton } from 'components/company-components/button/Button'
import { BoxIngressText as BaseBoxIngressText } from 'components/company-components/text/BoxIngressText'

import { DashboardBox as BaseDashboardBox } from './DashboardBox'

const DashboardBox = styled(BaseDashboardBox)`
	padding-inline: 40px;
`

const BoxIngressText = styled(BaseBoxIngressText)`
	margin-bottom: 30px;
`

const Button = styled(BaseButton)`
	padding-inline: 40px;
	width: 300px;
`

/**
 * Displays the box with the button to navigate to the company overview
 */
export const MyCompany = () => {
	const { t } = useTranslation('company')
	const navigate = useNavigate()

	return (
		<DashboardBox title={t('dash.myCompany.title')} minHeight="170px">
			<BoxIngressText>{t('dash.myCompany.text')}</BoxIngressText>
			<Button
				onClick={() => navigate(COMPANYLISTPAGE_PATH)}
				text={t('dash.myCompany.button')}
				hoverColor="blue"
			/>
		</DashboardBox>
	)
}
