import { ReactNode } from 'react'
import styled from 'styled-components'

import { TextButton as BaseTextButton } from 'components/common/TextButton'

const TextButton = styled(BaseTextButton)`
	margin-top: 15px;
`

const AlternativeSubmissionText = styled.p`
	margin: 0;
	font-size: var(--font-size-small_mobile);
	color: var(--color-main-dark_blue);

	&:hover {
		color: var(--color-main-blue_green);
		font-weight: var(--font-weight-bold);
	}
`

interface Props {
	onClick: () => void
	text?: string
	children?: ReactNode
}

/**
 * Component to show alternative submission button
 *
 * @param props.onClick onClick function
 * @param props.text Optional button text
 * @param props.children React children
 */
export const AlternativeSubmissionButton = ({
	onClick,
	text,
	children
}: Props) => {
	return (
		<TextButton onClick={onClick}>
			{text ? (
				<AlternativeSubmissionText>{text}</AlternativeSubmissionText>
			) : (
				children
			)}
		</TextButton>
	)
}
