import { ReactNode } from 'react'
import styled from 'styled-components'
import { styleChain } from 'utils/styleChain'

const TextButtonWrapper = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	text-decoration: underline;
	text-align: center;
	font-size: var(--font-size-small-text);
	font-weight: var(--font-weight-bold);
	color: ${styleChain()
		.equalsCase('color', 'white', `var(--color-white)`)
		.valueCase('color')
		.default('var(--color-black)')};
	font-family: var(--font-family-merriweather);
`

interface Props extends React.ComponentPropsWithoutRef<'button'> {
	onClick: () => void
	children: ReactNode
	color?: string
}

/**
 * Text button component
 *
 * @param onClick on click function of the button
 * @param children React children
 */
export const TextButton = ({ children, ...rest }: Props) => {
	return <TextButtonWrapper {...rest}>{children}</TextButtonWrapper>
}
