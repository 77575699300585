import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { Button } from 'components/company-components/button/Button'

import { LifeCertificateDetails } from '../LifeCertificateDetails'

const ButtonWrapper = styled.div`
	margin-top: 20px;
`

interface Props {
	onClick: () => void
	beneficiary: BeneficiaryType
}

/**
 * Case 1 - the life certificate is invalid and not yet requested
 *
 * @param props.onClick - Callback called when the request button is pressed
 * @param props.beneficiary the beneficiary
 */
export const CaseInitial = ({ onClick, beneficiary }: Props) => {
	const { t } = useTranslation(['common', 'company'])
	const { isAdmin } = useIsAdminRole()

	const displayButton = () => {
		if (isAdmin) return null
		return (
			<ButtonWrapper>
				<Button
					text={t('company:beneficiary.cert.initial.button')}
					icon={{ name: 'SendPlane' }}
					onClick={onClick}
				/>
			</ButtonWrapper>
		)
	}

	/**
	 * If Admin is logged in, display text about no request, otherwise remove it
	 */
	const listItems = isAdmin
		? [
				{
					title: t('company:beneficiary.cert.initial.title'),
					text: t('company:beneficiary.cert.initial.text')
				}
		  ]
		: undefined

	return (
		<LifeCertificateDetails
			status={t('common:status.invalid')}
			listItems={listItems}
			beneficiary={beneficiary}
		>
			{displayButton()}
		</LifeCertificateDetails>
	)
}
