export const COLOR_MAIN_DARK_BLUE = '#051D2E'
export const COLOR_MAIN_BLUE_GREEN = '#005670'
export const COLOR_MAIN_TURQUOISE = '#00B7BD'

export const COLOR_WHITE = '#FFFFFF'
export const COLOR_YELLOW = '#EEDA72'
export const COLOR_BLACK = '#000000'
export const COLOR_GREY = '#F4F4F4'
export const COLOR_GREY_DARK = '#D6D6D6'
export const COLOR_ERROR = '#D60D0D'

export const COLOR_TEXT = COLOR_MAIN_DARK_BLUE

export const COLOR_BOX = '#F4F4F4D9'
export const COLOR_BACKGROUND_LOGIN_PAGE = '#E3EAF0'
