import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { getBeneficiaryByNationalIdentityNumber } from 'api/services/BeneficiaryService'

import { BeneficiaryType, initialEmptyBeneficiary } from 'types/BeneficiaryType'

import { TopOfPageContent } from 'components/company-components/utility/TopOfPageContent'
import { Spinner } from 'components/Spinner'
import { Text } from 'components/text/Text'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'

import { useOnce } from 'hooks/useOnce'

import { mapJsonObjectToBeneficiaryType } from 'utils/type-mappers/mapJsonObjectToBeneficiaryType'

import {
	COMPANYLISTPAGE_PATH,
	OPPGAVEBEHANDLING_PATH
} from 'navigation/navigationURLs'

import { LifeCertificateContainer } from './LifeCertificateContainer'
import { BeneficiaryContainer } from './BeneficiaryContainer'
import { BottomButtons } from './BottomButtonts'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
`

const TopWrapper = styled.div`
	display: flex;
	margin-block: 20px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
	width: 100%;
`

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 1000px;
	margin-top: 10px;
	margin-inline: 15%;
`

const BodyContainerWrapper = styled.div`
	background-color: var(--color-box);
	display: flex;
	border-radius: 20px;
	max-width: 100%;
	box-shadow: 0 2px 4px rgb(0, 0, 0, 25%);
`

const NoBeneficiaryWrapper = styled.div`
	margin: 0 20% 5%;
	background-color: var(--color-box);
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	padding: 55px;
	align-items: center;
`

/**
 * Interface to control the state being sent
 */
interface LocationState {
	nin: string
	companyName: string
	from: string
}

/**
 * Page which displays details about a beneficiary and the life certificate
 */
export const BeneficiaryPageCompany = () => {
	const { nin, companyName, from } = useLocation().state as LocationState

	const [isLoading, setIsLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	const { t } = useTranslation('company')

	useEffect(() => {
		console.log({ errorMsg })
	}, [errorMsg])

	const navigate = useNavigate()

	/**
	 * useState to hold the selected beneficiary.
	 * 	- initialEmptyBeneficiary when it is initial state
	 * 	- beneficiary when found
	 * 	This is done to get the beneficiary in one call in a useEffect
	 */
	const [beneficiary, setBeneficiary] = useState<BeneficiaryType>(
		initialEmptyBeneficiary
	)

	const [hasBeneficiary, setHasBeneficiary] = useState(false)
	const [hasBeneficiaryChanged, setHasBeneficiaryChanged] = useState(false)

	/**
	 * Get the beneficiary when some values changes.
	 * Cases where this is possible:
	 * - When a request is sent - From Case 1 -> Case 2
	 * - When a reminder is sent - From Case 2 -> Case 3
	 * - When uploaded LC is marked as Valid - From Case 4 -> Case 6
	 * - When uploaded LC is marked as Invalid - From Case 4 -> Case 5
	 * - When Invalid LC is marked as Invalid - From Case 5 -> Case 6
	 */
	useEffect(() => {
		/**
		 * If nin does not exist or gets changed to undefined for some reason, send the user back to dashboard page,
		 */
		if (nin === undefined || nin === null) {
			navigate('/bedrift/oppgavebehandling/')
		}

		if (hasBeneficiaryChanged) {
			getBeneficiary()
			setHasBeneficiaryChanged(false)
		}
		// eslint-disable-next-line
	}, [hasBeneficiaryChanged, setHasBeneficiaryChanged])

	useOnce(() => {
		/**
		 * Get the beneficiary by nin, or set the error
		 */
		if (!hasBeneficiary) {
			getBeneficiary()
		} else setErrorMsg(t('beneficiary.errors.fetch'))
	})

	/**
	 * Get the beneficiary by nin, or set the error
	 */
	const getBeneficiary = () => {
		setErrorMsg('')
		setIsLoading(true)

		getBeneficiaryByNationalIdentityNumber(nin)
			.then((res) => {
				// Set the beneficiary
				setBeneficiary(mapJsonObjectToBeneficiaryType(res.data))
				setIsLoading(false)
				setHasBeneficiary(true)
			})
			.catch((err) => {
				console.error(err)
				console.log('Error - ' + err)
				setHasBeneficiary(false)
				setIsLoading(false)
				if (err.response.status === 401)
					setErrorMsg(t('beneficiary.errors.401'))
				else if (err.response.status === 404)
					setErrorMsg(t('beneficiary.errors.404'))
				else {
					setErrorMsg(t('beneficiary.errors.generic'))
				}
			})
	}

	const displayBody = () => {
		if (isLoading) {
			return <Spinner title={t('beneficiary.loading')} />
		}
		if (!hasBeneficiary) {
			return (
				<NoBeneficiaryWrapper>
					<Text>{t('beneficiary.notFound')}</Text>
				</NoBeneficiaryWrapper>
			)
		}
		return (
			<>
				<TopWrapper>
					<HeaderText1>{t('beneficiary.title')}</HeaderText1>
				</TopWrapper>
				<BodyContainerWrapper>
					<BeneficiaryContainer beneficiary={beneficiary} />
					<LifeCertificateContainer
						lifeCertificate={beneficiary.lifeCertificate}
						request={beneficiary.request}
						beneficiary={beneficiary}
						onBeneficiaryChange={() => setHasBeneficiaryChanged(true)}
					/>
				</BodyContainerWrapper>
				<BottomButtons
					beneficiary={beneficiary}
					companyName={companyName}
					onBeneficiaryChange={() => setHasBeneficiaryChanged(true)}
					onDeleteBeneficiary={() => navigate(OPPGAVEBEHANDLING_PATH)}
				/>
			</>
		)
	}

	return (
		<Wrapper>
			<TopOfPageContent
				displayBackButton
				navigateBackToLink={
					from === 'list' ? COMPANYLISTPAGE_PATH : OPPGAVEBEHANDLING_PATH
				}
				isPageList={from === 'list'}
			/>
			<BodyWrapper aria-live="polite" aria-busy={isLoading}>
				{displayBody()}
			</BodyWrapper>
		</Wrapper>
	)
}
