import axios from 'axios'

/**
 * Axios instance for connecting to the backend.
 *
 * baseURL is set to the backend URL.
 *
 * If a response fails with a 401 error, we invalidate the Authorization header if one is set on the instance
 */
export const backend = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URI
})
