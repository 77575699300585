import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button as BaseButton } from 'components/company-components/button/Button'
import { BoxIngressText as BaseBoxIngressText } from 'components/company-components/text/BoxIngressText'

import { AddModal } from './AddModal'
import { DashboardBox as BaseDashboardBox } from '../DashboardBox'

const DashboardBox = styled(BaseDashboardBox)`
	padding-inline: 40px;
`
const Button = styled(BaseButton)`
	padding-inline: 40px;
`

const BoxIngressText = styled(BaseBoxIngressText)`
	margin-bottom: 30px;
`

interface Props {
	companyName: string
}

/**
 * Component to display the box where the user can add beneficiaries
 *
 * @param props.companyName name of the company
 */
export const AddBeneficiaryContent = ({ companyName }: Props) => {
	const [modalOpen, setModalOpen] = useState(false)

	const { t } = useTranslation('company')

	return (
		<DashboardBox title={t('dash.register.title')} minHeight="170px">
			<BoxIngressText>{t('dash.register.text')}</BoxIngressText>
			<Button
				onClick={() => setModalOpen(true)}
				text={t('dash.register.button')}
				hoverColor="blue"
			/>
			<AddModal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				companyName={companyName}
			/>
		</DashboardBox>
	)
}
