import React from 'react'
import styled from 'styled-components'

import { ScreenReaderText } from 'components/text/ScreenReaderText'

import { Icon as BaseIcon, IconProps } from './Icon'

const StyledIconButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	padding: 0;
	margin: 0;
	font-size: var(--font-size-title1);
	cursor: pointer;

	&:focus {
		border-radius: 100%;
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const Icon = styled(BaseIcon)`
	color: inherit;
`

interface Props extends React.ComponentPropsWithoutRef<'button'> {
	icon: IconProps['name']
	screenReaderText: string
}

/**
 * Button wrapping an icon
 *
 * @param props.icon Name of the icon from react-icons to render
 * @param props.screenReaderText Text to render instead of the icon for users navigating using screen readers.
 */
export const IconButton = ({
	icon,
	screenReaderText,
	...rest
}: Props): JSX.Element => {
	return (
		<StyledIconButton {...rest}>
			<ScreenReaderText>{screenReaderText}</ScreenReaderText>
			<Icon name={icon} />
		</StyledIconButton>
	)
}
