import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { TopOfPageContent } from 'components/company-components/utility/TopOfPageContent'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { Text as BaseText } from 'components/text/Text'

import { OPPGAVEBEHANDLING_PATH } from 'navigation/navigationURLs'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-block: 20px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
	width: 100%;
`

const TextWrapper = styled.div`
	margin-inline: 15%;
	line-height: 27px;
	margin-top: 20px;
`

const Text = styled(BaseText)`
	text-align: center;
`

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 1000px;
	margin-top: 10px;
	margin-inline: 15%;
`

/**
 * Displays an error page when there is a Router Error on the BeneficiaryPage
 */
export const BeneficiaryPageError = () => {
	const { t } = useTranslation('company')

	return (
		<Wrapper>
			<TopOfPageContent
				displayBackButton
				navigateBackToLink={OPPGAVEBEHANDLING_PATH}
			/>
			<BodyWrapper>
				<TopWrapper>
					<HeaderText1>{t('error.beneficiary.title')}</HeaderText1>
				</TopWrapper>
				<TextWrapper>
					<Text>{t('error.beneficiary.text')}</Text>
				</TextWrapper>
			</BodyWrapper>
		</Wrapper>
	)
}
