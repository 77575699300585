import { LifeCertificateType } from 'types/LifeCertificateType'

/**
 * Function to map from a JSON object from backend to life certificate type
 *
 * @param responseJSON the object to map
 *
 * @returns the mapped beneficiary object
 */
export const mapJsonObjectToLifeCertificateType = (
	responseJSON: LifeCertificateType
): LifeCertificateType => {
	const lifeCertificate: LifeCertificateType =
		responseJSON as LifeCertificateType

	return {
		...lifeCertificate,
		issueDate:
			lifeCertificate.issueDate === null
				? null
				: new Date(lifeCertificate.issueDate),
		expiryDate:
			lifeCertificate.expiryDate === null
				? null
				: new Date(lifeCertificate.expiryDate),
		timestamp: new Date(lifeCertificate.timestamp)
	}
}
