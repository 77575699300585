import styled, { css } from 'styled-components'

/**
 * Text component to display sub header text
 *
 * Color: #051D2E or #FFFFFF
 * Size: 16px / 18px
 * Family: Open Sans
 * Weight: 700
 */
export const SubHeaderText = styled.h3<{ mb?: string }>`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-text);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-bold);
	margin: 0;
	${(props) =>
		props.mb &&
		css`
			margin-bottom: ${props.mb};
		`}
`
