import { ContactType } from 'types/ContactType'

/**
 * Function to map from a JSON object from backend to contact type
 *
 * @param responseJSON the object to map
 *
 * @returns the mapped beneficiary object
 */
export const mapJsonObjectToContactType = (
	responseJSON: unknown
): ContactType => {
	const contact: ContactType = responseJSON as ContactType

	return {
		...contact,
		createdOn: new Date(contact.createdOn)
	}
}
