import { toast } from 'react-toastify'

import { BulkFileDetailsType } from 'types/BulkFileDetailsType'

/**
 * Displays the correct toast with the correct message based on the bulkDetails given.
 * 	- GREEN toast - same amount of fields with status OK as total fields
 * 	- ORANGE toast - at least 1 field with Not OK and not same amount of total as Not OK
 * 	- RED toast - 0 fields were OK
 *
 * @param bulkDetails the details about the bulk file
 * @param text the main text to display in the toast
 * @param csvErrorText the text to display in addition to the text on warning and errors
 */
export const displayBulkUploadToast = (
	bulkDetails: BulkFileDetailsType,
	text: string,
	csvErrorText: string
) => {
	const numberUploaded =
		`${bulkDetails.bulksOK}/${bulkDetails.totalBulks}` + text

	const warningAndErrorText = numberUploaded + csvErrorText

	if (bulkDetails.bulksOK === bulkDetails.totalBulks) {
		toast.success(<div className="dismissToast">{numberUploaded}</div>)
	}
	if (
		bulkDetails.bulksNotOK > 0 &&
		bulkDetails.bulksNotOK !== bulkDetails.totalBulks
	) {
		toast.warning(<div className="dismissToast">{warningAndErrorText}</div>)
	}
	if (bulkDetails.bulksNotOK === bulkDetails.totalBulks) {
		toast.error(<div className="dismissToast">{warningAndErrorText}</div>)
	}
}
