import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import i18n from 'language/i18n'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { isMobile } from 'react-device-detect'

import { SelectType } from 'types/SelectType'
import { useTranslation } from 'react-i18next'

const StyledSelect = styled(Select)`
	.rs__control {
		width: 100%;
		height: 42px;
		background-color: transparent;
		border-radius: 5px;
		font-size: var(--font-size-field-text);
		font-family: var(--font-family-opensans);
		cursor: pointer;
		border: none;
	}

	.rs__single-value {
		color: var(--color-white);
	}

	.rs__input-container {
		color: var(--color-white);
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__menu-list {
		font-size: var(--font-size-field-text);
		font-family: var(--font-family-opensans);
	}
`

/**
 * Drop down to select languages between norwegian and english.
 */
export const LanguageDropDown = () => {
	const { t } = useTranslation('common')

	const languages: string[] = ['no', 'en']

	const currentLocale = i18n.languages.find(
		(lng) => languages.indexOf(lng) !== -1
	)

	/**
	 * Maps the language code to the correct word
	 * @param language
	 */
	const mapLanguage = (language: string) => {
		if (language === 'no')
			return isMobile
				? t('language.norwegian.short')
				: t('language.norwegian.full')
		if (language === 'en')
			return isMobile
				? t('language.english.short')
				: t('language.english.full')
	}

	/**
	 * Combines the unicode flag with the language text
	 * @param l
	 */
	const combineFlagAndText = (l: string) => {
		return getUnicodeFlagIcon(l === 'en' ? 'gb' : l) + ' ' + mapLanguage(l)
	}

	const options: SelectType[] = languages.map((l: string) => {
		return {
			value: l,
			label: combineFlagAndText(l)
		}
	})

	const toggleLanguage = (s: string) => {
		i18n.changeLanguage(s)
	}

	return (
		<StyledSelect
			options={options}
			isMulti={false}
			classNamePrefix="rs"
			onChange={(a: unknown) => {
				const o = a as SelectType
				toggleLanguage(o.value)
			}}
			value={{ label: combineFlagAndText(currentLocale ?? 'no') }}
			isSearchable={false}
		/>
	)
}
