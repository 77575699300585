import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { displayFormatDate } from 'utils/displayFormatDate'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { LifeCertificateDetails } from 'pages/company-pages/BeneficiaryPage/LifeCertificateContainer/LifeCertificateDetails'

import { Button as BaseButton } from 'components/company-components/button/Button'
import { Icon as BaseIcon } from 'components/icon/Icon'
import { ButtonText as BaseButtonText } from 'components/company-components/text/ButtonText'

import { buttonStyleWhiteMedium } from 'styles/button'

import { DeleteLCModal } from './DeleteLCModal'

const Button = styled(BaseButton)`
	${buttonStyleWhiteMedium}
	min-width: 200px;
	margin-top: 20px;

	&:hover {
		border: solid var(--color-main-dark_blue);
	}

	&:focus {
		border: solid var(--color-main-dark_blue);
		background-color: var(--color-main-dark_blue);
	}
`

const Icon = styled(BaseIcon)`
	font-size: 20px;
	margin-right: 5px;
`

const ButtonText = styled(BaseButtonText)`
	color: inherit;
`

interface Props {
	issueDate: Date
	expiryDate: Date
	beneficiary: BeneficiaryType
	method: 'digital' | 'manual'
	onBeneficiaryChange: () => void
}

/**
 * Case 6 - The life certificate is valid
 *
 * @param props.issueDate - Issue date of the life certificate
 * @param props.expiryDate - Expiry date of the life certificate
 * @param props.beneficiary - The beneficiary
 * @param props.method - The way it was verified
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const CaseValid = ({
	issueDate,
	expiryDate,
	beneficiary,
	method,
	onBeneficiaryChange
}: Props) => {
	const { t } = useTranslation(['common', 'company'])

	const { isAdmin } = useIsAdminRole()

	const [modalOpen, setModalOpen] = useState(false)

	const displayButton = () => {
		if (!isAdmin) return null
		return (
			<Button onClick={() => setModalOpen(true)}>
				<Icon name="Trash" />
				<ButtonText>{t('company:beneficiary.delete.LC.button')}</ButtonText>
			</Button>
		)
	}

	return (
		<LifeCertificateDetails
			status={t('common:status.valid')}
			listItems={[
				{
					items: [
						{
							title: t('company:beneficiary.cert.valid.from'),
							text: displayFormatDate(issueDate)
						},
						{
							title: t('company:beneficiary.cert.valid.to'),
							text: displayFormatDate(expiryDate)
						}
					]
				}
			]}
			beneficiary={beneficiary}
			additionalTopRowItem={{
				title: t('company:beneficiary.cert.valid.verification'),
				text:
					method === 'manual'
						? t('company:beneficiary.cert.valid.method_manual') // Only way to fix this was to split it out
						: t('company:beneficiary.cert.valid.method')
			}}
		>
			{displayButton()}
			<DeleteLCModal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				name={beneficiary.name}
				nin={beneficiary.nationalIdentityNumber}
				onBeneficiaryChange={onBeneficiaryChange}
			/>
		</LifeCertificateDetails>
	)
}
