import React from 'react'
import styled, { css } from 'styled-components'

import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'

const SubHeaderText = styled(BaseSubHeaderText).attrs({ as: 'p' })`
	color: inherit;
`

/**
 * Interface to handle the style of the circle
 */
interface IStyledCircle {
	isActive: boolean
	isPast: boolean
}

const Wrapper = styled.button<IStyledCircle>`
	border-radius: 100%;
	background-color: var(--color-white);
	border: solid 0.01em var(--color-black);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	${(props) =>
		props.isActive
			? css`
					background-color: var(--color-main-turquoise);
					border: none;
			  `
			: props.isPast
			? css`
					background-color: var(--color-main-dark_blue);
					color: var(--color-white);
					border: none;
			  `
			: null}

	&:focus {
		outline: 1px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

interface Props {
	tabText: 1 | 2 | 3 | 4
	isActive: boolean
	isPast: boolean
	onClick: () => void
}

/**
 * Component to show a tab (circle) in the tab menu
 *
 * @param props.tabText The text of the tabs
 * @param props.isActive If the tab is active
 * @param props.isPast If the tab is past
 * @param props.onClick On click tab
 */
export const Circle = ({ tabText, isActive, isPast, onClick }: Props) => {
	return (
		<Wrapper isActive={isActive} isPast={isPast} onClick={onClick}>
			<SubHeaderText>{tabText}</SubHeaderText>
		</Wrapper>
	)
}
