import { useTranslation } from 'react-i18next'

import { CommunicationType } from 'types/CommunicationType'
import { BeneficiaryType } from 'types/BeneficiaryType'
import { RequestType } from 'types/RequestType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { Button } from 'components/company-components/button/Button'

import { getSortedDateAtPosition } from 'utils/getSortedDateAtPosition'

import { CommentListTextItem } from '../CommentListTextItem'
import { ButtonContainer } from '../common'
import { LCReceivedOnPaper } from '../LCReceivedOnPaper'
import { getRequestText } from '../getRequestText'
import { LifeCertificateDetails } from '../LifeCertificateDetails'

interface Props {
	onClickSend: () => void
	communications: CommunicationType[]
	beneficiary: BeneficiaryType
	request: RequestType
	onBeneficiaryChange: () => void
}

/**
 * Case 2 - The life certificate has been requested or reminded
 *
 * @param onClickSend function to handle the sending of a request or reminder
 * @param communications list of the communications to send it on
 * @param beneficiary the beneficiary
 * @param request the request object
 * @param onBeneficiaryChange function to handle when beneficiary details change
 */
export const CaseRequestedOrReminded = ({
	onClickSend,
	communications,
	beneficiary,
	request,
	onBeneficiaryChange
}: Props) => {
	const { t } = useTranslation(['common', 'company'])
	const { isAdmin } = useIsAdminRole()

	const isReminder = request.status === 'reminded'

	const displayComment = () => {
		if (
			beneficiary.lifeCertificate === null ||
			beneficiary.lifeCertificate.comment === null
		)
			return null
		return (
			<CommentListTextItem comment={beneficiary.lifeCertificate.comment} />
		)
	}

	/**
	 * If Admin user display register button, else display send button
	 */
	const displayButton = () => {
		if (isAdmin) {
			return (
				<ButtonContainer>
					<LCReceivedOnPaper
						uuid={request.uuid}
						nin={beneficiary.nationalIdentityNumber}
						onBeneficiaryChange={onBeneficiaryChange}
					/>
				</ButtonContainer>
			)
		} else {
			return (
				<ButtonContainer>
					<Button
						text={
							isReminder
								? t('company:beneficiary.cert.reminded.button')
								: t('company:beneficiary.cert.requested.button')
						}
						onClick={onClickSend}
						icon={{
							name: 'SendPlane'
						}}
					/>
				</ButtonContainer>
			)
		}
	}

	const getListItems = () => {
		let lst = [
			{
				title: t('company:beneficiary.cert.request'),
				text: getRequestText(
					communications,
					getSortedDateAtPosition(communications, 0)
				)
			}
		]
		if (isReminder) {
			lst.push({
				title: t('company:beneficiary.cert.reminder'),
				text: getRequestText(
					communications,
					getSortedDateAtPosition(
						communications,
						communications.length - 1
					)
				)
			})
		}
		return lst
	}

	return (
		<LifeCertificateDetails
			status={t('common:status.invalid')}
			listItems={getListItems()}
			beneficiary={beneficiary}
		>
			{displayComment()}
			{displayButton()}
		</LifeCertificateDetails>
	)
}
