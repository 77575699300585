import { ReactNode } from 'react'
import styled from 'styled-components'

import { Text as BaseText } from 'components/text/Text'

const StyledTableData = styled.td`
	padding-block: 12.5px;
	background-color: var(--color-white);
	vertical-align: middle;
`

const Text = styled(BaseText)`
	font-weight: var(--font-weight-light);
`

interface Props {
	text?: string
	children?: ReactNode
	className?: string
}

/**
 * Component to display table data
 *
 * @param props.text text to display
 * @param props.children React children
 * @param props.className the className of the component
 */
export const TableData = ({ text, children, ...rest }: Props) => {
	return (
		<StyledTableData {...rest}>
			{text ? <Text>{text}</Text> : children}
		</StyledTableData>
	)
}
