import { BeneficiaryType } from 'types/BeneficiaryType'

import { mapJsonObjectToLifeCertificateType } from './mapJsonObjectToLifeCertificateType'
import { mapJsonObjectToContactType } from './mapJsonObjectToContactType'
import { mapJsonObjectToRequestType } from './mapJsonObjectToRequestType'

/**
 * Function to map from a JSON object from backend to beneficiary type
 *
 * @param responseJSON the object to map
 *
 * @returns the mapped beneficiary object
 */
export const mapJsonObjectToBeneficiaryType = (
	responseJSON: unknown
): BeneficiaryType => {
	const beneficiary: BeneficiaryType = responseJSON as BeneficiaryType

	return {
		...beneficiary,
		lifeCertificate:
			beneficiary.lifeCertificate === null
				? null
				: mapJsonObjectToLifeCertificateType(beneficiary.lifeCertificate),
		nationalIdentityNumber: beneficiary.nationalIdentityNumber,
		birthdate: new Date(beneficiary.birthdate),
		contact: mapJsonObjectToContactType(beneficiary.contact),
		request:
			beneficiary.request === null
				? null
				: mapJsonObjectToRequestType(beneficiary.request),
		createdOn: new Date(beneficiary.createdOn)
	}
}
