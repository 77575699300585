import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { ButtonText as BaseButtonText } from 'components/private-components/text/ButtonText'

import { buttonStyleWhite, privateButtonStyle } from 'styles/button'
import { styleChain } from 'utils/styleChain'

const ButtonText = styled(BaseButtonText)`
	color: inherit;
`

interface IStyledButton {
	fillWidth?: boolean
}

export const StyledAButton = styled.a<IStyledButton>`
	${privateButtonStyle}

	${({ color }) =>
		color === 'white'
			? buttonStyleWhite
			: css`
					background-color: ${color};
			  `}

	width: ${styleChain().case('fillWidth', '100%').default('auto')};
`

interface Props extends React.ComponentPropsWithoutRef<'a'> {
	text?: string
	fillWidth?: boolean
	buttonColor?: string
	openInNewTab?: boolean
	children?: ReactNode
}

/**
 * Component to display an a element as a button component
 *
 * @param props.text text on the button
 * @param props.fillWidth to fill its parents width or not
 * @param props.buttonColor color of the button
 * @param props.openInNewTab if the href should open in new tab
 * @param props.children React children
 */
export const AButton = ({
	text,
	fillWidth,
	buttonColor,
	openInNewTab,
	children,
	...rest
}: Props) => {
	return (
		<StyledAButton
			color={buttonColor}
			{...(openInNewTab
				? { target: '_blank', rel: 'noopener noreferrer' }
				: {})}
			{...rest}
		>
			{text ? <ButtonText>{text}</ButtonText> : children}
		</StyledAButton>
	)
}
