import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CompanyListElementType } from 'types/CompanyListElementType'

import { Icon as BaseIcon } from 'components/icon/Icon'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { TableHeader } from 'components/company-components/tableHeader'

import { TableDataRow } from './TableDataRow'

const StyledTable = styled.table`
	border-collapse: separate;
	border-spacing: 0 4px;
	width: 100%;
`

const TableHeaderNin = styled(TableHeader)`
	padding-left: 20px;
`

const TableHeaderStatus = styled(TableHeader)`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const IconWrapper = styled.div`
	width: 20px;
	height: 20px;
`

const Icon = styled(BaseIcon)`
	margin-left: 5px;
	font-size: 20px;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	text-align: left;
	padding-block: 5px;
`

interface Props {
	list: CompanyListElementType[]
}

/**
 * Component to display the Table containing the invalid life certificates
 *
 * @param props.list the list to display
 * @param props.onClickSortHeader function to handle the on click of the sort header
 * @param props.isSortAscending flag to handle which way the sort arrow should point
 */
export const Table = ({ list, ...rest }: Props) => {
	const { t } = useTranslation('company')

	return (
		<StyledTable {...rest}>
			<thead>
				<tr>
					<TableHeaderNin
						width="25%"
						text={t('companyList.list.table.birth')}
					/>
					<TableHeader
						width="45%"
						text={t('companyList.list.table.name')}
					/>
					<TableHeaderStatus width="30%">
						<SubHeaderText as="p">
							{t('companyList.list.table.status')}
						</SubHeaderText>
						<IconWrapper>
							<Icon name="LCStatus" />
						</IconWrapper>
					</TableHeaderStatus>
				</tr>
			</thead>
			<tbody>
				{list.map((element: CompanyListElementType, key: number) => {
					return <TableDataRow key={key} element={element} />
				})}
			</tbody>
		</StyledTable>
	)
}
