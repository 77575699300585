import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import i18n from 'language/i18n'

import { mapListToFormattedJSX } from 'utils/mapListToFormattedJSX'

import { Text } from 'components/text/Text'
import { HeaderText1 } from 'components/text/HeaderText1'
import { SubHeaderText } from 'components/text/SubHeaderText'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
	width: 100%;
`

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const TextWrapper = styled.div`
	margin-top: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const CounterText = styled(Text)`
	font-size: 18px;
	bottom: 0;
	right: 0;
	position: absolute;
	padding-right: 20px;
	padding-bottom: 20px;
`

const Bold = styled(SubHeaderText).attrs({ as: 'strong' })``

interface Props {
	type: 'request' | 'remind'
	beneficiaryName: string
	selectedChannels: ('sms' | 'email' | 'post')[]
	time: number
}

/**
 * Content to show inside the modal when a request has been successfully sent
 *
 * @param props.type 'request' or 'remind', determines how to render some text strings
 * @param props.beneficiaryName name of beneficiary
 * @param props.selectedChannels Which channels have been selected on the send request view
 * @param props.time Time remaining until the modal closes
 */
export const SuccessfullySentContainer = ({
	type,
	beneficiaryName,
	selectedChannels,
	time
}: Props) => {
	const { t } = useTranslation('company')

	const currentLocale = i18n.language

	const mapChannelsByLanguage = (channels: ('sms' | 'email' | 'post')[]) => {
		let lst: any[]
		if (currentLocale === 'no') {
			lst = channels.map((c) => {
				if (c === 'email') return 'e-post'
				return c
			})
		} else {
			lst = channels
		}
		return lst
	}

	return (
		<Container>
			<Wrapper>
				<HeaderText1>
					{type === 'request'
						? t('beneficiary.cert.sent.title')
						: t('beneficiary.cert.sent.title_reminder')}
				</HeaderText1>
				<TextWrapper>
					<Text>
						<Trans i18nKey="company:beneficiary.cert.sent.text">
							<span>til </span>
							<SubHeaderText as="span">
								{{ name: beneficiaryName } as any}
							</SubHeaderText>
							<span> på </span>
						</Trans>
						{mapListToFormattedJSX(
							mapChannelsByLanguage(selectedChannels),
							Bold
						)}
					</Text>
				</TextWrapper>
			</Wrapper>
			<CounterText>{t('beneficiary.cert.sent.close', { time })}</CounterText>
		</Container>
	)
}
