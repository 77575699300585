import { useTranslation } from 'react-i18next'
import { CompletedBankIdContent } from './CompletedBankIDContent'

/**
 * Page to display the success page when the bank id process is completed
 */
export const SuccessFullyUploadedLCPage = () => {
	const { t } = useTranslation('private')
	return (
		<CompletedBankIdContent
			success
			title={t('success.title')}
			text={t('success.text')}
		/>
	)
}
