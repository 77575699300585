import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useOnce } from 'hooks/useOnce'

import { CompanyListElementType } from 'types/CompanyListElementType'

import { getAllBeneficiariesInACompany } from 'api/services/BeneficiaryService'

import { Text as BaseText } from 'components/text/Text'
import { Spinner } from 'components/Spinner'

import { NavigationButtons } from 'pages/company-pages/CompanyDashboardPage/LCList/NavigationButtons'
import { SelectRows } from 'pages/company-pages/CompanyDashboardPage/LCList/SelectRows'

import { Table } from './Table/'
import { SearchBox } from './SearchBox'
import { Filter } from './Filter'

const Wrapper = styled.div`
	margin-bottom: 40px;
	width: 100%;
	padding-inline: 10%;
`

const TopWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 0;
`

const TopWrapperRight = styled.div`
	display: flex;
	flex-direction: column;
`

const RowSelectWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
`

const BottomWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
`

const BodyWrapper = styled.div`
	background-color: var(--color-box);
	display: flex;
	padding: 40px;
	padding-top: 30px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0.5px 2px rgb(0, 0, 0, 25%);
`

const ButtonWrapper = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
`

const Text = styled(BaseText)`
	font-weight: var(--font-weight-light);
	margin-right: 20px;
`

const EmptyText = styled(BaseText)`
	text-align: center;
	margin-block: 50px;
`

const Label = styled.label`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-light);
	margin: 0;
	margin-right: 20px;
`

const BottomTextLeftWrapper = styled.div`
	width: 100%;
`

export const CompanyList = () => {
	// To handle the company list and the values gathered from it
	const [companyList, setCompanyList] = useState<CompanyListElementType[]>()
	const [totalElements, setTotalElements] = useState(0)
	const [totalPages, setTotalPages] = useState(0)

	// To handle the search values to update the list
	const [currentPageNumber, setCurrentPageNumber] = useState(1)
	const [isLastPage, setIsLastPage] = useState(false)
	const [filter, setFilter] =
		useState<'all' | 'valid' | 'invalid' | 'processStarted'>('all')
	const [searchText, setSearchText] = useState('')
	const [pageSize, setPageSize] = useState<25 | 50 | 100>(25)

	// To show a spinner when loading, and flag to stop spinner if error
	const [showSpinner, setShowSpinner] = useState(false)

	// Handle error
	const [error, setError] = useState(false)

	const { t } = useTranslation('company')

	/**
	 * Get the list of beneficiaries in the company
	 */
	useOnce(() => {
		setShowSpinner(true)
		getCompanyList(pageSize, currentPageNumber, searchText, filter)
	})

	/**
	 * Update the list when inputs changes	 */
	useEffect(() => {
		if (showSpinner) {
			getCompanyList(pageSize, currentPageNumber, searchText, filter)
		}
		// eslint-disable-next-line
	}, [showSpinner, pageSize, currentPageNumber, searchText, filter])

	/**
	 * Gets the list of beneficiaries belonging to the company
	 *
	 * @param pageSize the size of the page to load
	 * @param pageNumber the page number to display
	 * @param search the search value (nin or name)
	 * @param filter the filter to be used
	 */
	const getCompanyList = (
		pageSize: number,
		pageNumber: number,
		search: string,
		filter: 'all' | 'valid' | 'invalid' | 'processStarted'
	) => {
		getAllBeneficiariesInACompany(pageSize, pageNumber - 1, search, filter)
			.then((res) => {
				setCompanyList(res.data.beneficiaries as CompanyListElementType[])
				setTotalElements(res.data.totalElements as number)
				setTotalPages(res.data.totalPages as number)
				setIsLastPage(res.data.last as boolean)
				setShowSpinner(false)
			})
			.catch((err) => {
				setShowSpinner(false)
				setError(true)
				console.error(err)
				console.log({ err })
			})
	}

	/**
	 * Loads the table again with the given page size and page number
	 * @param size page size
	 * @param number page number
	 * @param search the search value
	 * @param newFilter the new filter
	 */
	const loadNewTableContent = (
		size: number,
		number: number,
		search: string,
		newFilter: 'all' | 'valid' | 'invalid' | 'processStarted'
	) => {
		setShowSpinner(true)
		getCompanyList(size, number, search, newFilter)
	}

	/**
	 * Function to update the table elements when the page size increases or decreases.
	 * It also sets the current page number back to first page.
	 */
	const updateTableSize = (newPageSize: 25 | 50 | 100) => {
		setPageSize(newPageSize)
		setCurrentPageNumber(1)
		loadNewTableContent(newPageSize, 1, searchText, filter)
	}

	/**
	 * Function to update the table elements when the page changes
	 */
	const updateTablePage = (newPageNumber: number) => {
		setCurrentPageNumber(newPageNumber)
		loadNewTableContent(pageSize, newPageNumber, searchText, filter)
	}

	/**
	 * Function to update the table elements when the search values change
	 */
	const updateTableSearch = (val: string) => {
		setSearchText(val)
		setCurrentPageNumber(1)
		loadNewTableContent(pageSize, 1, val, filter)
	}

	/**
	 * Function to update the table elements when the filter values change
	 */
	const updateTableFilter = (
		newFilter: 'all' | 'valid' | 'invalid' | 'processStarted'
	) => {
		setFilter(newFilter)
		setCurrentPageNumber(1)
		loadNewTableContent(pageSize, 1, searchText, newFilter)
	}

	/**
	 * Gets the rowId of the first row in the visible list.
	 * It takes the page size and multiplies it with the current page number, before subtracting the pagesize + 1
	 */
	const getRowFrom = () => {
		return pageSize * currentPageNumber - pageSize + 1
	}

	/**
	 * Gets the rowId of the last row in the visible list.
	 * It takes the id of the first row and adds pageSize - 1
	 */
	const getRowTo = () => {
		if (isLastPage && companyList) {
			return getRowFrom() + companyList.length - 1
		}
		return getRowFrom() + pageSize - 1
	}

	const displayBottomContent = () => {
		if (companyList && companyList.length > 0) {
			return (
				<BottomWrapper>
					<BottomTextLeftWrapper>
						<Text>
							{t('companyList.list.total', {
								from: getRowFrom(),
								to: getRowTo(),
								totalElements
							})}
						</Text>
					</BottomTextLeftWrapper>
					<ButtonWrapper>
						<NavigationButtons
							totalPages={totalPages}
							currentPageNumber={currentPageNumber}
							onClickPageNumber={(pageNumber: number) => {
								updateTablePage(pageNumber)
							}}
						/>
					</ButtonWrapper>
				</BottomWrapper>
			)
		}
		return null
	}

	const displayListContent = () => {
		if (companyList === undefined || showSpinner) {
			return <Spinner />
		}
		if (companyList && companyList.length > 0) {
			return (
				<>
					<Table list={companyList} />
					{displayBottomContent()}
				</>
			)
		}
		return <EmptyText>{t('companyList.list.none')}</EmptyText>
	}

	const displayContent = () => {
		if (error) {
			return <Text>{t('companyList.list.error')}</Text>
		}

		return (
			<>
				<TopWrapper>
					<SearchBox value={searchText} onSearch={updateTableSearch} />
					<TopWrapperRight>
						<Filter
							onSelectFilter={(val) => updateTableFilter(val)}
							currentFilter={filter}
						/>
						<RowSelectWrapper>
							<Label htmlFor="selectPageSize">
								{t('companyList.list.rows')}
							</Label>
							<SelectRows
								onSelect={(num) => {
									updateTableSize(num)
								}}
								currentPageSize={pageSize}
								id="selectPageSize"
							/>
						</RowSelectWrapper>
					</TopWrapperRight>
				</TopWrapper>
				{displayListContent()}
			</>
		)
	}

	return (
		<Wrapper>
			<BodyWrapper>{displayContent()}</BodyWrapper>
		</Wrapper>
	)
}
