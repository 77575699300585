import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { displayFormatDate } from 'utils/displayFormatDate'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { Button } from 'components/company-components/button/Button'

import { LifeCertificateDetails } from '../LifeCertificateDetails'

const ButtonWrapper = styled.div`
	margin-top: 10px;
`

interface Props {
	expiryDate: Date
	beneficiary: BeneficiaryType
	onClick: () => void
}

/**
 * Case 5 - The life certificate has expired
 *
 * @param props.expiryDate - Expiry date of the life certificate
 * @param props.beneficiary - The beneficiary
 * @param props.onClick - Callback called when the request button is pressed
 */
export const CaseExpired = ({ expiryDate, beneficiary, onClick }: Props) => {
	const { t } = useTranslation(['common', 'company'])
	const { isAdmin } = useIsAdminRole()

	const displayButton = () => {
		if (isAdmin) return null
		return (
			<ButtonWrapper>
				<Button
					text={t('company:beneficiary.cert.expired.button')}
					icon={{ name: 'SendPlane' }}
					onClick={onClick}
				/>
			</ButtonWrapper>
		)
	}

	return (
		<LifeCertificateDetails
			status={t('common:status.invalid')}
			listItems={[
				{
					items: [
						{
							title: t('company:beneficiary.cert.expired.title'),
							text: displayFormatDate(expiryDate)
						}
					]
				}
			]}
			beneficiary={beneficiary}
		>
			{displayButton()}
		</LifeCertificateDetails>
	)
}
