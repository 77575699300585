import styled from 'styled-components'

/**
 * Text component to display button text
 *
 * Color: #051D2E or #FFFFFF
 * Size: 16px / 20px
 * Family: Open Sans Semi Bold
 * Weight: 600
 */
export const ButtonText = styled.p`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-button-text_private);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-almost-bold);
	display: flex;
`
