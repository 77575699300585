import styled, { css } from 'styled-components'

export const Label = styled.label<{ mb?: string }>`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-field-text);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-bold);
	margin: 0;
	${(props) =>
		props.mb &&
		css`
			margin-bottom: ${props.mb};
		`}
`
