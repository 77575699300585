import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { IconButton as BaseIconButton } from 'components/icon/IconButton'

const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
`

const Input = styled.input`
	resize: none;
	font-size: var(--font-size-text);
	font-weight: var(--font-weight-light);
	font-family: var(--font-family-merriweather);
	width: 400px;
	min-height: 40px;
	border-radius: 5px;
	border: solid 1px var(--color-main-blue_green);
	box-sizing: border-box;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
`

const IconButton = styled(BaseIconButton)`
	position: absolute;
	color: var(--color-main-dark_blue);
	min-height: 2rem;
	min-width: 2rem;
	padding: 4px;
	box-sizing: border-box;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	font-size: 30px;
`

interface InputTypes {
	ninOrName: string
}

interface Props {
	value: string
	onSearch: (v: string) => void
}

/**
 * Displays the textarea where the user can search for nin or name of a beneficiary.
 * This does not perform live searches when the user types - TODO in the future
 *
 * @param props.value the value in the textarea
 * @param props.onSubmit searches with the new value
 */
export const SearchBox = ({ value, onSearch }: Props) => {
	const { t } = useTranslation('company')

	const { register, handleSubmit } = useForm<InputTypes>()

	const [val, setVal] = useState(value)

	const onSubmit = () => {
		onSearch(val)
	}

	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (['Enter', 'NumpadEnter'].includes(e.key)) {
			onSubmit()
		}
	}

	return (
		<Form id="searchbox" onSubmit={handleSubmit(onSubmit)}>
			<Input
				{...register('ninOrName', {
					required: true
				})}
				aria-label={t('companyList.search.placeholder')}
				placeholder={t('companyList.search.placeholder')}
				value={val}
				onChange={(e) => {
					setVal(e.target.value as string)
				}}
				onKeyDown={onKeyPress}
			/>
			<IconButton
				icon="Search"
				screenReaderText="Søk"
				type="submit"
				// To make it possible to search for the empty string by clicking the icon
				onClick={() => {
					if (val === '') onSearch(val)
				}}
			/>
		</Form>
	)
}
