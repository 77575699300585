import { ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { COLOR_WHITE } from 'styles/baseColors'
import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { Logo } from 'components/svg/Logo'
import { HeaderText2 } from 'components/text/HeaderText2'
import { LanguageDropDown } from 'components/common/LanguageDropDown'

const Wrapper = styled.header`
	display: none;
	padding: 15px 30px;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-main-dark_blue);

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: flex;
	}
`

const LeftHeaderContainer = styled.div`
	display: flex;
	align-items: center;
`

const CompanyHeaderText2 = styled(HeaderText2)`
	padding-left: 30px;
`

interface Props {
	title?: ReactNode
	hasCompany?: boolean
	companyName?: string | undefined
}

/**
 * Component to display the header when the application is opened on a browser
 *
 * @param hasCompany if the header has company included
 * @param companyName the company to display in the header
 */
export const BrowserHeader = ({
	title,
	hasCompany,
	companyName: company
}: Props) => {
	const { t } = useTranslation('common')

	const getTitleAreaContent = () => {
		if (title) {
			if (typeof title === 'string')
				return (
					<CompanyHeaderText2 as="h1" color="white">
						{title}
					</CompanyHeaderText2>
				)
			return title
		}
		if (hasCompany && company && company !== 'undefined')
			return (
				<CompanyHeaderText2 as="h1" color="white">
					{t('header.desktop.company', { company })}
				</CompanyHeaderText2>
			)
	}

	return (
		<Wrapper>
			<LeftHeaderContainer>
				<Logo color={COLOR_WHITE} width="200" height="40" />
				{getTitleAreaContent()}
			</LeftHeaderContainer>
			<LanguageDropDown />
		</Wrapper>
	)
}
