import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { postUploadAndValidateFileAsFnf } from 'api/services/LifeCertificateService'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { Modal } from 'components/common/Modal'
import { Spinner } from 'components/Spinner'
import { Button as BaseCompanyButton } from 'components/company-components/button/Button'

import { ValidationButtons as BaseValidationButtons } from 'pages/company-pages/ValidateLCModal/ValidationButtons'
import { CommentPage } from 'pages/company-pages/ValidateLCModal/CommentPage'

import { buttonColorLight } from 'styles/button'

const CompanyButton = styled(BaseCompanyButton)`
	margin-top: 20px;
	padding-block: 5px;
	min-width: 300px;
	box-shadow: 0 1px 4px rgb(0, 0, 0, 25%);

	&:hover {
		${(props) => props.disabled && buttonColorLight}
	}
`

const ValidationButtons = styled(BaseValidationButtons)`
	margin-top: 20px;
`

interface Props {
	files: File[]
	onReset: () => void
	onSuccess: () => void
	uuid: string
}

/**
 * Component to split the send button and the modal out of the file uploader component
 *
 * @param props.files files to upload
 * @param props.uuid uuid of the request
 * @param props.onReset function to handle resetting
 * @param props.onSuccess function to handle success
 */
export const UploadButtonAndModalFNF = ({
	files,
	uuid,
	onReset,
	onSuccess
}: Props) => {
	const { t } = useTranslation(['company', 'common'])
	const { isAdmin } = useIsAdminRole()

	// To handle loading
	const [isLoading, setIsLoading] = useState(false)

	// To handle the modal
	const [modalOpen, setModalOpen] = useState(false)

	const [isValid, setIsValid] = useState<boolean>()
	const [markedAsInvalid, setMarkedAsInvalid] = useState(false)

	const closeModal = () => {
		setModalOpen(false)
	}

	const reset = () => {
		onReset()
		setModalOpen(false)
	}

	/**
	 * Function to validate that a file is of type pdf
	 * @param file file to validate
	 * @return boolean
	 */
	const isFilePDF = (file: File) => {
		const validType = ['application/pdf']
		return validType.indexOf(file.type) !== -1
	}

	const uploadLC = (isValid: boolean, comment: string) => {
		setIsLoading(true)
		postUploadAndValidateFileAsFnf(uuid, files, isValid, comment)
			.then(() => {
				setIsLoading(false)
				onSuccess()
				reset()
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
				toast.error(
					<div className="dismissToast">
						{t('common:fileUploader.backendError') as string}
					</div>
				)
				setIsLoading(false)
				setModalOpen(!isValid)
			})
	}

	const onClickRegister = () => {
		if (isValid !== undefined) {
			setModalOpen(true)
			if (isValid) {
				if (isAdmin) {
					setMarkedAsInvalid(false)
					setIsLoading(true)
					uploadLC(isValid, '')
				}
			}
			if (!isValid) {
				setMarkedAsInvalid(true)
			}
		}
	}

	const onClickUploadFile = () => {
		// Get the invalid files
		const invalidFiles = files.filter((f: File) => {
			return !isFilePDF(f)
		})

		if (invalidFiles.length === 0) {
			// Upload the files
			onClickRegister()
		}
	}

	const displayModalContent = () => {
		if (isLoading) {
			return <Spinner title={t('common:fileUploader.sending')} />
		}
		if (markedAsInvalid) {
			return (
				<CommentPage
					onClickRegisterLC={(comment: string) => {
						setMarkedAsInvalid(false)
						uploadLC(false, comment)
					}}
				/>
			)
		}
	}

	return (
		<>
			<ValidationButtons
				isValid={isValid}
				setIsValid={(v: boolean) => setIsValid(v)}
			/>
			<CompanyButton
				text={t('company:beneficiary.cert.upload.register')}
				onClick={onClickUploadFile}
				disabled={isValid === undefined}
			/>
			<Modal isOpen={modalOpen} onClose={closeModal}>
				{displayModalContent()}
			</Modal>
		</>
	)
}
