import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Logo } from 'components/svg/Logo'
import { LanguageDropDown } from 'components/common/LanguageDropDown'

import { COLOR_WHITE } from 'styles/baseColors'

const Wrapper = styled.header`
	background-color: var(--color-main-dark_blue);
	width: 100%;
`

const HeaderContentWrapper = styled.div`
	padding: 15px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const LeftHeaderContainer = styled.div`
	display: flex;
	align-items: center;
`

const HeaderText2 = styled(BaseHeaderText2)`
	padding-left: 30px;
`

/**
 * Header component
 */
export const Header = () => {
	const { t } = useTranslation(['common', 'company'])

	return (
		<Wrapper>
			<HeaderContentWrapper>
				<LeftHeaderContainer>
					<Logo color={COLOR_WHITE} width="200" height="40" />
					<HeaderText2 as="h1" color="white">
						{t('company:header.title')}
					</HeaderText2>
				</LeftHeaderContainer>
				<LanguageDropDown />
			</HeaderContentWrapper>
		</Wrapper>
	)
}
