import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { BackAndForwardButton } from '../BackAndForwardButtons'
import { InformationBox } from '../InformationBox'

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ContentWrapper = styled.div`
	margin-top: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: flex;
		justify-content: space-between;
		margin-top: 2vh;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const InformationBoxLeft = styled(InformationBox)`
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 53%;
		margin-bottom: 0;
	}
`

const InformationBoxRight = styled(InformationBox)`
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 43%;
	}
`

const ButtonWrapper = styled.div``

interface Props {
	onClickSwitchTab: (tabIndex: number, fromTab?: number) => void
}

/**
 * Details about step 2 in the walk-through process
 *
 * @param props.onClickSwitchTab 	function that navigates to another tab index page. Optional parameter fromTab is to let the
 * 									browser know which page we came from so it moves back to the correct page
 */
export const Tab2 = ({ onClickSwitchTab }: Props) => {
	const { t } = useTranslation('private')
	return (
		<>
			<TopWrapper>
				<HeaderText1>{t('tabs.2.title')}</HeaderText1>
				<ContentWrapper>
					<InformationBoxLeft text={t('tabs.2.details.text')} />
					<InformationBoxRight
						title={t('tabs.2.voluntary.title')}
						text={t('tabs.2.voluntary.text')}
						externalLinkText={t('tabs.2.voluntary.linkText')}
						onClick={() => onClickSwitchTab(5, 2)}
					/>
				</ContentWrapper>
			</TopWrapper>
			<ButtonWrapper>
				<BackAndForwardButton
					hasForwardButton
					hasBackButton
					onClickBackButton={() => {
						onClickSwitchTab(1)
					}}
					onClickForwardButton={() => {
						onClickSwitchTab(3)
					}}
				/>
			</ButtonWrapper>
		</>
	)
}
