import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Icon as BaseIcon } from 'components/icon/Icon'
import { Text as BaseText } from 'components/text/Text'

const AddFileWrapper = styled.button`
	border: none;
	background-color: transparent;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px;
	margin-block: 5px;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
		color: var(--color-main-blue_green);

		> .iconWrapper {
			background-color: var(--color-main-blue_green);
		}
	}

	&:focus {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 1px;
		color: var(--color-main-blue_green);

		> .iconWrapper {
			background-color: var(--color-main-blue_green);
		}
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const IconWrapper = styled.div`
	background-color: var(--color-main-dark_blue);
	width: 25px;
	height: 25px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const PlusIcon = styled(BaseIcon)`
	font-size: 20px;
	color: var(--color-white);
`

const AddFileText = styled(BaseText)`
	color: inherit;
	margin-left: 10px;
`

/**
 * Add file button
 */
export const AddButton = () => {
	const { t } = useTranslation('public')
	return (
		<AddFileWrapper>
			<IconWrapper className="iconWrapper">
				<PlusIcon name="Plus" />
			</IconWrapper>
			<AddFileText>{t('upload.add')}</AddFileText>
		</AddFileWrapper>
	)
}
