import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

/**
 * Link component from React Router, wrapped with styled components
 */
export const Link = styled(RouterLink)`
	text-decoration: none;
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-button-text);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-light);

	&:hover {
		font-weight: var(--font-weight-bold);
	}
`
