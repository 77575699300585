import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Modal as BaseModal } from 'components/common/Modal'
import { Spinner } from 'components/Spinner'

import { DeleteModalContent } from 'pages/company-pages/BeneficiaryPage/DeleteModalContent'

import {
	deleteBeneficiaryByIdAsFNF,
	deleteBeneficiaryByNin
} from 'api/services/BeneficiaryService'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

const Modal = styled(BaseModal)`
	min-width: 750px;
`

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-inline: 100px;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	name: string
	nin: string
	beneficiaryId: number
	onBeneficiaryChange: () => void
	company?: string
}

/**
 * Display the modal where a user has to verify the deletion of a LC
 *
 * @param props.isOpen if the modal is open or not
 * @param props.onClose function to handle the closing of modal
 * @param props.name the name of the beneficiary
 * @param props.nin the nin of the beneficiary
 * @param props.beneficiaryId the id of the beneficiary
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const DeleteBeneficiaryModal = ({
	isOpen,
	onClose,
	name,
	nin,
	beneficiaryId,
	onBeneficiaryChange,
	company
}: Props) => {
	// To handle loading
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation('company')

	const { isAdmin } = useIsAdminRole()

	const onDelete = () => {
		setIsLoading(true)

		if (isAdmin) {
			deleteBeneficiaryByIdAsFNF(beneficiaryId.toString())
				.then(() => {
					deleteSuccess()
				})
				.catch((err) => {
					deleteError(err)
				})
		} else {
			deleteBeneficiaryByNin(nin)
				.then(() => {
					deleteSuccess()
				})
				.catch((err) => {
					deleteError(err)
				})
		}
	}

	const deleteSuccess = () => {
		setIsLoading(false)
		onBeneficiaryChange()
		toast.success(
			<div className="dismissToast">
				{t('beneficiary.delete.beneficiary.success')}
			</div>
		)
	}

	const deleteError = (err: any) => {
		setIsLoading(false)
		console.log({ err })
		console.error(err)
		toast.error(
			<div className="dismissToast">
				{t('beneficiary.delete.beneficiary.error') as string}
			</div>
		)
	}

	const displayContent = () => {
		if (isLoading) {
			return <Spinner />
		}
		return (
			<>
				<DeleteModalContent
					onClose={onClose}
					onDelete={onDelete}
					name={name}
					title={t('beneficiary.delete.beneficiary.title')}
					type="beneficiary"
					company={company}
				/>
			</>
		)
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalContent>{displayContent()}</ModalContent>
		</Modal>
	)
}
