import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Text as BaseText } from 'components/text/Text'
import { Button as BaseButton } from 'components/company-components/button/Button'

import { buttonColorLight } from 'styles/button'

const Wrapper = styled.div`
	padding-top: 40px;
	width: 100%;
`

const Text = styled(BaseText)`
	margin-inline: 20px;
	text-align: center;
`

const CommentBoxWrapper = styled.div`
	margin-top: 40px;
	margin-inline: 75px;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	margin-bottom: 5px;
`

const TextArea = styled.textarea`
	resize: none;
	width: 100%;
	min-height: 140px;
	padding: 10px;
	border-radius: 5px;
	border: solid 1px var(--color-main-dark_blue);
	background-color: var(--color-box);
	color: var(--color-text);
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
`

const Button = styled(BaseButton)`
	min-width: 175px;

	&:hover {
		${(props) => props.disabled && buttonColorLight}
	}
`

interface Props {
	onClickRegisterLC: (comment: string) => void
}

/**
 * Component to display the modal page where the user can add a comment to why they marked the LC as invalid
 *
 * @param props.onClickRegisterLC function to be called when registering the LC
 */
export const CommentPage = ({ onClickRegisterLC }: Props) => {
	const { isAdmin } = useIsAdminRole()

	const [comment, setComment] = useState('')

	const { t } = useTranslation('company')

	const onClickRegister = () => {
		if (isAdmin) {
			if (comment.length > 0) {
				onClickRegisterLC(comment)
			} else toast.error('Du må legge til kommentar')
		}
	}

	return (
		<Wrapper>
			<Text>{t('dash.validate.invalid.text')}</Text>
			<CommentBoxWrapper>
				<SubHeaderText>{t('dash.validate.invalid.comment')}</SubHeaderText>
				<TextArea
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
				<ButtonWrapper>
					<Button
						text={t('dash.validate.invalid.button')}
						onClick={onClickRegister}
						disabled={comment.length <= 0}
					/>
				</ButtonWrapper>
			</CommentBoxWrapper>
		</Wrapper>
	)
}
