/**
 * Function to validate if an email address is valid
 *
 * @param email the email to validate
 */
export const isValidEmail = (email: string | null) => {
	if (email === null) return false
	return !!email.toLowerCase().match(
		/**
		 * This regex checks that the email is of a valid format.
		 *
		 * @example test@t - false
		 * @example test.com - false
		 * @example example@example.com - true
		 */
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	)
}
