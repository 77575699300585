import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

interface IStyledUploadField {
	active: boolean
}

const StyledUploadField = styled.div<IStyledUploadField>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px dashed var(--color-main-dark_blue);
	border-radius: 10px;
	padding: 20px;
	width: 100%;
	background-color: var(--color-box);

	${(props) =>
		props.active &&
		css`
			cursor: pointer;
			&:hover {
				border-color: var(--color-main-turquoise);
			}

			&:focus {
				outline: 2px solid var(--color-main-dark_blue);
				outline-offset: 4px;
				border-color: var(--color-main-turquoise);
			}

			&:focus-within,
			&:hover {
				path.file-color {
					fill: var(--color-main-turquoise);
				}
				path.plus-color {
					fill: var(--color-main-dark_blue);
				}
			}

			&:focus:not(:focus-visible) {
				outline: none;
			}
		`};

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 450px;
	}
`
interface Props {
	active: boolean
	children: ReactNode
}

/**
 * The upload field with dotted lines where users can upload and see their files
 *
 * @param active if border should change color when hovered, and if the field can be selected with keyboard
 * @param children react children
 */
export const UploadField = ({ children, ...rest }: Props) => {
	return <StyledUploadField {...rest}>{children}</StyledUploadField>
}
