import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

import { IconButton } from 'components/icon/IconButton'
import { BASE_BREAKPOINT_MOBILE } from '../../styles/baseBreakpoints'

const Overlay = styled.div`
	background: rgb(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledModal = styled(ReactModal)`
	background-color: var(--color-white);
	padding: 40px;
	width: 90%;
	border-radius: 10px;
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 650px;
		max-height: 100%;
	}
`

const CloseButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	padding-right: 10px;
	padding-top: 10px;
	font-size: var(--font-size-title1);
	cursor: pointer;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	children: ReactNode
}

/**
 * Reusable modal component
 * @param props.isOpen Whether to show the modal or not
 * @param props.onClose Function that is called from the modal when it should be closed
 * @param props.children React children
 */
export const ModalX = ({ isOpen, onClose, children, ...rest }: Props) => {
	return (
		<StyledModal
			isOpen={isOpen}
			onRequestClose={onClose}
			portalClassName="modal"
			overlayClassName="_"
			overlayElement={(props, contentElement) => (
				<Overlay {...props}>{contentElement}</Overlay>
			)}
			{...rest}
		>
			<CloseButtonWrapper>
				<IconButton
					icon="Close"
					screenReaderText="Lukk panelet"
					onClick={onClose}
				/>
			</CloseButtonWrapper>
			{children}
		</StyledModal>
	)
}
