import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'

import { loginRequest } from 'auth/authConfig'

const ADMIN_ROLE = 'Admin'

/**
 * Hook to get if the logged in user is admin or not
 */
export const useIsAdminRole = () => {
	const { instance, accounts } = useMsal()

	const [isAdmin, setIsAdmin] = useState<boolean | undefined>()

	const request = {
		...loginRequest,
		account: accounts[0]
	}

	/**
	 * Check if the logged in user has a role which is "Admin"
	 */
	useEffect(() => {
		if (isAdmin === undefined) {
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					const roles = response.account?.idTokenClaims?.roles

					if (roles === undefined) setIsAdmin(false)
					else if (roles.length > 0) {
						setIsAdmin(
							roles.filter((roleId: string) => roleId === ADMIN_ROLE)
								.length > 0
						)
					} else setIsAdmin(false)
				})
				.catch((e) => {
					console.log({ e })
					console.error(e)
				})
		}
		// eslint-disable-next-line
	}, [accounts, isAdmin, instance])

	return { isAdmin }
}
