import Select from 'react-select'
import styled from 'styled-components'

import { BeneficiaryType } from 'types/BeneficiaryType'
import { SelectType } from 'types/SelectType'

const StyledSelect = styled(Select)`
	min-height: 42px;
	width: 300px;
	display: flex;
	padding: 0;
	margin: 0;
	cursor: pointer;
	font-size: 16px;

	.rs__input {
		cursor: pointer;
	}

	.rs__control {
		border: solid 1px var(--color-main-blue_green);
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__indicator-container {
		cursor: pointer;
	}

	.rs__value-container {
		width: 300px;
	}

	#react-select-3-listbox {
		width: 300px;
	}
`

interface Props {
	id?: string
	onSelect: (beneficiary: BeneficiaryType) => void
	beneficiaries: BeneficiaryType[]
}

/**
 * Component to select a beneficiary by the company it belongs to
 *
 * @param props.id Id to feed to the input
 * @param props.onSelect function to handle the selection
 * @param props.beneficiaries the list of beneficiaries to select from
 */
export const SelectBeneficiaryByCompany = ({
	id,
	onSelect,
	beneficiaries,
	...rest
}: Props) => {
	const options: SelectType[] = beneficiaries.map(
		(beneficiary: BeneficiaryType) => {
			return {
				value: beneficiary.id.toString(),
				label: beneficiary.companyName ?? ''
			}
		}
	)

	/**
	 * Gets the beneficiary selected by its id
	 * @param id
	 */
	const getBeneficiaryById = (id: string) => {
		return beneficiaries.filter((b: BeneficiaryType) => {
			return b.id.toString() === id
		})[0]
	}

	return (
		<StyledSelect
			options={options}
			isMulti={false}
			classNamePrefix="rs"
			onChange={(a: unknown) => {
				const val = a as SelectType
				onSelect(getBeneficiaryById(val.value))
			}}
			defaultValue={{ label: options[0].label ?? 'Select' }}
			inputId={id}
			{...rest}
		/>
	)
}
