import Select from 'react-select'
import styled from 'styled-components'

import { SelectType } from 'types/SelectType'

const StyledSelect = styled(Select)`
	width: 85px;
	display: flex;
	padding: 0;
	margin: 0;
	cursor: pointer;

	.rs__control {
		border: solid 1px var(--color-main-dark_blue);
		border-radius: 10px;
	}

	.rs__input {
		cursor: pointer;
	}

	.rs__value-container {
		margin: 0;
		padding: 0;
	}

	.rs__single-value {
		font-size: var(--font-size-text);
		font-family: var(--font-family-merriweather);
		font-weight: var(--font-weight-light);
		text-align: center;
	}

	.rs__indicator-container {
		cursor: pointer;
	}

	.rs__value-container {
		width: 85px;
	}

	#react-select-3-listbox {
		width: 85px;
	}
`

interface Props {
	onSelect: (number: 25 | 50 | 100) => void
	currentPageSize: 25 | 50 | 100
	id: string
}

/**
 * Component to select a beneficiary by the company it belongs to
 *
 * @param props.onSelect function to handle the selection
 * @param props.currentPageSize page size of current page
 * @param props.id the aria label of the input
 */
export const SelectRows = ({
	onSelect,
	currentPageSize,
	id,
	...rest
}: Props) => {
	const list: number[] = [25, 50, 100]

	const options: SelectType[] = list.map((num: number) => {
		return {
			value: num.toString(),
			label: num.toString()
		}
	})

	return (
		<StyledSelect
			options={options}
			isMulti={false}
			classNamePrefix="rs"
			onChange={(a: unknown) => {
				const val = a as SelectType
				onSelect(parseInt(val.value) as 25 | 50 | 100)
			}}
			defaultValue={{ label: currentPageSize }}
			isSearchable={false}
			inputId={id}
			{...rest}
		/>
	)
}
