import styled from 'styled-components'

import { Text } from 'components/text/Text'

/**
 * Displays the text
 */
export const BoxIngressText = styled(Text)`
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-box-ingress);
	text-align: center;
`
