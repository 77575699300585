import { ReactNode, ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Text as BaseText } from 'components/text/Text'
import { Icon, IconProps } from 'components/icon/Icon'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { TextButtonOrExternalLink } from './TextButtonOrExternalLink'

const Wrapper = styled.div`
	display: flex;
	background-color: var(--color-grey);
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	column-gap: 10px;
	&:last-child {
		margin-bottom: 0;
	}
	@media screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
`

interface IRightSideWrapper {
	justifyContent?: string
}

const RightSideWrapper = styled.div<IRightSideWrapper>`
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => props.justifyContent};
`

const Text = styled(BaseText)`
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		line-height: 27px;
	}
`

const SubHeaderText = styled(BaseSubHeaderText)`
	margin-bottom: 10px;
`

const IconWrapper = styled.div`
	min-height: 40px;
	max-height: 40px;
	min-width: 40px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
`

interface Props {
	icon?: IconProps
	leftChildren?: ReactNode
	rightChildren?: ReactNode
	title?: string
	text?: ReactNode
	externalLinkText?: string
	href?: string
	onClick?: () => void
	externalLinkIcon?: IconProps
	justifyContentRight?: string
	rightSideProps?: ComponentPropsWithoutRef<'div'>
	children?: ReactNode
}

/**
 * Component to show the grey boxes to be filled with information
 * about what the user must do to connect with the Bank ID app
 *
 * @param props.icon Optional icon
 * @param props.leftChildren Optional components placed to the left of the text
 * @param props.rightChildren Replaces the text prop for text on the right
 * @param props.title main title
 * @param props.text main text
 * @param props.externalLinkText optional text for an external link
 * @param props.href optional href for an external link
 * @param props.onClick optional button action
 * @param props.externalLinkIcon optional icon for an external link
 * @param props.justifyContentRight how to align content in right block
 * @param props.rightSideProps Optional. Props applied to the right side wrapper
 * @param props.children optional children that will replace all content if present
 */
export const InformationBox = ({
	icon,
	leftChildren,
	rightChildren,
	title,
	text,
	externalLinkText,
	href,
	onClick,
	externalLinkIcon,
	justifyContentRight,
	rightSideProps,
	children,
	...rest
}: Props) => {
	const displayLeftSide = () => {
		if (icon) {
			return (
				<IconWrapper>
					<Icon name={icon.name} />
				</IconWrapper>
			)
		}
		if (leftChildren) return leftChildren
		return null
	}

	return (
		<Wrapper {...rest}>
			{children ? (
				children
			) : (
				<>
					{displayLeftSide()}
					<RightSideWrapper
						justifyContent={justifyContentRight}
						{...rightSideProps}
					>
						{title && <SubHeaderText as="h2">{title}</SubHeaderText>}
						{rightChildren ? rightChildren : <Text>{text}</Text>}
						{externalLinkText && (
							<TextButtonOrExternalLink
								onClick={onClick}
								href={href}
								externalLinkIcon={externalLinkIcon}
								externalLinkText={externalLinkText}
							/>
						)}
					</RightSideWrapper>
				</>
			)}
		</Wrapper>
	)
}
