import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { WALK_THROUGH_PAGE_PATH } from 'navigation/navigationURLs'

import { isValidUUID } from 'utils/validators/isValidUUID'

import { Tabs } from './Tabs'
import { AlternativeWaysToSendPage } from './AlternativeWaysToSendPage'
import { Tab1, Tab2, Tab3, Tab4 } from './WalkThroughTabs'
import { LoadingBankIdPage } from './LoadingBankIdPage'

const TabPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-between;
	row-gap: 50px;
`

/**
 * Page to display the walk through steps for the user before entering
 * the Bank ID application
 *
 * Overview of the pageTabIds:
 * 		1 = Tab 1
 * 		2 = Tab 2
 * 		3 = Tab 3
 * 		4 = Tab 4
 * 		5 = Alternative ways page
 */
export const WalkThroughPage = () => {
	// Get the uuid and company from the url
	const { companyName, uuid, pageId } = useParams()
	const pageIdAsNum: number = parseInt(pageId ?? '0')

	const navigate = useNavigate()

	// To handle if the user consent or not
	const [consent, setConsent] = useState(false)

	// To handle loading of bank id and the bank id error
	const [showIsLoadingBankIdPage, setShowIsLoadingBankIdPage] = useState(false)
	const [bankIdError, setBankIdError] = useState(false)

	/**
	 * If the company name or uuid doesn't exist or is valid, send the user back
	 * todo - maybe refactor to a hook named useInvalidNavigation.
	 */
	useEffect(() => {
		if (
			!uuid ||
			uuid === 'undefined' ||
			!companyName ||
			companyName === 'undefined' ||
			!isValidUUID(uuid ?? '') ||
			pageIdAsNum === 0
		) {
			window.location.href = process.env.REACT_APP_URI ?? ''
		}
	}, [companyName, uuid, pageIdAsNum])

	/**
	 * Function to handle the switching between tabs, consent page, and alternative ways page.
	 *
	 * @param toTabIndex the index of the tab to show (1=tab1, 2=tab2, 3=tab3, 4=tab4, 5=consent page, 6=alternative ways page)
	 * @param fromTabIndex the tab we came from (as alternative ways page can be reached from both tab2, tab3 and tab4, we need to know where it came from).
	 *
	 */
	const onClickSwitchTab = (toTabIndex: number, fromTabIndex?: number) => {
		navigate(
			`${WALK_THROUGH_PAGE_PATH}/${companyName}/${uuid}/${toTabIndex}`,
			{
				state: {
					fromTab: fromTabIndex
				}
			}
		)
	}

	/**
	 * Function to display the correct tab page
	 */
	const displayTabPage = () => {
		if (pageIdAsNum === 2) return <Tab2 onClickSwitchTab={onClickSwitchTab} />
		if (pageIdAsNum === 3)
			return (
				<Tab3
					consent={consent}
					setConsent={setConsent}
					onClickSwitchTab={onClickSwitchTab}
				/>
			)
		if (pageIdAsNum === 4)
			return (
				<Tab4
					setShowIsLoadingBankIdPage={setShowIsLoadingBankIdPage}
					bankIdError={bankIdError}
					setBankIdError={setBankIdError}
					onClickSwitchTab={onClickSwitchTab}
				/>
			)
		return <Tab1 onClickSwitchTab={onClickSwitchTab} />
	}

	/**
	 * Function to display the correct page
	 */
	const displayPage = () => {
		if (pageIdAsNum === 5) {
			return (
				<AlternativeWaysToSendPage onClickSwitchTab={onClickSwitchTab} />
			)
		}
		if (showIsLoadingBankIdPage) {
			return <LoadingBankIdPage />
		}
		return (
			<>
				<Tabs
					hasConsent={consent}
					pageId={pageIdAsNum}
					onClickSwitchTab={onClickSwitchTab}
				/>
				<TabPageWrapper>{displayTabPage()}</TabPageWrapper>
			</>
		)
	}

	return displayPage()
}
