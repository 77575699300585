import { ReactNode, Suspense, useEffect, useLayoutEffect, useRef } from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	redirect,
	Route,
	RouterProvider,
	useLocation,
	Outlet
} from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { ToastContainer } from 'react-toastify'

import i18n from 'language/i18n'

import 'react-toastify/dist/ReactToastify.css'

import '@fontsource/merriweather'
import '@fontsource/merriweather/300.css' // Light
import '@fontsource/merriweather/400.css' // Normal
import '@fontsource/merriweather/700.css' // Bold
import '@fontsource/merriweather/900.css' // Bold
import '@fontsource/open-sans'
import '@fontsource/open-sans/500.css' // Semi bold
import '@fontsource/open-sans/600.css' // Semi bold
import '@fontsource/open-sans/700.css' // Bold
import { ElectronicProcessStartPage } from 'pages/private-pages/ElectronicProcessStartPage'
import { LoginPage } from 'pages/company-pages/LoginPage'
import { BeneficiaryPageCompany } from 'pages/company-pages/BeneficiaryPage'
import { BeneficiaryPageFNF } from 'pages/company-pages/BeneficiaryPage/BeneficiaryPageFNF'
import { WalkThroughPage } from 'pages/private-pages/WalkThroughPage'
import { QRCodePage } from 'pages/private-pages/QRCodePage'
import { SuccessFullyUploadedLCPage } from 'pages/private-pages/SuccessFullyUploadedLCPage'
import { FailedToUploadLCPage } from 'pages/private-pages/FailedToUploadLCPage'
import { PublicPage } from 'pages/private-pages/PublicPage'
import { CompanyDashboardPage } from 'pages/company-pages/CompanyDashboardPage'
import { CompanyListPage } from 'pages/company-pages/CompanyListPage'
import { BeneficiaryPageError } from 'pages/company-pages/BeneficiaryPage/BeneficiaryPageError'

import {
	COMPANYLISTPAGE_PATH,
	ELECTRONIC_PROCESS_START_PAGE_PATH,
	FAILED_TO_UPLOAD_PATH,
	LOGIN_PATH,
	OPPGAVEBEHANDLING_PATH,
	QRCODE_PAGE_PATH,
	SUCCESSFULLY_UPLOADED_PATH,
	WALK_THROUGH_PAGE_PATH,
	YTELSESMOTTAKER_PATH
} from 'navigation/navigationURLs'

import {
	COLOR_BACKGROUND_LOGIN_PAGE,
	COLOR_BLACK,
	COLOR_BOX,
	COLOR_ERROR,
	COLOR_GREY,
	COLOR_GREY_DARK,
	COLOR_MAIN_BLUE_GREEN,
	COLOR_MAIN_DARK_BLUE,
	COLOR_MAIN_TURQUOISE,
	COLOR_TEXT,
	COLOR_WHITE,
	COLOR_YELLOW
} from 'styles/baseColors'
import {
	FONT_FAMILY_MERRIWEATHER,
	FONT_FAMILY_OPEN_SANS,
	FONT_SIZE_BOX_INGRESS,
	FONT_SIZE_BUTTON_TEXT,
	FONT_SIZE_BUTTON_TEXT_PRIVATE_BROWSER,
	FONT_SIZE_BUTTON_TEXT_PRIVATE_MOBILE,
	FONT_SIZE_FIELD_TEXT,
	FONT_SIZE_SMALL_TEXT,
	FONT_SIZE_TEXT,
	FONT_SIZE_TEXT_MOBILE,
	FONT_SIZE_TITLE1,
	FONT_SIZE_TITLE1_MOBILE,
	FONT_SIZE_TITLE2,
	FONT_SIZE_TITLE2_MOBILE,
	FONT_WEIGHT_ALMOST_BOLD,
	FONT_WEIGHT_BOLD,
	FONT_WEIGHT_EXTRA_BOLD,
	FONT_WEIGHT_LIGHT,
	FONT_WEIGHT_NORMAL,
	FONT_WEIGHT_SEMI_BOLD
} from 'styles/baseFonts'
import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { LayoutOutlet as CompanyLayoutOutlet } from 'components/company-components/layout/Layout'
import { LayoutOutlet as PrivateLayoutOutlet } from 'components/private-components/layout/Layout'

import { RequestInterceptor } from 'RequestInterceptor'

const GlobalStyle = createGlobalStyle`
  	:root {
		--color-main-dark_blue: ${COLOR_MAIN_DARK_BLUE};
		--color-main-turquoise: ${COLOR_MAIN_TURQUOISE};
		--color-main-blue_green: ${COLOR_MAIN_BLUE_GREEN};
		--color-white: ${COLOR_WHITE};
		--color-black: ${COLOR_BLACK};
		--color-yellow: ${COLOR_YELLOW};
		--color-grey: ${COLOR_GREY};
		--color-grey_dark: ${COLOR_GREY_DARK};
		--color-error: ${COLOR_ERROR};
		--color-text: ${COLOR_TEXT};
		--color-box: ${COLOR_BOX};
		--color-background-login_page: ${COLOR_BACKGROUND_LOGIN_PAGE};
	
		--font-family-merriweather: ${FONT_FAMILY_MERRIWEATHER};
		--font-family-opensans: ${FONT_FAMILY_OPEN_SANS};
		
		--font-weight-semi_bold: ${FONT_WEIGHT_SEMI_BOLD};
		--font-weight-bold: ${FONT_WEIGHT_BOLD};
	  	--font-weight-light: ${FONT_WEIGHT_LIGHT};
		--font-weight-extra-bold: ${FONT_WEIGHT_EXTRA_BOLD};
	  	--font-weight-normal: ${FONT_WEIGHT_NORMAL};
	  	--font-weight-almost-bold: ${FONT_WEIGHT_ALMOST_BOLD};
		
	  	// Default to phone size, then increase when size of screen is larger 
		--font-size-title1: ${FONT_SIZE_TITLE1_MOBILE};
		--font-size-title2: ${FONT_SIZE_TITLE2_MOBILE};
		--font-size-button-text: ${FONT_SIZE_BUTTON_TEXT};
      	--font-size-button-text_private: ${FONT_SIZE_BUTTON_TEXT_PRIVATE_MOBILE};
      	--font-size-text: ${FONT_SIZE_TEXT_MOBILE};
	  	--font-size-field-text: ${FONT_SIZE_FIELD_TEXT};
	  	--font-size-box-ingress: ${FONT_SIZE_BOX_INGRESS};
		--font-size-small-text: ${FONT_SIZE_SMALL_TEXT};
	  
		@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
          	--font-size-title1: ${FONT_SIZE_TITLE1};
          	--font-size-title2: ${FONT_SIZE_TITLE2};
          	--font-size-button-text_private: ${FONT_SIZE_BUTTON_TEXT_PRIVATE_BROWSER};
            --font-size-text: ${FONT_SIZE_TEXT};
          	--font-size-small_mobile: ${FONT_SIZE_TEXT};
        }
  	}	 
  
		html,body {
			margin: 0;
			padding: 0;
	  	}
	
		html {   
			box-sizing: border-box; 
	  	} 
	  
	  	*, *:before, *:after {
			box-sizing: inherit; 
	  	}
`

const SilentAuth = ({ children }: { children: ReactNode }) => {
	useMsalAuthentication(InteractionType.Popup)
	return <>{children}</>
}

const AllRoutesWrapper = () => {
	const location = useLocation()
	const prevLocationRef = useRef(location)

	/**
	 * Close all toasts whenever the route changes, so that error messages won't stay around after their use is through.
	 *
	 * This could cause issues if a page both spawns a toast and navigates.
	 */
	useLayoutEffect(() => {
		/*const nonDismissList = [
			{
				from: '',
				to: ''
			},
			{
				from: '',
				to: ''
			}
		]
		const isNavigationThatShouldNotDismissToast = nonDismissList.includes(
			(item: INonDismissListItem) => {
				return item.from === prevLocationRef.current && item.to === location
			}
		)*/

		/**
		 * When navigating to a new page, reset scroll position to the top of the page
		 */
		document.documentElement.scrollTo({
			top: 0,
			left: 0
		})

		/**
		 * Dismiss all toasts marked for dismissal.
		 *
		 * We wrap all toasts that should be dismissable in ".dismissToast",
		 * which means we can programatically target and click them.
		 */
		const dismissToasts: HTMLCollectionOf<HTMLElement> =
			document.getElementsByClassName(
				'dismissToast'
			) as HTMLCollectionOf<HTMLElement>
		for (const dt of dismissToasts) {
			dt.click()
		}

		prevLocationRef.current = location
	}, [location])

	return <Outlet />
}

const companyRouter = createBrowserRouter(
	createRoutesFromElements([
		// PRIVATE OPEN FOR ALL PAGES
		<Route element={<AllRoutesWrapper />}>
			<Route element={<PrivateLayoutOutlet />}>
				<Route path="*" loader={() => redirect('/')} />,
				<Route path="/" element={<PublicPage />} />
				{/* The open way to this page - This will only display the search box as the company name is unknown */}
				<Route
					path={ELECTRONIC_PROCESS_START_PAGE_PATH}
					element={<ElectronicProcessStartPage />}
				/>
				<Route
					path={SUCCESSFULLY_UPLOADED_PATH}
					element={<SuccessFullyUploadedLCPage />}
				/>
				<Route
					path={FAILED_TO_UPLOAD_PATH}
					element={<FailedToUploadLCPage />}
				/>
			</Route>
			<Route element={<PrivateLayoutOutlet hasCompany />}>
				{/* This is placed in the route where company name is known and will display the relevant data */}
				<Route
					path={`${ELECTRONIC_PROCESS_START_PAGE_PATH}/:companyName/:uuid`}
					element={<ElectronicProcessStartPage />}
				/>
				<Route
					path={`${WALK_THROUGH_PAGE_PATH}/:companyName/:uuid/:pageId`}
					element={<WalkThroughPage />}
				/>
				<Route
					path={`${QRCODE_PAGE_PATH}/:companyName/:uuid`}
					element={<QRCodePage />}
				/>
			</Route>
			<Route path={LOGIN_PATH} element={<LoginPage />} />
			<Route
				path=""
				element={
					<SilentAuth>
						<AuthenticatedTemplate>
							<RequestInterceptor>
								<CompanyLayoutOutlet />
							</RequestInterceptor>
						</AuthenticatedTemplate>
					</SilentAuth>
				}
			>
				<Route path="bedrift/*" loader={() => redirect(LOGIN_PATH)} />,
				<Route
					path="bedrift"
					loader={() => {
						console.log('Redirecting to /bedrift/oppgavebehandling/')
						return redirect('/bedrift/oppgavebehandling/')
					}}
				/>
				<Route
					path={OPPGAVEBEHANDLING_PATH}
					element={<CompanyDashboardPage />}
				/>
				<Route
					path={`${YTELSESMOTTAKER_PATH}/:beneficiaryId`} //has to to it like this if we want to reuse the path
					element={<BeneficiaryPageCompany />}
					errorElement={<BeneficiaryPageError />}
				/>
				<Route
					path={`${YTELSESMOTTAKER_PATH}/admin/:beneficiaryId`}
					element={<BeneficiaryPageFNF />}
					errorElement={<BeneficiaryPageError />}
				/>
				<Route path={COMPANYLISTPAGE_PATH} element={<CompanyListPage />} />
			</Route>
		</Route>
	])
)

export const App = () => {
	/**
	 * Constantly get the language of the application.
	 * This will make sure screen readers knows which language it is.
	 */
	useEffect(() => {
		document.documentElement.lang = i18n.language
	}, [])
	return (
		<Suspense fallback="...laster">
			<ToastContainer
				position="top-center"
				autoClose={10000}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				theme="colored"
			/>
			<GlobalStyle />
			<RouterProvider router={companyRouter} />
		</Suspense>
	)
}
