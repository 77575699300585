import styled from 'styled-components'

import { FooterContent } from 'components/FooterContent'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.footer``

const PrivacyWrapper = styled.div`
	width: 100%;
	background-color: var(--color-main-blue_green);
	padding-left: 40px;
	padding-block: 10px;
	margin-top: 20px;
`

const A = styled.a`
	color: var(--color-white);
	font-size: var(--font-size-text);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-almost-bold);
	text-decoration: underline 0.5px;
`

/**
 * Footer component for private pages
 */
export const Footer = () => {
	const { t } = useTranslation('common')

	return (
		<Wrapper>
			<PrivacyWrapper>
				<A
					href="https://www.finansnorge.no/forsikringsdrift/digital-leveattest---personvernerklaring/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{t('footer.privacyStatement')}
				</A>
			</PrivacyWrapper>
			<FooterContent />
		</Wrapper>
	)
}
