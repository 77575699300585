import { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { postBulkAddBeneficiaries } from 'api/services/BeneficiaryService'

import exampleJson from 'files/exampleAddBeneficiaries.json'

import { BulkFileUploader } from 'components/FileUploader'
import { Spinner } from 'components/Spinner'

import { downloadCsvFile } from 'utils/fileUtils/downloadCsvFile'
import { getFileDetails } from 'utils/fileUtils/getFileDetails'
import { displayBulkUploadToast } from 'utils/fileUtils/displayBulkUploadToast'

import { useGetCompanyNameOfUser } from 'hooks/useGetCompanyNameOfUser'

const BodyWrapper = styled.div`
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

interface Props {
	onSuccess: () => void
}

/**
 * Component to upload a json file with bulk for registration of new beneficiaries
 *
 * @param props.onSuccess function to be executed when successfully uploaded
 */
export const AddBulkBeneficiaries = ({ onSuccess }: Props) => {
	const { companyName } = useGetCompanyNameOfUser()

	// Handle loading
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation('company')

	// Handle the files
	const [files, setFiles] = useState<File[]>([])

	const blob = new Blob([JSON.stringify(exampleJson, null, 4)], {
		type: 'application/json'
	})

	const reset = () => {
		setFiles([])
	}

	const onUpload = () => {
		setIsLoading(true)
		postBulkAddBeneficiaries(files[0])
			.then((res) => {
				downloadCsvFile(res.data, companyName)
				displayBulkUploadToast(
					getFileDetails(res.data),
					t('dash.register.modal.multiple.page.sent'),
					t('dash.register.modal.multiple.page.sentCSV')
				)
				onSuccess()
				reset()
				setIsLoading(false)
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
				toast.error(t('dash.register.modal.multiple.page.errors.generic'))
				setIsLoading(false)
			})
	}

	const displayContent = () => {
		if (isLoading) {
			return <Spinner />
		}
		return (
			<BulkFileUploader
				fileType="application/json"
				onUpload={onUpload}
				files={files}
				setFiles={setFiles}
				uploadButtonText={t('dash.register.modal.multiple.page.upload')}
				exampleFileAsBlob={blob}
				uploadFieldMinWidth="470px"
				uploadFieldColor="var(--color-box)"
				isRequest={false}
			/>
		)
	}

	return (
		<div>
			<BodyWrapper>{displayContent()}</BodyWrapper>
		</div>
	)
}
