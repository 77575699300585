import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
	auth: {
		clientId:
			process.env.REACT_APP_AZURE_CLIENT_ID ??
			'84f2f8c7-4536-426c-bb93-f34eb497caaf',
		authority:
			process.env.REACT_APP_AZURE_AUTHORITY ??
			'https://login.microsoftonline.com/b7e22da2-2252-420d-930a-5119d917f62a',
		redirectUri: process.env.REACT_APP_URI ?? 'http://localhost:3000/'
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message)
						return
					case LogLevel.Info:
						console.info(message)
						return
					case LogLevel.Verbose:
						console.debug(message)
						return
					case LogLevel.Warning:
						console.warn(message)
						return
					default:
						return
				}
			}
		}
	}
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: ['User.Read']
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me'
}
