import { useParams } from 'react-router-dom'

import { BrowserHeader } from './BrowserHeader'
import { PhoneHeader } from './PhoneHeader'

interface Props {
	hasCompany: boolean
}
/**
 * Header component for private pages
 *
 * @param props.hasCompany Flag if we know which company the user belongs to
 */
export const Header = ({ hasCompany = false }: Props) => {
	const { companyName } = useParams()

	return (
		<>
			<PhoneHeader />
			<BrowserHeader hasCompany={hasCompany} companyName={companyName} />
		</>
	)
}
