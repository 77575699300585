import React, { useCallback, useRef } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { Text as BaseText } from 'components/text/Text'
import { UploadSVG as BaseUploadSVG } from 'components/svg/UploadSVG'
import { Button as BaseButton } from 'components/company-components/button/Button'

import { SR_ONLY } from 'styles'

import { DropZone } from '../DropZone'
import { FileList } from '../FileList'
import { UploadField as BaseUploadField } from '../UploadField'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-inline: 20px;
`

const Text = styled(BaseText)`
	text-align: center;
`

const NBText = styled(BaseText)`
	text-align: center;
	color: var(--color-black);
	font-size: var(--font-size-small-text);
	margin-top: 7px;
`

const SmallText = styled(BaseText)`
	font-size: var(--font-size-small-text);
`

const DownloadExampleText = styled(BaseText)`
	font-size: var(--font-size-small-text);
	text-align: center;
	margin: 0;
	margin-top: 5px;
`

const FileWrapper = styled.div`
	width: 100%;
`

interface IUploadField {
	minWidth: string
	color?: string
}

const UploadField = styled(BaseUploadField)<IUploadField>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.color};
	min-width: ${(props) => props.minWidth};
	margin: 0;
	padding: 0;
	padding-bottom: 10px;
	padding-block: 15px;
`

const UploadSVG = styled(BaseUploadSVG)`
	width: 40px;
`

const AText = styled.a`
	color: var(--color-black);
	font-size: var(--font-size-small-text);
	text-decoration: underline;
`

const Button = styled(BaseButton)<IUploadField>`
	margin-top: 40px;
	min-width: ${(props) => props.minWidth};
`

const Input = styled.input`
	${SR_ONLY}
`

interface Props {
	onUpload: () => void
	fileType: string
	files: File[]
	setFiles: React.Dispatch<React.SetStateAction<File[]>>
	uploadButtonText: string
	exampleFileAsBlob: Blob
	uploadFieldMinWidth: string
	uploadFieldColor: string
	isRequest?: boolean
}

/**
 * Component to display the file uploader
 *
 * @param props.onUpload function to handle the success of uploading
 * @param props.fileType the filetype
 * @param props.files the file array
 * @param props.setFiles function to update files
 * @param props.uploadButtonText the text of the upload button
 * @param props.exampleFileAsBlob the example file
 * @param props.uploadFieldMinWidth the min width of the upload field
 * @param props.uploadFieldColor the color of the upload field
 * @param props.isRequest optional flag for if the usage is for requests
 */
export const BulkFileUploader = ({
	fileType,
	onUpload,
	files,
	setFiles,
	uploadButtonText,
	exampleFileAsBlob,
	uploadFieldMinWidth,
	uploadFieldColor,
	isRequest,
	...rest
}: Props) => {
	// Handle the hidden input field
	const hiddenFileInput = useRef<any>(null)
	const { t } = useTranslation('company')

	/**
	 * Function to handle the hidden file input with button and input
	 */
	const onClickChooseFile = (event: any) => {
		hiddenFileInput.current.click()
	}

	/**
	 * Function to either set the selected files, or spawn the error toast
	 *
	 * @param selectedFiles the new files selected
	 * @param currentFiles the already selected files
	 */
	const setSelectedFiles = (selectedFiles: File[], currentFiles: File[]) => {
		if (currentFiles.length === 0 && selectedFiles.length <= 1) {
			setFiles(selectedFiles)
		} else {
			toast.error(
				<div className="dismissToast">
					{t('dash.bulk.upload.errors.amount')}
				</div>
			)
		}
	}

	/**
	 * Function to handle when files are dropped if there are 1 file
	 */
	const onFilesDrop = useCallback(
		(newFiles: File[], currentFiles: File[]) => {
			setSelectedFiles(newFiles, currentFiles)
		},
		// eslint-disable-next-line
		[]
	)

	/**
	 * Function to set the files if there are 1 or less files
	 */
	const onFileChange = (event: any) => {
		const newFiles: File[] = Array.from(event.target.files)
		setSelectedFiles(newFiles, files)
	}

	/**
	 * Function to remove the file from the list
	 */
	const onClickDeleteFile = () => {
		setFiles([])
	}

	const onClickUploadBulk = () => {
		//TODO: ADD errors - this cleanly fails without error currently!
		if (isFileJson(files[0])) {
			onUpload()
		}
	}

	/**
	 * Function to validate that a file is of type pdf
	 * @param file file to validate
	 * @return boolean
	 */
	const isFileJson = (file: File) => {
		const validType = ['application/json']
		return validType.indexOf(file.type) !== -1
	}

	const displayContent = () => {
		if (files.length > 0) {
			return (
				<>
					<UploadField
						active={false}
						minWidth={uploadFieldMinWidth}
						color={uploadFieldColor}
					>
						<FileWrapper>
							<FileList
								files={files}
								onClickDeleteFile={onClickDeleteFile}
							/>
						</FileWrapper>
					</UploadField>
					<Button
						text={uploadButtonText}
						onClick={onClickUploadBulk}
						minWidth={uploadFieldMinWidth}
					/>
				</>
			)
		}
		return (
			<>
				<UploadField
					active
					minWidth={uploadFieldMinWidth}
					color={uploadFieldColor}
				>
					<DropZone
						onClick={onClickChooseFile}
						onFilesDrop={onFilesDrop}
						errorMessage={t('dash.bulk.dropzone.errors.generic')}
						currentFiles={files}
						fileType={fileType}
					>
						<Input
							accept={fileType}
							multiple
							type="file"
							onChange={onFileChange}
							ref={hiddenFileInput}
							aria-labelledby="dropZone"
						/>
						<UploadSVG />
						<Text>{t('dash.bulk.dropzone.title')}</Text>
						<SmallText>{t('dash.bulk.dropzone.subtitle')}</SmallText>
					</DropZone>
				</UploadField>
				{isRequest && <NBText>{t('dash.bulk.dropzone.text')}</NBText>}
				<DownloadExampleText>
					<Trans t={t} i18nKey="dash.bulk.dropzone.example">
						Last ned{' '}
						<AText
							href={window.URL.createObjectURL(exampleFileAsBlob)}
							target="_blank"
							rel="noopener noreferrer"
						>
							eksempel.json
						</AText>{' '}
						for å se et eksempel!
					</Trans>
				</DownloadExampleText>
			</>
		)
	}

	return <Wrapper {...rest}>{displayContent()}</Wrapper>
}
