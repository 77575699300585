import styled, { css } from 'styled-components'

/**
 * Interface to handle the style of the line
 */
interface IStyledLine {
	isPast: boolean
}

const Wrapper = styled.hr<IStyledLine>`
	border: 0;
	border-top: 2px dashed #989aa5;
	flex-grow: 100;

	${(props) =>
		props.isPast &&
		css`
			border-top: 2px solid var(--color-main-dark_blue);
		`}
`

interface Props {
	isPast: boolean
}

/**
 * Component to display the line between the tabs
 *
 * @param props.isPast If the tab to the left is past
 */
export const Line = ({ isPast }: Props) => {
	return <Wrapper isPast={isPast}></Wrapper>
}
