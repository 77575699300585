import styled from 'styled-components'

/**
 * Title component that contains the styling for a title 1 element.
 *
 * Color: #000000
 * Size: 28px
 * Weight: 400
 * Family: Merriweather
 */
export const HeaderText1 = styled.h1<{ mb?: string }>`
	color: var(--color-black);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-title1);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-normal);
	margin: 0;
	${({ mb }) => mb && `margin-bottom: ${mb}`}
`
