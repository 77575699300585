import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'
import { SR_ONLY } from 'styles'

import { Text as BaseText } from 'components/text/Text'
import { UploadSVG as BaseUploadSVG } from 'components/svg/UploadSVG'

import { DropZone } from '../DropZone'
import { FileList } from '../FileList'
import { AddButton } from '../AddButton'
import { UploadField } from '../UploadField'
import { UploadButtonAndModalPublic } from './UploadButtonAndModalPublic'
import { UploadButtonAndModalFNF } from './UploadButtonAndModalFNF'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border-radius: 10px;
	min-height: 120px;
	width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 800px;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const Input = styled.input`
	${SR_ONLY}
`

const Text = styled(BaseText)`
	text-align: center;
`

const SmallText = styled(BaseText)`
	font-size: var(--font-size-small-text);
`

const FilesWrapper = styled.div`
	width: 100%;
`

const UploadSVG = styled(BaseUploadSVG)`
	width: 40px;
	margin-bottom: 10px;
`

interface Props {
	uuid: string
	nin: string
	onSuccess: () => void
	// TODO - fix below. This is a temporary fix to handle the different API calls when the upload is pressed
	usageType: 'public' | 'company'
	fileType: string
}

/**
 * Component to display the file uploader
 *
 * @param props.uuid the uuid of the person that uploads
 * @param props.nin the nin of the person that uploads
 * @param props.onSuccess function to handle the success of uploading
 * @param props.type if it is public or company - TODO - fix this
 */
export const LCFileUploader = ({
	uuid,
	nin,
	onSuccess,
	usageType,
	fileType,
	...rest
}: Props) => {
	// Handle the hidden input field
	const hiddenFileInput = useRef<any>(null)
	const { t } = useTranslation('public')

	// Handle the files
	const [files, setFiles] = useState<File[]>([])

	/**
	 * Function to handle the hidden file input with button and input
	 */
	const onClickChooseFile = (event: any) => {
		hiddenFileInput.current.click()
	}

	/**
	 * Function to either set the selected files, or spawn the error toast
	 *
	 * @param selectedFiles the new files selected
	 * @param currentFiles the already selected files
	 */
	const setSelectedFiles = (selectedFiles: File[], currentFiles: File[]) => {
		if (selectedFiles.length <= 2 && currentFiles.length <= 2) {
			if (currentFiles.length === 1 && selectedFiles.length <= 1) {
				setFiles([...currentFiles, ...selectedFiles])
			} else if (currentFiles.length === 1 && selectedFiles.length > 1) {
				toast.error(
					<div className="dismissToast">
						{t('upload.errors.amount') as string}
					</div>
				)
			} else {
				setFiles(selectedFiles)
			}
		} else {
			toast.error(
				<div className="dismissToast">
					{t('upload.errors.amount') as string}
				</div>
			)
		}
	}

	/**
	 * Function to handle when files are dropped if there are 2 or less files
	 */
	const onFilesDrop = useCallback((newFiles: File[], currentFiles: File[]) => {
		setSelectedFiles(newFiles, currentFiles)
		// eslint-disable-next-line
	}, [])

	/**
	 * Function to set the files if there are 2 or less files
	 */
	const onFileChange = (event: any) => {
		const newFiles: File[] = Array.from(event.target.files)
		setSelectedFiles(newFiles, files)
	}

	/**
	 * Function to dusplay the add button. This will be displayed inside a DropZone
	 */
	const displayAddButton = () => {
		if (files.length === 1) {
			return (
				<DropZone
					onClick={onClickChooseFile}
					onFilesDrop={onFilesDrop}
					errorMessage={t('upload.errors.format')}
					currentFiles={files}
					fileType={fileType}
				>
					<input
						accept={fileType}
						multiple
						type="file"
						onChange={onFileChange}
						ref={hiddenFileInput}
						style={{ display: 'none' }}
						aria-labelledby="dropZone"
					/>
					<AddButton />
				</DropZone>
			)
		}
		return null
	}

	/**
	 * Function to remove a file from the list
	 *
	 * @param index the position to remove
	 */
	const onClickDeleteFile = (index: number) => {
		const newFileList: File[] = files.filter(
			(file: File, i: number) => index !== i
		)
		setFiles(newFileList)
	}

	const onReset = () => {
		setFiles([])
	}

	const displayUploadButtonAndModal = () => {
		if (usageType === 'public') {
			return (
				<UploadButtonAndModalPublic
					files={files}
					onReset={onReset}
					onSuccess={onSuccess}
					uuid={uuid}
					nin={nin}
				/>
			)
		} else {
			return (
				<UploadButtonAndModalFNF
					files={files}
					onReset={onReset}
					onSuccess={onSuccess}
					uuid={uuid}
				/>
			)
		}
	}

	/**
	 * Function to decide the content displayed in the box
	 * TODO - refactor this to smaller components
	 */
	const displayContent = () => {
		if (files.length > 0) {
			return (
				<>
					<UploadField active={false}>
						<FilesWrapper>
							<FileList
								files={files}
								onClickDeleteFile={onClickDeleteFile}
							/>
							{displayAddButton()}
						</FilesWrapper>
					</UploadField>
					{displayUploadButtonAndModal()}
				</>
			)
		}
		return (
			<>
				<UploadField active>
					<DropZone
						onClick={onClickChooseFile}
						onFilesDrop={onFilesDrop}
						errorMessage={t('upload.errors.format_multiple')}
						currentFiles={files}
						fileType={fileType}
					>
						<Input
							accept={fileType}
							multiple
							type="file"
							onChange={onFileChange}
							ref={hiddenFileInput}
							aria-labelledby="dropZone"
						/>
						<UploadSVG />
						<Text>{t('upload.title')}</Text>
						<SmallText>{t('upload.subtitle')}</SmallText>
					</DropZone>
				</UploadField>
			</>
		)
	}

	return <Wrapper {...rest}>{displayContent()}</Wrapper>
}
