import { ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Text as BaseText } from 'components/text/Text'

import { hasOwnProperty } from 'utils/hasOwnProperty'
import { displayFormatDate } from 'utils/displayFormatDate'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { ListTextItem, ListTextItemProps } from '../ListTextItem'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// TODO - the height on this is a bit weird, has to be fixed
	height: 90%;
`

const TopWrapper = styled.div``

const BottomWrapper = styled.div``

const FlexRow = styled.div`
	display: flex;
`

const RowWrapper = styled.div`
	width: 40%;
`

const Text = styled(BaseText)`
	font-size: var(--font-size-small-text);
	font-weight: var(--font-weight-light);
`

interface Props {
	status: 'Gyldig' | 'Ugyldig'
	children?: ReactNode
	listItems?: (ListTextItemProps | { items: ListTextItemProps[] })[]
	beneficiary: BeneficiaryType
	additionalTopRowItem?: ListTextItemProps
}

/**
 * Component to display the details of a life certificate
 *
 * @param props.status The status of the life certificate
 * @param props.listItems The items to display on the page
 * @param props.beneficiary the beneficiary
 * @param props.children React children
 */
export const LifeCertificateDetails = ({
	status,
	listItems,
	beneficiary,
	additionalTopRowItem,
	children
}: Props) => {
	const { t } = useTranslation('company')
	return (
		<Wrapper>
			<TopWrapper>
				<FlexRow>
					<RowWrapper>
						<ListTextItem
							title={t('beneficiary.cert.status')}
							text={status}
							color="white"
						/>
					</RowWrapper>
					{additionalTopRowItem && (
						<ListTextItem
							title={additionalTopRowItem.title}
							text={additionalTopRowItem.text}
							color="white"
						/>
					)}
				</FlexRow>
				{listItems?.map((item, index: number) => {
					if (
						typeof item === 'object' &&
						!Array.isArray(item) &&
						hasOwnProperty(item, 'items')
					) {
						return (
							<FlexRow key={index}>
								{(item.items as ListTextItemProps[]).map(
									(i, key: number) => (
										<RowWrapper key={key}>
											<ListTextItem color="white" {...i} />
										</RowWrapper>
									)
								)}
							</FlexRow>
						)
					}
					if (typeof item === 'object' && hasOwnProperty(item, 'title')) {
						const itemCasted: ListTextItemProps =
							item as ListTextItemProps
						return (
							<ListTextItem color="white" {...itemCasted} key={index} />
						)
					}
					return null
				})}
				{children}
			</TopWrapper>
			<BottomWrapper>
				{beneficiary.otherCompanyRequest !== null && (
					<Text color="white">
						{t('beneficiary.cert.sent.about', {
							date: displayFormatDate(
								new Date(beneficiary.otherCompanyRequest)
							)
						})}
					</Text>
				)}
			</BottomWrapper>
		</Wrapper>
	)
}
