import React from 'react'
import styled from 'styled-components'

import {
	FaFileSignature,
	FaHandPointDown,
	FaFileUpload,
	FaFileDownload,
	FaChalkboardTeacher
} from 'react-icons/fa'
import {
	MdOutlinePhoneIphone,
	MdOutlineMailOutline,
	MdDeleteForever,
	MdModeEdit,
	MdFilterAlt
} from 'react-icons/md'
import {
	BsMailbox,
	BsPersonCircle,
	BsPersonFill,
	BsPhoneFill,
	BsFileEarmarkText,
	BsCheck,
	BsArrowRightShort,
	BsFillQuestionCircleFill,
	BsFilter
} from 'react-icons/bs'
import {
	RiSendPlaneFill,
	RiPassportFill,
	RiCustomerService2Fill,
	RiArrowDownSFill,
	RiArrowUpSFill
} from 'react-icons/ri'
import {
	HiOutlineDownload,
	HiOutlineSearch,
	HiCheckCircle,
	HiOutlinePlusSm,
	HiUserGroup,
	HiBadgeCheck
} from 'react-icons/hi'
import { IoExitOutline } from 'react-icons/io5'
import {
	IoIosOpen,
	IoIosArrowForward,
	IoIosArrowBack,
	IoIosWarning
} from 'react-icons/io'
import { TiArrowBackOutline } from 'react-icons/ti'
import {
	AiOutlineCloseCircle,
	AiFillCaretDown,
	AiFillCaretRight,
	AiFillClockCircle
} from 'react-icons/ai'
import { TbArrowBigRight } from 'react-icons/tb'
import { VscFilePdf } from 'react-icons/vsc'
import { BiErrorCircle } from 'react-icons/bi'
import { HiDocumentCheck } from 'react-icons/hi2'

import bankIdLogo from 'images/BankID.svg'
import fnfLogo from 'images/mobileLogo.svg'

const Image = styled.img`
	height: 40px;
	width: 40px;
`

const StyledArrowRight = styled(BsArrowRightShort)`
	font-size: 30px;
`

const BankId = () => {
	return <Image src={bankIdLogo} alt="Bank id logo" />
}

const FNFLogo = () => {
	return <Image src={fnfLogo} alt="Finans Norge logo" />
}

const ICONS = {
	ArrowBack: TiArrowBackOutline,
	ArrowRight: StyledArrowRight,
	ArrowRightBig: TbArrowBigRight,
	BankIdLogo: BankId,
	CaretDown: AiFillCaretDown,
	CaretRight: AiFillCaretRight,
	ChalkboardTeacher: FaChalkboardTeacher,
	CheckCircle: HiCheckCircle,
	Check: BsCheck,
	Close: AiOutlineCloseCircle,
	CustomerService: RiCustomerService2Fill,
	Download: HiOutlineDownload,
	Edit: MdModeEdit,
	ErrorCircle: BiErrorCircle,
	Exit: IoExitOutline,
	FileDocument: BsFileEarmarkText,
	FileDownload: FaFileDownload,
	FileSignature: FaFileSignature,
	FileUpload: FaFileUpload,
	Filter: MdFilterAlt,
	FNFLogo: FNFLogo,
	HandPointDown: FaHandPointDown,
	InvalidLC: IoIosWarning,
	LCStatus: BsFilter,
	Mail: MdOutlineMailOutline,
	MailBox: BsMailbox,
	NavigationBack: IoIosArrowBack,
	NavigationNext: IoIosArrowForward,
	OpenFile: IoIosOpen,
	Passport: RiPassportFill,
	Person: BsPersonFill,
	PersonCircle: BsPersonCircle,
	PersonsGroup: HiUserGroup,
	PDFFile: VscFilePdf,
	PhoneCompany: MdOutlinePhoneIphone,
	PhonePrivate: BsPhoneFill,
	Plus: HiOutlinePlusSm,
	QuestionMarkCircle: BsFillQuestionCircleFill,
	RequestSent: AiFillClockCircle,
	Search: HiOutlineSearch,
	SendPlane: RiSendPlaneFill,
	SortArrowDown: RiArrowDownSFill,
	SortArrowUp: RiArrowUpSFill,
	Trash: MdDeleteForever,
	ValidLC: HiBadgeCheck,
	VerifyDocument: HiDocumentCheck
}

export interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
	name: keyof typeof ICONS
}

/**
 * Component that returns an icon based on the given name
 *
 * TODO: Fix error related to the imports from react-icons having changed aria-hidden to ariaHidden. Since we render the component with these added, things throw errors. Not sure how hard this would be to fix.
 *
 * @param name name of the icon to use
 */
export const Icon = ({ name, ...rest }: IconProps) => {
	const Element = ICONS[name]
	return <Element {...rest} />
}
