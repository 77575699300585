import React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/icon/Icon'

import { SR_ONLY } from 'styles'

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-white);
	font-size: 30px;
`

interface IStyledCheckbox {
	checked: boolean
}

const StyledCheckbox = styled.div<IStyledCheckbox>`
	background-color: ${(props) =>
		props.checked ? 'var(--color-main-dark_blue)' : 'var(--color-white)'};

	min-height: 35px;
	max-height: 35px;
	min-width: 35px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: solid 0.09em var(--color-black);
	cursor: pointer;

	${IconWrapper} {
		visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
	}

	&:hover {
		background-color: var(--color-main-turquoise);
	}
`

const Input = styled.input`
	${SR_ONLY}

	&:focus + ${StyledCheckbox} {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) + ${StyledCheckbox} {
		outline: none;
	}
`

interface Props {
	id?: string
	name: string
	checked: boolean
	onClick: () => void
}

/**
 * Component to display a checkbox
 * It uses a label element as wrapper which is responsible for triggering the onChange callback
 * of the checkbox as the actual checkbox is hidden.
 *
 * @param props.name name to identify the submission in forms
 * @param props.id Optional. If not provided, the input takes the id from the name prop
 * @param props.isChecked Value for if the box is checked or not
 * @param props.onChange Function to set the value of checked
 */
export const Checkbox = ({ id, name, checked, onClick, ...rest }: Props) => {
	return (
		<div onClick={onClick}>
			<Input
				id={id ?? name}
				name={name}
				type="checkbox"
				checked={checked}
				{...rest}
			/>
			<StyledCheckbox checked={checked}>
				<IconWrapper>
					<Icon name="Check" aria-label="" />
				</IconWrapper>
			</StyledCheckbox>
		</div>
	)
}
