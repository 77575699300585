import React from 'react'
import styled from 'styled-components'

import { Text as BaseText } from 'components/text/Text'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

const StyledErrorWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-block: 1vh;
	width: 100%;
`

const ErrorText = styled(BaseText)`
	color: var(--color-error);
	text-align: center;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		text-align: left;
	}
`

interface Props {
	errorText: string
}

/**
 * Component to display error for uploading
 *
 * @param errorText text to display
 */
export const ErrorWrapper = ({ errorText }: Props) => {
	return (
		<StyledErrorWrapper>
			{errorText !== '' && <ErrorText>{errorText}</ErrorText>}
		</StyledErrorWrapper>
	)
}
