import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { LCFileUploader as BaseFileUploader } from 'components/FileUploader/LCFileUploader'
import { HeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Text as BaseText } from 'components/text/Text'
import { ScreenReaderText } from 'components/text/ScreenReaderText'

import successImage from 'images/BankIDSuccess.svg'

import { NINSearchBox } from './NINSearchBox'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-top: 10px;
	}
`

const SuccessWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--color-box);
	padding: 20px 20px;
	border-radius: 10px;
	min-height: 240px;
	width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 800px;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const SearchAndUploadWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	width: 100%;
`

const HeaderText2 = styled(BaseHeaderText2).attrs({ as: 'p' })`
	margin-top: 40px;
	text-align: center;
	font-weight: var(--font-weight-light);
`

const FileUploader = styled(BaseFileUploader)`
	margin-top: 10px;
	background-color: var(--color-box);
	padding: 40px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-top: 30px;
	}
`

const Text = styled(BaseText)`
	text-align: center;
	font-weight: var(--font-weight-semi-bold);
	white-space: pre-line;
	margin-top: 30px;
`

/**
 * Component to display the main public leveattest page
 */
// TODO - fix design for mobile
export const PublicPage = () => {
	const [uuid, setUuid] = useState<string>()
	const [nin, setNin] = useState<string>()
	const { t } = useTranslation('public')

	// To handle if it was successfully sent
	const [successfullySent, setSuccessFullySent] = useState(false)

	/**
	 * Display the upload field if uuid exists
	 */
	const displayUpload = () => {
		if (uuid && nin && nin.length === 11) {
			return (
				<>
					<ScreenReaderText as="h2">
						{t('upload.mainTitle')}
					</ScreenReaderText>
					<FileUploader
						uuid={uuid}
						nin={nin}
						onSuccess={() => setSuccessFullySent(true)}
						usageType="public"
						fileType="application/pdf"
					/>
				</>
			)
		}
		return null
	}

	const displayContent = () => {
		if (successfullySent) {
			return (
				<>
					<HeaderText1 mb="20px">{t('titleSuccess')}</HeaderText1>
					<SuccessWrapper>
						<img src={successImage} alt="" />
						<Text>{t('success')}</Text>
					</SuccessWrapper>
				</>
			)
		}
		return (
			<>
				<HeaderText1>{t('title')}</HeaderText1>
				<HeaderText2>{t('instruction')}</HeaderText2>
				<SearchAndUploadWrapper>
					<NINSearchBox
						onSuccess={(data: unknown, nin: string) => {
							const { uuid } = (data as { uuid: string }) ?? {}
							setUuid(uuid)
							setNin(nin)
						}}
						onError={() => setUuid(undefined)}
					/>
					{displayUpload()}
				</SearchAndUploadWrapper>
			</>
		)
	}

	return <Wrapper>{displayContent()}</Wrapper>
}
