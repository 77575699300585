import React from 'react'
import styled, { css } from 'styled-components'
import {
	Wrapper as BaseWrapper,
	Button as BaseButton,
	Menu as BaseMenu,
	MenuItem as BaseMenuItem
} from 'react-aria-menubutton'
import { Trans, useTranslation } from 'react-i18next'

import { SelectType } from 'types/SelectType'

import { Icon as BaseIcon } from 'components/icon/Icon'
import { Text } from 'components/text/Text'
import { Strong } from 'components/text/Strong'

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`

const ButtonAndMenuWrapper = styled(BaseWrapper)`
	position: relative;
	margin-left: 20px;
`

const Button = styled(BaseButton)`
	font-family: var(--font-family-merriweather);
	min-width: max-content;
	height: 42px;
	padding: 20px;
	border-radius: 5px;
	background-color: var(--color-main-blue_green);
	position: relative;
	border: none;
	cursor: pointer;
	box-shadow: 0.1px 0.5px 4px rgb(0, 0, 0, 25%);
	color: var(--color-white);
	display: flex;
	flex-direction: row;
	align-items: center;

	&:hover {
		background-color: var(--color-main-dark_blue);
	}

	&:focus {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const Icon = styled(BaseIcon)`
	font-size: 25px;
	color: var(--color-white);
	margin-right: 5px;
`

const Menu = styled(BaseMenu)`
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--color-main-blue_green);
	background-color: var(--color-white);
	color: var(--color-black);
	z-index: 200;
`

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`

const MenuItem = styled(BaseMenuItem)`
	padding: 8px;
	cursor: pointer;
	font-family: var(--font-family-merriweather);

	${(props) =>
		props.selected &&
		css`
			background-color: var(--color-main-dark_blue);
			color: var(--color-white);
		`}

	&:hover {
		color: var(--color-black);
		background-color: var(--color-main-turquoise);
	}

	&:focus {
		color: var(--color-black);
		outline: 2px solid red;
		background-color: var(--color-main-turquoise);
	}

	&:focus:not(:focus-visible) {
		outline: none;
		background-color: var(--color-white);
	}

	&:focus-visible {
		outline: 2px solid var(--color-main-dark_blue);
		background-color: var(--color-main-turquoise);
	}
`

interface Props {
	onSelectFilter: (
		filter: 'all' | 'valid' | 'invalid' | 'processStarted'
	) => void
	currentFilter: 'all' | 'valid' | 'invalid' | 'processStarted'
}

/**
 * Filter component that filters a list of all beneficiaries that belongs to a company.
 * It can filter on
 * - ALL beneficiaries
 * - beneficiaries with VALID life certificate
 * - beneficiaries with INVALID life certificate
 * - beneficiaries where the life certificate process HAS STARTED
 *
 * @param props.onSelectFilter function to set the filter
 * @param props.currentFilter the currently selected filter
 */
export const Filter = ({ onSelectFilter, currentFilter }: Props) => {
	const { t } = useTranslation('company')

	const options: SelectType[] = [
		{ value: 'all', label: t('companyList.filter.status.all') },
		{
			value: 'valid',
			label: t('companyList.filter.status.valid')
		},
		{ value: 'invalid', label: t('companyList.filter.status.invalid') },
		{ value: 'processStarted', label: t('companyList.filter.status.started') }
	]

	const handleSelection = (value: SelectType['value']) => {
		onSelectFilter(value as 'all' | 'valid' | 'invalid' | 'processStarted')
	}
	return (
		<Wrapper>
			<Text>
				<Trans i18nKey="company:companyList.filter.showing">
					Viser:{' '}
					<Strong>
						{
							{
								status: options?.find(
									(item) => item.value === currentFilter
								)?.label
							} as any
						}
					</Strong>
				</Trans>
			</Text>
			<ButtonAndMenuWrapper onSelection={handleSelection}>
				<Button>
					<>
						<Icon name="Filter" />
						{t('companyList.filter.button')}
					</>
				</Button>
				<Menu>
					<List>
						{options.map((item: SelectType, i) => {
							return (
								<li key={i}>
									<MenuItem
										value={item.value}
										selected={item.value === currentFilter}
									>
										{item.label}
									</MenuItem>
								</li>
							)
						})}
					</List>
				</Menu>
			</ButtonAndMenuWrapper>
		</Wrapper>
	)
}
