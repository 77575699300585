import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BrowserView, MobileView } from 'react-device-detect'

import { QRCODE_PAGE_PATH } from 'navigation/navigationURLs'

import { openBankIDApp } from 'utils/openBankIDApp'

import { Button as BaseButton } from 'components/private-components/button/Button'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Text as BaseText } from 'components/text/Text'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { InformationBox as BaseInformationBox } from '../InformationBox'
import { AlternativeSubmissionButton } from './AlternativeSubmissionButton'
import { useTranslation } from 'react-i18next'

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ContentWrapper = styled.div`
	margin-top: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: flex;
		flex-direction: column;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const InformationBox = styled(BaseInformationBox)`
	margin-bottom: 15px;
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		align-items: center;
		margin-bottom: 10px;
	}
`

const HeaderText2 = styled(BaseHeaderText2).attrs({ as: 'p' })`
	margin-top: 20px;
	white-space: pre-line;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		text-align: center;
		line-height: 27px;
	}
`

const SubHeaderText = styled(BaseSubHeaderText)`
	text-align: center;
	margin-block: 30px;
	margin-bottom: 10px;
`

const BankIDButtonWrapperDesktop = styled.div`
	margin-block: 30px;
	display: flex;
	justify-content: center;
`

const BankIDButtonWrapperMobile = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const BrowserButton = styled(BaseButton)`
	width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		padding-left: 100px;
		padding-right: 100px;
		width: auto;
	}
`

const MobileButton = styled(BaseButton)``

const BankIDErrorText = styled(BaseText)`
	margin-top: 10px;
	color: var(--color-error);
`

interface Props {
	setShowIsLoadingBankIdPage: React.Dispatch<React.SetStateAction<boolean>>
	setBankIdError: React.Dispatch<React.SetStateAction<boolean>>
	bankIdError: boolean
	onClickSwitchTab: (tabIndex: number, fromTab?: number) => void
}

/**
 * Details about step 4 in the walk-through process
 *
 * @param props.setShowIsLoadingBankIdPage Function to handle visibility of loading bank id details page
 * @param props.setBankIdError Function to set the error for BankId
 * @param props.bankIdError The error for BankId
 * @param props.onClickSwitchTab 	function that navigates to another tab index page. Optional parameter fromTab is to let the
 * 									browser know which page we came from so it moves back to the correct page
 */
export const Tab4 = ({
	setShowIsLoadingBankIdPage,
	setBankIdError,
	bankIdError,
	onClickSwitchTab
}: Props) => {
	const navigate = useNavigate()

	const { companyName, uuid } = useParams()
	const { t } = useTranslation('private')

	return (
		<>
			<TopWrapper>
				<HeaderText1>{t('tabs.4.title')}</HeaderText1>
				<HeaderText2>{t('tabs.4.text')}</HeaderText2>
				<SubHeaderText as="h2">
					{t('tabs.4.instruction.title')}
				</SubHeaderText>
				<ContentWrapper>
					<InformationBox
						text={t('tabs.4.instruction.step1')}
						icon={{ name: 'Passport' }}
					/>
					<InformationBox
						text={t('tabs.4.instruction.step2')}
						icon={{ name: 'BankIdLogo' }}
					/>
					<InformationBox
						text={t('tabs.4.instruction.step3')}
						icon={{ name: 'HandPointDown' }}
					/>
				</ContentWrapper>
				<BrowserView>
					<BankIDButtonWrapperDesktop>
						<BrowserButton
							text={t('tabs.4.start')}
							onClick={() => {
								navigate(`${QRCODE_PAGE_PATH}/${companyName}/${uuid}`)
							}}
						/>
					</BankIDButtonWrapperDesktop>
				</BrowserView>
				<MobileView>
					<BankIDButtonWrapperMobile>
						<MobileButton
							text={t('tabs.4.start')}
							onClick={() =>
								openBankIDApp(
									setShowIsLoadingBankIdPage,
									setBankIdError,
									uuid ?? ''
								)
							}
							fillWidth
						/>
						{bankIdError && (
							<BankIDErrorText>{t('tabs.4.error')}</BankIDErrorText>
						)}
					</BankIDButtonWrapperMobile>
				</MobileView>
				<AlternativeSubmissionButton
					onClick={() => onClickSwitchTab(5, 4)}
					text={t('tabs.alternative')}
				/>
			</TopWrapper>
		</>
	)
}
