import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Icon as BaseIcon } from 'components/icon/Icon'
import { TextButton as BaseTextButton } from 'components/common/TextButton'
import { TableHeader } from 'components/company-components/tableHeader/TableHeader'
import { SubHeaderText } from 'components/text/SubHeaderText'

import { InvalidListElementType } from 'types/InvalidListElementType'

import { TableRow } from './TableRow'
import { TableDataRow } from './TableDataRow'

const StyledTable = styled.table`
	padding-inline: 20px;
	border-collapse: collapse;
	width: 100%;
`

const TableHeaderNin = styled(TableHeader)`
	padding-left: 10px;
`

const TableHeaderVerify = styled(TableHeader)`
	text-align: center;
`

const Icon = styled(BaseIcon)`
	margin-left: 5px;
	font-size: 20px;
`

const TextButton = styled(BaseTextButton)`
	margin: 0;
	padding: 0;
	display: flex;
	text-decoration: none;
`

interface Props {
	list: InvalidListElementType[]
	onClickSortHeader: (dir: 'asc' | 'desc') => void
	isSortAscending: boolean
	onTableChange: () => void
}

/**
 * Component to display the Table containing the invalid life certificates
 *
 * @param props.list the list to display
 * @param props.onClickSortHeader function to handle the on click of the sort header
 * @param props.isSortAscending flag to handle which way the sort arrow should point
 * @param props.onTableChange function to update the table when its data is changed
 */
export const Table = ({
	list,
	onClickSortHeader,
	isSortAscending,
	onTableChange,
	...rest
}: Props) => {
	const { t } = useTranslation('company')

	const displayRows = list.map(
		(element: InvalidListElementType, key: number) => {
			return (
				<TableDataRow
					key={key}
					element={element}
					onRegisterSuccess={onTableChange}
				/>
			)
		}
	)

	return (
		<StyledTable {...rest}>
			<tbody>
				<TableRow>
					<TableHeaderNin width="25%" text={t('dash.list.table.birth')} />
					<TableHeader width="40%" text={t('dash.list.table.name')} />
					<TableHeader width="20%">
						<TextButton
							onClick={() => {
								onClickSortHeader(isSortAscending ? 'desc' : 'asc')
							}}
						>
							<SubHeaderText>{t('dash.list.table.date')}</SubHeaderText>
							<Icon
								name={isSortAscending ? 'SortArrowDown' : 'SortArrowUp'}
							/>
						</TextButton>
					</TableHeader>
					<TableHeaderVerify
						width="15%"
						text={t('dash.list.table.verify.title')}
					/>
				</TableRow>
				{displayRows}
			</tbody>
		</StyledTable>
	)
}
