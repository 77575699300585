import { COLOR_MAIN_DARK_BLUE, COLOR_MAIN_TURQUOISE } from 'styles/baseColors'

interface Props {
	fileColor?: string
	plusColor?: string
	width?: string
	height?: string
}

/**
 * Component to display the upload svg
 *
 * @param fileColor color of the file
 * @param plusColor color of the plus sign
 * @param width	width of the image
 * @param height height of the image
 */
export const UploadSVG = ({
	fileColor = COLOR_MAIN_DARK_BLUE,
	plusColor = COLOR_MAIN_TURQUOISE,
	width = '56',
	height = '72',
	...rest
}: Props) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 72" {...rest}>
			<g>
				<path
					className="file-color"
					d="M29.1667 17.7969V0H3.125C1.39323 0 0 1.4002 0 3.14062V63.8594C0 65.5998 1.39323 67 3.125 67H46.875C48.6068 67 50 65.5998 50 63.8594V20.9375H32.2917C30.5729 20.9375 29.1667 19.5242 29.1667 17.7969ZM37.6536 46.0638H29.1667V56.5326C29.1667 57.6894 28.2344 58.6263 27.0833 58.6263H22.9167C21.7656 58.6263 20.8333 57.6894 20.8333 56.5326V46.0638H12.3464C10.487 46.0638 9.55859 43.8013 10.8789 42.4835L23.4336 29.9603C24.2995 29.0953 25.6979 29.0953 26.5638 29.9603L39.1185 42.4835C40.4401 43.8013 39.513 46.0638 37.6536 46.0638ZM49.0885 13.7402L36.3411 0.916016C35.7552 0.327148 34.9609 0 34.1276 0H33.3333V16.75H50V15.9518C50 15.1273 49.6745 14.3291 49.0885 13.7402Z"
					fill={fileColor}
				/>
				<path
					className="plus-color"
					d="M44 49C37.925 49 33 53.925 33 60C33 66.075 37.925 71 44 71C50.075 71 55 66.075 55 60C55 53.925 50.075 49 44 49ZM45 64C45 64.2652 44.8946 64.5196 44.7071 64.7071C44.5196 64.8946 44.2652 65 44 65C43.7348 65 43.4804 64.8946 43.2929 64.7071C43.1054 64.5196 43 64.2652 43 64V61H40C39.7348 61 39.4804 60.8946 39.2929 60.7071C39.1054 60.5196 39 60.2652 39 60C39 59.7348 39.1054 59.4804 39.2929 59.2929C39.4804 59.1054 39.7348 59 40 59H43V56C43 55.7348 43.1054 55.4804 43.2929 55.2929C43.4804 55.1054 43.7348 55 44 55C44.2652 55 44.5196 55.1054 44.7071 55.2929C44.8946 55.4804 45 55.7348 45 56V59H48C48.2652 59 48.5196 59.1054 48.7071 59.2929C48.8946 59.4804 49 59.7348 49 60C49 60.2652 48.8946 60.5196 48.7071 60.7071C48.5196 60.8946 48.2652 61 48 61H45V64Z"
					fill={plusColor}
				/>
			</g>
		</svg>
	)
}
