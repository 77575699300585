import { useTranslation } from 'react-i18next'

import { ListTextItem } from 'pages/company-pages/BeneficiaryPage/ListTextItem'

/**
 * To handle the failure case if some data is wrong
 */
export const FailureCase = () => {
	const { t } = useTranslation('company')

	return (
		<ListTextItem
			title={t('beneficiary.cert.error.title')}
			text={t('beneficiary.cert.error.text')}
			color="white"
		/>
	)
}
