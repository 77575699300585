import { ReactNode } from 'react'
import styled from 'styled-components'

import { SubHeaderText } from 'components/text/SubHeaderText'

interface IStyledTableHeader {
	width: string
}

const StyledTableHeader = styled.th<IStyledTableHeader>`
	width: ${(props) => props.width};
	text-align: left;
	padding-block: 5px;
`

interface Props {
	width: string
	text?: string
	children?: ReactNode
}

/**
 * Component to show a table header
 *
 * @param props.width the width of the header
 * @param props.text the text to display
 * @param props.children React children
 */
export const TableHeader = ({ width, text, children, ...rest }: Props) => {
	return (
		<StyledTableHeader width={width} {...rest}>
			{text ? <SubHeaderText as="p">{text}</SubHeaderText> : children}
		</StyledTableHeader>
	)
}
