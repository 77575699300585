import styled from 'styled-components'

import { FooterContent } from 'components/FooterContent'

const Wrapper = styled.footer`
	background-color: var(--color-main-dark_blue);
	width: 100%;
`

/**
 * Footer component for company pages
 */
export const Footer = () => {
	return (
		<Wrapper>
			<FooterContent />
		</Wrapper>
	)
}
