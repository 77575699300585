import { backend } from 'api/backend'

import { AddOrEditBeneficiaryType } from 'types/AddOrEditBeneficiaryType'

/**
 * Get a beneficiary from backend by a given national identity number
 *
 * @param nationalIdentityNumber	The national identity number
 */
export const getBeneficiaryByNationalIdentityNumber = (
	nationalIdentityNumber: string
) => backend.get(`/portal/beneficiary/${nationalIdentityNumber}`)

/**
 * Function to get the uuid by the national identity number
 *
 * @param nationalIdentityNumber the nin of the beneficiary
 */
export const getUuidByNIN = (nationalIdentityNumber: string) =>
	backend.get('/uuidbynin/' + nationalIdentityNumber)

/**
 * Function to get beneficiary uuid and company name by session Id from bankID
 * Used from failed page
 *
 * @param sessionId the session id from bank id
 */
export const getUuidAndCompanyNameBySessionId = (sessionId: string) =>
	backend.get('/uuidandcompanybysession/' + sessionId)

/**
 * Function to create a new beneficiary
 *
 * @param beneficiary the beneficiary
 */
export const postAddSingleBeneficiary = (
	beneficiary: AddOrEditBeneficiaryType
) => {
	return backend.post('/portal/beneficiary', beneficiary)
}

/**
 * Function to post a bulk of the creation of new beneficiaries to backend
 *
 * @param jsonFile the json file containing the bulks
 */
export const postBulkAddBeneficiaries = (jsonFile: File) => {
	const formData = new FormData()
	formData.append('json', jsonFile)

	return backend.post(`/portal/beneficiaries`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		responseType: 'arraybuffer'
	})
}

/**
 * Function to edit a beneficiary
 *
 * @param beneficiaryId the id of the beneficiary to edit
 * @param beneficiary the beneficiary
 */
export const putEditBeneficiary = (
	beneficiaryId: string,
	beneficiary: AddOrEditBeneficiaryType
) => {
	return backend.put(`/portal/beneficiary/${beneficiaryId}`, {
		...beneficiary,
		beneficiaryName: beneficiary?.beneficiaryName
	})
}

/**
 * Gets all beneficiaries that are related to its national identity number.
 * A beneficiary can be registered in many companies, and will always have the same nin.
 *
 * @param nin the national identity number of the beneficiary
 */
export const getAllBeneficiariesByNin = (nin: string) => {
	return backend.get(`/portal/beneficiaries/${nin}`)
}

/**
 * Deletes a beneficiary by its national identity number.
 *
 * @param nin the national identity number of the beneficiary
 */
export const deleteBeneficiaryByNin = (nin: string) => {
	return backend.delete(`/portal/beneficiary/${nin}`)
}

/**
 * Deletes a beneficiary by its id.
 *
 * @param beneficiaryId the id of the beneficiary
 */
export const deleteBeneficiaryByIdAsFNF = (beneficiaryId: string) => {
	return backend.delete(`/portal/beneficiary/admin/${beneficiaryId}`)
}

export const getAllBeneficiariesInACompany = (
	pageSize: number = 25,
	pageNumber: number = 0,
	search: string = '',
	filter: 'all' | 'valid' | 'invalid' | 'processStarted' = 'all'
) => {
	return backend.get('/portal/beneficiaries', {
		params: { pageNumber, pageSize, search, filter }
	})
}
