import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button as BaseButton } from 'components/company-components/button/Button'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { buttonStyleLight, buttonStyleYellow } from 'styles/button'

import { EditBeneficiaryModal } from './EditBeneficiaryModal'
import { DeleteBeneficiaryModal } from './DeleteBeneficiaryModal'

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
`

const EditButton = styled(BaseButton)`
	${buttonStyleLight}
	min-width: 300px;
	height: 40px;
	margin-right: 60px;
`

const DeleteButton = styled(BaseButton)`
	${buttonStyleYellow}
	min-width: 300px;
	height: 40px;
`

interface Props {
	beneficiary: BeneficiaryType
	companyName: string
	onBeneficiaryChange: () => void
	onDeleteBeneficiary: () => void
}

/**
 * Displays the bottom buttons (edit beneficiary and delete beneficiary) in the beneficiary page
 *
 * @param props.beneficiary the beneficiary
 * @param props.companyName the name of the company selected
 * @param props.onBeneficiaryChange function to update the beneficiary
 * @param props.onDeleteBeneficiary function to handle the deletion of the beneficiary
 */
export const BottomButtons = ({
	beneficiary,
	companyName,
	onBeneficiaryChange,
	onDeleteBeneficiary
}: Props) => {
	const { t } = useTranslation('company')

	const [editModalOpen, setEditModalOpen] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)

	return (
		<Wrapper>
			<EditButton
				text={t('beneficiary.edit')}
				icon={{ name: 'Edit' }}
				onClick={() => setEditModalOpen(true)}
			/>
			<DeleteButton
				text={t('beneficiary.delete.beneficiary.button')}
				icon={{ name: 'Trash' }}
				onClick={() => setDeleteModalOpen(true)}
			/>

			<EditBeneficiaryModal
				isOpen={editModalOpen}
				onClose={() => {
					setEditModalOpen(false)
				}}
				beneficiary={beneficiary}
				companyName={companyName}
				onBeneficiaryChange={onBeneficiaryChange}
			/>
			<DeleteBeneficiaryModal
				isOpen={deleteModalOpen}
				onClose={() => setDeleteModalOpen(false)}
				name={beneficiary.name}
				nin={beneficiary.nationalIdentityNumber}
				beneficiaryId={beneficiary.id}
				onBeneficiaryChange={() => {
					setDeleteModalOpen(false)
					onDeleteBeneficiary()
				}}
				company={companyName}
			/>
		</Wrapper>
	)
}
