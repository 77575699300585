import React, { useState } from 'react'

import styled, { css } from 'styled-components'

import {
	buttonColorDark,
	buttonStyleWhite,
	privateButtonStyle
} from 'styles/button'

import { ButtonText as BaseButtonText } from 'components/private-components/text/ButtonText'
import { Icon, IconProps } from 'components/icon/Icon'
import { styleChain } from 'utils/styleChain'

const ButtonIconLeft = styled(BaseButtonText)`
	color: inherit;
	margin-right: 5px;
	font-size: 20px;
`

const ButtonIconRight = styled(BaseButtonText)`
	color: inherit;
	margin-left: 5px;
	font-size: 20px;
`

const ButtonText = styled(BaseButtonText)`
	color: inherit;
`

interface IStyledButton {
	fillWidth?: boolean
}

const StyledButton = styled.button<IStyledButton>`
	${privateButtonStyle}

	${(props) =>
		props?.disabled &&
		css`
			opacity: 0.5;
			&:hover {
				${buttonColorDark}
			}
		`};

	${({ color }) =>
		color === 'white'
			? css`
					${buttonStyleWhite}
					border: solid 0.01em var(--color-black);
			  `
			: css`
					background-color: ${color};
			  `}

	width: ${styleChain().case('fillWidth', '100%').default('auto')};
`

interface Props extends React.ComponentPropsWithoutRef<'button'> {
	text: string
	type?: 'button' | 'submit' | 'reset' | undefined
	onClick?: () => void
	leftIcon?: IconProps
	rightIcon?: IconProps
	fillWidth?: boolean
	color?: string
}

/**
 * Button component for private pages
 *
 * @param props.text The text on the button
 * @param props.type The type of button
 * @param props.onClick Function to handle button click
 * @param props.leftIcon Icon to the left
 * @param props.rightIcon Icon to the right
 * @param props.fillWidth To control the width of the button
 * @param props.color Button color
 */
export const Button = ({
	text,
	type = 'button',
	leftIcon,
	rightIcon,
	...rest
}: Props) => {
	// To control the button colours
	const [isActive, setIsActive] = useState(false)

	const textColor = isActive ? 'white' : undefined

	return (
		<StyledButton
			type={type}
			onMouseEnter={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
			{...rest}
		>
			{leftIcon && (
				<ButtonIconLeft color={textColor}>
					<Icon name={leftIcon.name} />
				</ButtonIconLeft>
			)}
			<ButtonText color={textColor}>{text}</ButtonText>
			{rightIcon && (
				<ButtonIconRight color={textColor}>
					<Icon name={rightIcon.name} />
				</ButtonIconRight>
			)}
		</StyledButton>
	)
}
