/**
 * Function to check if an object has a property
 *
 * @param obj 	- The object to check
 * @param prop	- The property to check
 */
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
	obj: X,
	prop: Y
): obj is X & Record<Y, unknown> {
	return obj.hasOwnProperty(prop)
}
