import styled from 'styled-components'
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal
} from '@azure/msal-react'
import { useTranslation } from 'react-i18next'

import { useGetCompanyNameOfUser } from 'hooks/useGetCompanyNameOfUser'

import { Link as BaseLink } from 'components/text/Link'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Icon as BaseIcon } from 'components/icon/Icon'
import { LogoutButton as BaseLogoutButton } from 'components/company-components/button/LogoutButton'
import { UnstyledLoginButton as BaseUnstyledLoginButton } from 'components/company-components/button/UnstyledLoginButton'

const HeaderWrapper = styled.nav`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	padding-inline: 40px;
`

const BackIconContainer = styled.p`
	margin-block: 0;
	margin-inline: 10px;
`

const Link = styled(BaseLink)`
	justify-content: center;
	align-items: center;
	display: flex;
	margin-left: 5px;
`

const RowWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	margin-top: 10px;
`

const RightColumnWrapper = styled.div`
	display: flex;
	align-items: center;
	&:only-child {
		margin-left: auto; //position on the right if there is no back button
	}
`

const LogoutButton = styled(BaseLogoutButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 30px;
	font-weight: var(--font-weight-light);

	&:hover {
		font-weight: var(--font-weight-bold);
	}
`

const LoginButton = styled(BaseUnstyledLoginButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 30px;
`

interface IHeaderText2 {
	loading: boolean
}

const HeaderText2 = styled(BaseHeaderText2)<IHeaderText2>`
	color: var(--color-text);
	font-size: var(--font-size-button-text);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-light);
	font-style: ${(props) => (props.loading ? 'italic' : undefined)};
`

const LogInOutIcon = styled(BaseIcon)`
	font-size: var(--font-size-text);
`

const BackIcon = styled(BaseIcon)`
	font-size: 20px;
	font-weight: var(--font-weight-light);

	&:hover {
		font-weight: var(--font-weight-bold);
	}
`

const LogoutOrLoginText = styled(BaseHeaderText2).attrs({ as: 'p' })`
	margin: 0 5px 0 10px;
	font-size: var(--font-size-button-text);
	font-family: var(--font-family-merriweather);
	font-weight: inherit;
`

interface Props {
	displayBackButton?: boolean | false
	navigateBackToLink?: string
	isPageList?: boolean
}

/**
 * Component to render the top of a page
 *
 * @param displayBackButton Flag to decide if back button should be visible
 * @param navigateBackToLink The link the back button navigates to
 * @param isPageList Flag for if the user should navigate back to the list
 */
export const TopOfPageContent = ({
	displayBackButton,
	navigateBackToLink,
	isPageList
}: Props) => {
	const name = useMsal()?.accounts?.[0]?.name
	const { t } = useTranslation('company')

	const { companyName, loadingCompanyName } = useGetCompanyNameOfUser()

	return (
		<HeaderWrapper>
			<RowWrapper>
				{displayBackButton && navigateBackToLink && (
					<Link to={navigateBackToLink}>
						<BackIconContainer>
							<BackIcon name="ArrowBack" />
						</BackIconContainer>
						{isPageList
							? t('dash.header.returnList')
							: t('dash.header.return')}
					</Link>
				)}
				<AuthenticatedTemplate>
					<RightColumnWrapper>
						<HeaderText2 as="p" loading={loadingCompanyName}>
							{companyName.length > 0 ? `${companyName}, ${name}` : name}
						</HeaderText2>
						<LogoutButton>
							<LogInOutIcon name="Exit" />
							<LogoutOrLoginText>
								{t('login.button_out')}
							</LogoutOrLoginText>
						</LogoutButton>
					</RightColumnWrapper>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<LoginButton>
						<LogInOutIcon name="Exit" />
						<LogoutOrLoginText>{t('login.button_in')}</LogoutOrLoginText>
					</LoginButton>
				</UnauthenticatedTemplate>
			</RowWrapper>
		</HeaderWrapper>
	)
}
