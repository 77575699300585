import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { displayFormatDate } from 'utils/displayFormatDate'
import { displayFormatNationalIdentityNumber } from 'utils/displayFormatNationalIdentityNumber'

import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'

import { ListTextItem } from './ListTextItem'

const Wrapper = styled.div`
	width: 50%;
	min-width: 500px;
	padding: 20px 20px 20px 40px;
`

const FlexWrapper = styled.div`
	display: flex;
`

const HeaderText2 = styled(BaseHeaderText2)`
	margin-top: 10px;
	margin-bottom: 20px;
`

const LeftListTextItem = styled(ListTextItem)`
	margin-right: 10px;
	min-width: 160px;
`

interface Props {
	beneficiary: BeneficiaryType
}

/**
 *
 * Beneficiary container to hold the data about a beneficiary
 *
 * @param props.beneficiary Object with fields from BeneficiaryType
 */
export const BeneficiaryContainer = ({ beneficiary }: Props) => {
	const { t } = useTranslation('company')
	return (
		<Wrapper>
			<HeaderText2>{beneficiary.name}</HeaderText2>
			<FlexWrapper>
				<LeftListTextItem
					title={t('beneficiary.personalia.dob')}
					text={
						beneficiary.birthdate.getFullYear() === 1800
							? '-'
							: displayFormatDate(beneficiary.birthdate)
					}
				/>
				<ListTextItem
					title={t('beneficiary.personalia.nin')}
					text={displayFormatNationalIdentityNumber(
						beneficiary.nationalIdentityNumber
					)}
				/>
			</FlexWrapper>
			<FlexWrapper>
				<LeftListTextItem
					title={t('beneficiary.personalia.country')}
					text={beneficiary.contact.country}
				/>
				<ListTextItem
					title={t('beneficiary.personalia.address')}
					text={beneficiary.contact.streetAddress}
				/>
			</FlexWrapper>
			<FlexWrapper>
				<LeftListTextItem
					title={t('beneficiary.personalia.zip')}
					text={beneficiary.contact.postcode}
				/>
				<ListTextItem
					title={t('beneficiary.personalia.city')}
					text={beneficiary.contact.city}
				/>
			</FlexWrapper>
			<ListTextItem
				title={t('beneficiary.personalia.phone')}
				text={beneficiary.contact.phoneNumber}
			/>
			<ListTextItem
				title={t('beneficiary.personalia.email')}
				text={beneficiary.contact.emailAddress}
			/>
		</Wrapper>
	)
}
