import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Text as BaseText } from 'components/text/Text'
import { ExternalLink } from 'components/text/ExternalLink'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { BackAndForwardButton } from '../BackAndForwardButtons'
import { InformationBox as BaseInformationBox } from '../InformationBox'
import { Checkbox as BaseCheckbox } from '../Checkbox'
import { AlternativeSubmissionButton } from './AlternativeSubmissionButton'

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
`

const InformationBox = styled(BaseInformationBox)`
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		// align-items: center;
	}
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ContentWrapper = styled.div`
	margin-top: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const ButtonWrapper = styled.div``

const HeaderText2 = styled(BaseHeaderText2).attrs({ as: 'p' })`
	margin-top: 20px;
	text-align: center;
	white-space: pre-line;
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		line-height: 27px;
	}
`

const Text = styled(BaseText)`
	white-space: pre-line;
`

const Checkbox = styled(BaseCheckbox)`
	margin-right: 15px;
`

interface Props {
	consent: boolean
	setConsent: React.Dispatch<React.SetStateAction<boolean>>
	onClickSwitchTab: (tabIndex: number, fromTab?: number) => void
}

/**
 * Details about step 3 in the walk-through process
 *
 * @param props.setConsent Function to set if the user consents
 * @param props.consent If the user has consent
 * @param props.onClickSwitchTab 	function that navigates to another tab index page. Optional parameter fromTab is to let the
 * 									browser know which page we came from so it moves back to the correct page
 */
export const Tab3 = ({ setConsent, consent, onClickSwitchTab }: Props) => {
	const { t } = useTranslation(['private', 'common'])

	return (
		<>
			<TopWrapper>
				<HeaderText1>{t('private:tabs.3.title')}</HeaderText1>
				<HeaderText2>{t('private:tabs.3.text')}</HeaderText2>
				<ContentWrapper>
					<InformationBox
						text={
							<Text>
								<Trans t={t} i18nKey={'private:tabs.3.terms'}>
									Jeg har lest{' '}
									<ExternalLink
										href="https://www.finansnorge.no/forsikringsdrift/digital-leveattest---personvernerklaring/"
										target="_blank"
										rel="noopener noreferrer"
									>
										{t('common:footer.privacyStatement')}
									</ExternalLink>
									, og samtykker til at mine personopplysninger,
									herunder biometriske data, behandles som beskrevet i
									denne. Jeg er klar over at jeg når som helst kan
									trekke tilbake samtykket og kreve at mine
									personopplysninger slettes. Jeg er også klar over at
									jeg i stedet kan sende inn Leveattest manuelt.
								</Trans>
							</Text>
						}
						rightSideProps={{ id: 'consent' }}
						leftChildren={
							<Checkbox
								name="checkbox"
								checked={consent}
								onClick={() => setConsent((v) => !v)}
								aria-labelledby="consent"
							/>
						}
					/>
				</ContentWrapper>
				<AlternativeSubmissionButton
					onClick={() => onClickSwitchTab(5, 3)}
					text={t('private:tabs.alternative')}
				/>
			</TopWrapper>
			<ButtonWrapper>
				<BackAndForwardButton
					disableForwardButton={!consent}
					hasForwardButton
					hasBackButton
					onClickBackButton={() => {
						onClickSwitchTab(2)
					}}
					onClickForwardButton={() => {
						onClickSwitchTab(4)
					}}
				/>
			</ButtonWrapper>
		</>
	)
}
