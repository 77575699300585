import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { putEditBeneficiary } from 'api/services/BeneficiaryService'

import { Modal as BaseModal } from 'components/common/Modal'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { ConfirmationModal } from 'components/common/ConfirmationModal'

import { BeneficiaryType } from 'types/BeneficiaryType'
import { AddOrEditBeneficiaryType } from 'types/AddOrEditBeneficiaryType'

import { AddOrEditBeneficiaryContent } from 'pages/company-pages/AddOrEditBeneficiaryContent'

const Modal = styled(BaseModal)`
	min-width: 900px;
`

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 30px;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	beneficiary: BeneficiaryType
	companyName: string
	onBeneficiaryChange: () => void
}

/**
 * Component to display the modal where a user can update and edit a beneficiary
 *
 * @param props.isOpen if the modal is open or not
 * @param props.onClose function to handle the closing of modal
 * @param props.beneficiary the beneficiary to edit
 * @param props.companyName the name of the company
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const EditBeneficiaryModal = ({
	isOpen,
	onClose,
	beneficiary,
	companyName,
	onBeneficiaryChange
}: Props) => {
	const { t } = useTranslation('company')

	// To handle loading
	const [isLoading, setIsLoading] = useState(false)

	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

	const reset = () => {
		onClose()
		setIsConfirmationOpen(false)
	}

	const onSave = (beneficiaryDetails: AddOrEditBeneficiaryType) => {
		setIsLoading(true)

		putEditBeneficiary(beneficiary.id.toString(), beneficiaryDetails)
			.then(() => {
				setIsLoading(false)
				onBeneficiaryChange()
				reset()
				toast.success(
					<div className="dismissToast">
						{t('beneficiary.cert.edit.success') as string}
					</div>
				)
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
				toast.error(
					<div className="dismissToast">
						{t('beneficiary.cert.edit.error') as string}
					</div>
				)
				setIsLoading(false)
			})
		setIsLoading(false)
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => setIsConfirmationOpen(true)}
				shouldCloseOnOverlayClick={false}
			>
				<ModalContent>
					<HeaderText1>{t('beneficiary.cert.edit.title')}</HeaderText1>
					<AddOrEditBeneficiaryContent
						onSave={onSave}
						beneficiary={beneficiary}
						buttonPosition="flex-end"
						buttonMinWidth="175px"
						buttonText={t('beneficiary.cert.edit.button')}
						isLoading={isLoading}
						companyName={companyName}
						isEdit
					/>
				</ModalContent>
			</Modal>
			<ConfirmationModal
				title={t('confirmationModal.edit.title')}
				text={t('confirmationModal.edit.text')}
				isOpen={isConfirmationOpen}
				onClose={() => setIsConfirmationOpen(false)}
				onYes={reset}
				onCancel={() => setIsConfirmationOpen(false)}
			/>
		</>
	)
}
