import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'components/Spinner'

import { COLOR_MAIN_DARK_BLUE } from 'styles/baseColors'

const Wrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`

/**
 * Component to display the loading spinner when creating a session with bank id
 */
export const LoadingBankIdPage = () => {
	const { t } = useTranslation('private')
	return (
		<Wrapper>
			<Spinner
				color={COLOR_MAIN_DARK_BLUE}
				size={10}
				speed={2}
				title={t('loading')}
			/>
		</Wrapper>
	)
}
