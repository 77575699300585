import { ContactType } from 'types/ContactType'

/**
 * Get if the post address exists and is valid
 */
export const getPostAddressExists = (contact: ContactType) => {
	return (
		contact.city !== null &&
		contact.country !== null &&
		contact.streetAddress !== null &&
		contact.postcode !== null
	)
}
