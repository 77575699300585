import i18n from 'language/i18n'

/**
 * Function to format the channel name from backend object with text to a
 * string which is displayed correctly to the end user
 *
 * @param channelName	The name of the channel
 *
 * @returns the correctly formatted string
 */
export const displayFormatChannelName = (
	channelName: 'sms' | 'email' | 'post'
) => {
	const lang = i18n.language

	if (channelName === 'sms') return 'SMS'
	if (channelName === 'email') return lang === 'no' ? 'E-post' : 'Email'
	if (channelName === 'post') return 'Post'
}
