/**
 * Function to display the correct format of the national identity number
 * Formatting from xxxxxxxxxxx to xxxxxx xxxxx
 *
 * @param nationalIdentityNumber - the nin to format
 */
export const displayFormatNationalIdentityNumber = (
	nationalIdentityNumber: string
) =>
	nationalIdentityNumber.slice(0, 6) +
	' ' +
	nationalIdentityNumber.slice(6, 11)
