import { RequestType } from 'types/RequestType'
import { CommunicationType } from 'types/CommunicationType'

import { mapJsonObjectToCommunicationType } from './mapJsonObjectToCommunicationType'

/**
 * Function to map from a JSON object from backend to request type
 *
 * @param responseJSON the object to map
 *
 * @returns the mapped beneficiary object
 */
export const mapJsonObjectToRequestType = (
	responseJSON: RequestType
): RequestType => {
	const request: RequestType = responseJSON as RequestType

	const communications = request.communications.map(
		(communication: CommunicationType) =>
			mapJsonObjectToCommunicationType(communication)
	)

	return {
		...request,
		communications
	}
}
