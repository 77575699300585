import styled from 'styled-components'

import { ButtonText } from 'components/company-components/text/ButtonText'
import { Button as BaseButton } from 'components/company-components/button/Button'
import { Text as BaseText } from 'components/text/Text'
import { Icon as BaseIcon } from 'components/icon/Icon'

import { buttonStyleWhiteMedium } from 'styles/button'

const StartAgainButton = styled(BaseButton)`
	${buttonStyleWhiteMedium}
	padding-inline: 25px;
	height: 40px;
	margin-top: 20px;
`

const Text = styled(BaseText)`
	text-align: center;
	padding-inline: 60px;
	line-height: 27px;
`

const Icon = styled(BaseIcon)`
	font-size: 25px;
	margin-right: 5px;
`

interface Props {
	text: string
	buttonText: string
	onStartAgain: () => void
}

/**
 * Displays the receipt text and a start again button for bulk uploads
 *
 * @param text the text to displays
 * @param buttonText the text on the button
 * @param onStartAgain function when button is clicked
 */
export const ReceiptButton = ({ text, buttonText, onStartAgain }: Props) => {
	return (
		<>
			<Text>{text}</Text>
			<StartAgainButton onClick={onStartAgain}>
				<Icon name="ArrowBack" />
				<ButtonText>{buttonText}</ButtonText>
			</StartAgainButton>
		</>
	)
}
