import { FlattenSimpleInterpolation } from 'styled-components'

interface StyledString {
	value: string | FlattenSimpleInterpolation
}

interface Case {
	key?: string
	equalTo?: string
	value?: StyledString['value']
	returnValue?: boolean
	callback?: (val: any) => StyledString['value']
}

interface Props {
	[key: string]: any
}

/**
 * For use with styled components.
 *
 * Takes a chain of cases and outputs the first one that meets the criteria for return.
 *
 * Without a default case, the output will be undefined
 *
 * @param cases array of cases following the Case interface
 * @returns A function that accepts props as an argument
 *
 * @example
 *
 * styleChain()
 * 	.case("black", "#111111") //if the prop black exists return #111111
 * 	.case("white", "#eeeeee")
 * 	.case("error", "#bb1111")
 * 	//if the prop color has the value blue return #1111bb
 * 	.equalsCase("color", "blue", "#1111bb")
 * 	.valueCase("color") //if the prop color exists, return it's value
 * 	.default("slategray")
 *
 * @example //optional wrapper for a valueCase
 *
 * styleChain()
 * 	//if the prop color exists, return the string 'background: <value of color>;'
 * 	.valueCase("color", color => `background: ${color}`)
 * 	.run() //we don't want a default value here, so we must call run manually
 */
export const styleChain = (cases: Case[] = []) => {
	return {
		case: (key: string, value: StyledString['value']) => {
			return styleChain([...cases, { key, value }])
		},
		equalsCase: (
			key: string,
			equalTo: string,
			value: StyledString['value']
		) => {
			return styleChain([...cases, { key, equalTo, value }])
		},
		valueCase: (
			key: string,
			callback?: (val: any) => StyledString['value']
		) => {
			return styleChain([
				...cases,
				{ key, returnValue: true, ...(callback ? { callback } : {}) }
			])
		},
		default: (value: StyledString['value']) => {
			return styleChain([...cases, { value }]).run()
		},
		run: () => {
			return (props: Props) => {
				return cases.reduce(
					(result, { key, value, equalTo, returnValue, callback }) => {
						if (result) return result // skip execution
						if (!key) return value //if no key, we must be in a default case!

						const operator = equalTo
							? props?.[key] === equalTo
							: props?.[key]
						const maybeCallbackValue = callback
							? callback(props?.[key])
							: props?.[key]

						const outputValue = returnValue ? maybeCallbackValue : value
						if (operator) return outputValue

						return undefined
					},
					undefined
				)
			}
		}
	}
}

/**
 * INCOMPLETE!
 *
 * Receives a list of cases that will be checked for in succession and outputs another function that receives a list of props as its argument.
 *
 * If a case matches equalTo or has no equalTo and is true, then the output is set to its value. Otherwise, execution tries the next case until all cases are exhausted. If that happens, returns the defaultCase
 * @param  {string} defaultCase What to return if no props match the given keys or equality operators
 * @param  {...any} cases object containing the keys "key", "equals" and "value"
 * @returns
 */
/*export const styleSwitch =
	(defaultCase, ...cases) =>
	(props) => {
		const caseResult = cases.reduce((result, { key, value, equals }) => {
			if (result) return result
			const operator = equals ? props?.[key] === equals : props?.[key]
			console.log('StyleSwitch', { result, key, value, equals, operator })
			return operator ? value : undefined
		}, undefined)
		return caseResult ?? defaultCase
	}*/

/*const sopp = styleSwitch(
	'blue',
	{ key: 'color', equals: 'white', value: 'var(--color-white)' },
	{ key: 'isTonight', value: 'var(--color-red)' }
)*/

/*styled.div`
	${styleSwitch( "blue",
		{ key: "color", equals: "white", value: "var(--color-white)"},
		{ key: "isTonight", value: "var(--color-red)"})
	}
`*/

//const calledSopp = sopp({ color: "black", isTonight: false })

//console.log({ sopp, calledSopp })

/*const styleCase = (key, value, equals) => {
	return {
		key,
		value,
		...(equals ? { equals } : {})
	}
}

const styleCaseSopp = styleSwitch(
	'blue',
	styleCase('color', 'var(--color-white)', 'white'),
	styleCase('isTonight', 'var(--color-red)')
)*/

//const calledStyleCaseSopp = styleCaseSopp({ color: "black", isTonight: false })

//console.log({ styleCaseSopp, calledStyleCaseSopp })

//make a utility function that creates the object to make it more readable
/*styled.div`
	${styleSwitch( "blue",
		styleCase("color", "var(--color-white)", "white"),
		styleCase("isTonight", "var(--color-red)")
	}
`*/

//make it so the chain returns a function that accepts props

/*styled.div`
	${styleChain(props)
		.case("isActive", "background: blue", "white")
		.case("isPast", "background: red")
		.default("background: green")
	}
`*/
