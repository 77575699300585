/**
 * Type for the bank id details
 */
export interface BankIDType {
	intentUrl: string
	handoverId: string
}

export const InitialEmptyBankIDDetails: BankIDType = {
	intentUrl: '',
	handoverId: ''
}

/**
 * Bank ID session type
 */
export interface BankIDSessionType {
	state:
		| 'created'
		| 'started'
		| 'face_match_retry'
		| 'completed'
		| 'failed'
		| 'expired'
		| 'mrz_completed'
		| 'nfc_completed'
}

/**
 * Type for bank id error message
 */
export interface BankIDErrorType {
	title: string
	text: string
}
