import { ReactNode } from 'react'
import styled from 'styled-components'
import useCollapse from 'react-collapsed'

import { buttonReset } from 'styles/button'
import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { Text as BaseText } from 'components/text/Text'
import { Icon } from 'components/icon/Icon'

const Div = styled.div`
	@media screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		&:hover {
			color: var(--color-main-blue_green);
		}
	}
`

const Button = styled.button`
	display: flex;
	background-color: transparent;
	${buttonReset}
	font-family: var(--font-family-merriweather);
	font-size: var(--font-size-text);
	font-weight: var(--font-weight-bold);
	color: inherit;
`

const Section = styled.section`
	margin-top: 5px;
`

const HeaderText = styled(BaseText)`
	margin-left: 5px;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	text-align: left;
`

const Text = styled(BaseText)`
	color: inherit;
`

interface Props {
	title: string
	children: ReactNode
}

/**
 * Button that expands a section when clicked, then closes again on the next click.
 *
 * @param props.title Text on the expand button
 * @param props.children React children used for the content of the expandable area
 * @returns
 */
export const Expandable = ({ title, children, ...rest }: Props) => {
	const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

	return (
		<Div {...rest}>
			<Button {...getToggleProps()}>
				<Icon name={isExpanded ? 'CaretDown' : 'CaretRight'} />
				<HeaderText>{title}</HeaderText>
			</Button>
			<Section {...getCollapseProps()}>
				<Text>{children}</Text>
			</Section>
		</Div>
	)
}
