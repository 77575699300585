import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { getUuidAndCompanyNameBySessionId } from 'api/services/BeneficiaryService'

import { Button } from 'components/private-components/button/Button'
import { AButton } from 'components/private-components/button/AButton'
import { Text as BaseText } from 'components/text/Text'

import { ELECTRONIC_PROCESS_START_PAGE_PATH } from 'navigation/navigationURLs'

import { BankIDErrorType } from 'types/BankIDType'

import { openBankIDApp } from 'utils/openBankIDApp'

import { CompletedBankIdContent } from './CompletedBankIDContent'
import { LoadingBankIdPage } from './WalkThroughPage/LoadingBankIdPage'

const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
`

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const WatchAgainButtonWrapper = styled.div`
	margin-top: 10px;
`

const BankIDErrorText = styled(BaseText)`
	margin-top: 10px;
	margin-bottom: 20px;
	color: var(--color-error);
`

/**
 * Page to display the error message given by bank id
 *
 * @Example url to test: http://localhost:3000/privat/feilet?id=dac63c1d-42dc-4c05-92fb-0a68e590da86&code=BAD_DOCUMENT
 */
export const FailedToUploadLCPage = () => {
	// Get the session id and the error code from the url
	const [searchParams] = useSearchParams()
	const { t } = useTranslation('private')

	const sessionId = searchParams.get('id')
	const errorCode = searchParams.get('code') as
		| 'BAD_DOCUMENT'
		| 'BAD_PERSON'
		| 'CLIENT_FAILURE'
		| 'SERVER_FAILURE'
		| 'SESSION_TIMEOUT'

	// Handle the response, uuid and company name
	const [hasResponse, setHasResponse] = useState(false)
	const [uuid, setUuid] = useState<string>()
	const [companyName, setCompanyName] = useState<string>()

	// To handle the error
	const [bankIdError, setBankIdError] = useState(false)

	// To handle the spinner
	const [showSpinner, setShowSpinner] = useState(false)

	/**
	 * Component to get the bank id error type
	 */
	const getBankIDError = (
		code:
			| 'BAD_DOCUMENT'
			| 'BAD_PERSON'
			| 'CLIENT_FAILURE'
			| 'SERVER_FAILURE'
			| 'SESSION_TIMEOUT'
	): BankIDErrorType => {
		if (code === 'BAD_DOCUMENT') {
			return {
				title: t('failed.errors.document.title'),
				text: t('failed.errors.document.text')
			}
		}
		if (code === 'BAD_PERSON') {
			return {
				title: t('failed.errors.person.title'),
				text: t('failed.errors.person.text')
			}
		}
		if (code === 'CLIENT_FAILURE') {
			return {
				title: t('failed.errors.client.title'),
				text: t('failed.errors.client.text')
			}
		}
		if (code === 'SERVER_FAILURE') {
			return {
				title: t('failed.errors.server.title'),
				text: t('failed.errors.server.text')
			}
		}
		if (code === 'SESSION_TIMEOUT') {
			return {
				title: t('failed.errors.session.title'),
				text: t('failed.errors.session.text')
			}
		}
		return {
			title: t('failed.errors.generic.title'),
			text: t('failed.errors.generic.text')
		}
	}

	/**
	 * Get the uuid and company details immediately
	 */
	useEffect(() => {
		if (sessionId !== null && !hasResponse) {
			getUuidAndCompanyNameBySessionId(sessionId)
				.then((res) => {
					// Get the data on page load
					setUuid(res.data.uuid)
					setCompanyName(res.data.companyName)
					setHasResponse(true)
				})
				.catch((err) => {
					console.error(err)
					console.log({ err })
					setHasResponse(true)
					toast.error(
						<div className="dismissToast">
							{t('failed.errors.identifier') as string}
						</div>
					)
				})
		}
		// eslint-disable-next-line
	}, [hasResponse, sessionId])

	/**
	 * Send the user back to the bank id application
	 */
	const onClickBackToBankId = () => {
		if (uuid) {
			openBankIDApp(setShowSpinner, setBankIdError, uuid)
		} else {
			console.log('Error with uuid')
			// TODO - Handle error
		}
	}

	/**
	 * Display the correct page content
	 */
	const getPageContent = () => {
		if (showSpinner) return <LoadingBankIdPage />
		return (
			<>
				<CompletedBankIdContent
					success={false}
					title={getBankIDError(errorCode).title}
					text={getBankIDError(errorCode).text}
				/>
				<ButtonWrapper>
					<Button text={t('failed.back')} onClick={onClickBackToBankId} />
					<WatchAgainButtonWrapper>
						<AButton
							text={t('failed.rewatch')}
							href={`${ELECTRONIC_PROCESS_START_PAGE_PATH}/${companyName}/${uuid}`}
							color="white"
						/>
					</WatchAgainButtonWrapper>
					{bankIdError && (
						<BankIDErrorText>{t('failed.errors.bankID')}</BankIDErrorText>
					)}
				</ButtonWrapper>
			</>
		)
	}

	return (
		<Wrapper aria-live="polite" aria-busy={showSpinner}>
			{getPageContent()}
		</Wrapper>
	)
}
