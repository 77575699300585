import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { LifeCertificateType } from 'types/LifeCertificateType'
import { RequestType } from 'types/RequestType'
import { BeneficiaryType } from 'types/BeneficiaryType'

import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'

import { Modal } from 'components/common/Modal'

import { useTimer } from 'hooks/useTimer'
import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { ModalContents } from './modal/ModalContents'
import { CaseInitial } from './cases/CaseInitial'
import { CaseRequestedOrReminded } from './cases/CaseRequestedOrReminded'
import { CaseMarkedInvalid } from './cases/CaseMarkedInvalid'
import { CaseUploaded } from './cases/CaseUploaded'
import { CaseValid } from './cases/CaseValid'
import { CaseExpired } from './cases/CaseExpired'
import { FailureCase } from './cases/FailureCase'
import { getPhoneNumberExists } from './contactInformationValidators/getPhoneNumberExists'
import { getEmailAddressExists } from './contactInformationValidators/getEmailAddressExists'
import { getPostAddressExists } from './contactInformationValidators/getPostAddressExists'

const Wrapper = styled.div`
	background-color: var(--color-main-blue_green);
	width: 50%;
	min-width: 500px;
	padding: 20px;
	padding-left: 40px;
	border-radius: 0 20px 20px 0;
	height: 100%;
	margin-bottom: 40px;
`

const HeaderText2 = styled(BaseHeaderText2)`
	margin-top: 10px;
	margin-bottom: 20px;
`

interface Props {
	lifeCertificate: LifeCertificateType | null
	request: RequestType | null
	beneficiary: BeneficiaryType
	onBeneficiaryChange: () => void
}

/**
 * Component to hold and display the details about a life certificate
 * on the beneficiary pag
 *
 * @param props.lifeCertificate the life certificate of the beneficiary
 * @param props.request the request
 * @param props.beneficiary the beneficiary we are on
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const LifeCertificateContainer = ({
	lifeCertificate,
	request,
	beneficiary,
	onBeneficiaryChange
}: Props) => {
	const { t } = useTranslation('company')
	const { isAdmin } = useIsAdminRole()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalType, setModalType] = useState<'request' | 'remind'>('request')

	const fileName = `leveattest_ytelsesmottaker_${beneficiary.nationalIdentityNumber}.pdf`

	// To handle the updating of the beneficiary when the modal either times out or is closed by the user
	const [sent, setSent] = useState(false)
	const { time, startTimer } = useTimer(10, () => {
		setIsModalOpen(false)
		onBeneficiaryChange()
	})

	/**
	 * Function to handle the on click send request
	 */
	const onClickSendRequest = () => {
		setModalType('request')
		!isAdmin && setIsModalOpen(true)
	}

	/**
	 * Function to handle the on click send remind
	 */
	const onClickSendRemind = () => {
		setModalType('remind')
		!isAdmin && setIsModalOpen(true)
	}

	/**
	 * Function to display the details of a life certificate
	 */
	const displayLifeCertificateDetails = () => {
		switch (beneficiary.caseName) {
			// Case 1 - Initial
			case 'initial': {
				return (
					<CaseInitial
						onClick={onClickSendRequest}
						beneficiary={beneficiary}
					/>
				)
			}
			// Case 2 - Requested or reminded
			case 'requestedOrReminded': {
				// Check that we have values, otherwise failure case
				if (request !== null) {
					return (
						<CaseRequestedOrReminded
							onClickSend={onClickSendRemind}
							communications={request.communications}
							beneficiary={beneficiary}
							request={request}
							onBeneficiaryChange={onBeneficiaryChange}
						/>
					)
				} else return <FailureCase />
			}
			// Case 3 - Uploaded manually by the user
			case 'uploaded': {
				// Check that we have values, otherwise failure case
				if (
					lifeCertificate !== null &&
					lifeCertificate.documentBase64 !== null
				) {
					return (
						<CaseUploaded
							beneficiary={beneficiary}
							lifeCertificate={lifeCertificate}
							linkSource={lifeCertificate.documentBase64}
							fileName={fileName}
							onBeneficiaryChange={onBeneficiaryChange}
						/>
					)
				} else return <FailureCase />
			}
			// Case 4 - Marked as Invalid
			case 'markedInvalid': {
				// Check that we have values, otherwise failure case
				if (lifeCertificate !== null) {
					return (
						<CaseMarkedInvalid
							beneficiary={beneficiary}
							lifeCertificate={lifeCertificate}
							onClickSendNewRequest={onClickSendRequest}
						/>
					)
				} else return <FailureCase />
			}
			// Case 5 - Expired
			case 'expired': {
				// Check that we have values, otherwise failure case
				if (
					lifeCertificate !== null &&
					lifeCertificate.expiryDate !== null
				) {
					return (
						<CaseExpired
							expiryDate={lifeCertificate.expiryDate}
							beneficiary={beneficiary}
							onClick={onClickSendRequest}
						/>
					)
				} else return <FailureCase />
			}
			// Case 6 - Valid
			case 'valid': {
				// Check that we have values, otherwise failure case
				if (
					lifeCertificate !== null &&
					lifeCertificate.issueDate !== null &&
					lifeCertificate.expiryDate !== null
				) {
					return (
						<CaseValid
							issueDate={lifeCertificate.issueDate}
							expiryDate={lifeCertificate.expiryDate}
							beneficiary={beneficiary}
							method={lifeCertificate.method}
							onBeneficiaryChange={onBeneficiaryChange}
						/>
					)
				} else return <FailureCase />
			}
			default: {
				return <FailureCase />
			}
		}
	}

	const onCloseModal = () => {
		setIsModalOpen(false)
		if (sent) {
			onBeneficiaryChange()
		}
	}

	/**
	 * Function to get the name of the channel if the beneficiary only has one possible channel type
	 */
	const getChannelIfOnlyOnePossible = () => {
		const hasPhone = getPhoneNumberExists(beneficiary.contact)
		const hasEmail = getEmailAddressExists(beneficiary.contact)
		const hasPost = getPostAddressExists(beneficiary.contact)

		if (hasPhone && !hasEmail && !hasPost) return 'sms'
		if (!hasPhone && hasEmail && !hasPost) return 'email'
		if (!hasPhone && !hasEmail && hasPost) return 'post'
	}

	return (
		<Wrapper>
			<HeaderText2 color="white">{t('beneficiary.cert.title')}</HeaderText2>
			{displayLifeCertificateDetails()}
			<Modal isOpen={isModalOpen} onClose={onCloseModal}>
				<ModalContents
					type={modalType}
					beneficiary={beneficiary}
					onSuccess={() => {
						startTimer()
						setSent(true)
					}}
					isSent={sent}
					time={time}
					onlyOneChannel={getChannelIfOnlyOnePossible()}
				/>
			</Modal>
		</Wrapper>
	)
}
