import { css } from 'styled-components'

import { BASE_BREAKPOINT_MOBILE } from './baseBreakpoints'

export const buttonColorLight = css`
	background-color: var(--color-main-turquoise);
	color: var(--color-black);
`

const colorMedium = css`
	background-color: var(--color-main-blue_green);
	color: var(--color-white);
`

export const buttonColorDark = css`
	background-color: var(--color-main-dark_blue);
	color: var(--color-white);
`

export const buttonColorWhite = css`
	background-color: var(--color-white);
	color: var(--color-black);
`

const colorYellow = css`
	background-color: var(--color-yellow);
	color: var(--color-black);
`

/**
 * Private pages
 */
export const buttonStyleLight = css`
	${buttonColorLight}
	&:hover {
		${colorMedium}
	}
	&:focus {
		${buttonColorDark}
	}
`

/**
 * For use on company pages
 */
export const buttonStyleLightYellow = css`
	${buttonColorLight}
	&:hover {
		${buttonColorDark}
	}
	&:focus {
		${colorMedium}
	}
`

/**
 * Dark Blue (Dark) into Turquoise (Light)
 */
export const buttonStyleDark = css`
	${buttonColorDark}
	&:hover {
		${buttonColorLight}
	}
	&:focus {
		${colorMedium}
	}
`

/**
 * White into Dark Blue (Dark)
 */
export const buttonStyleWhiteD = css`
	${buttonColorWhite}
	border: 1px solid var(--color-black);
	&:hover {
		${buttonColorDark}
		border-color: transparent;
	}
	&:focus {
		${colorMedium}
		border-color: transparent;
	}
`

/**
 * White into BlueGreen
 */
export const buttonStyleWhiteMedium = css`
	${buttonColorWhite}
	border: 1px solid var(--color-black);
	&:hover {
		${buttonColorLight}
		border-color: transparent;
	}
	&:focus {
		${colorMedium}
		border-color: transparent;
	}
`

/**
 * Yellow style
 */
export const buttonStyleYellow = css`
	${colorYellow}
	&:hover {
		${colorMedium}
	}
	&:focus {
		${buttonColorDark}
	}
`

/**
 * For private pages
 */
export const buttonStyleWhite = css`
	${buttonColorWhite}
	border: 1px solid var(--color-black);
	&:hover {
		${buttonColorLight}
		border-color: transparent;
	}
	&:focus {
		${buttonColorDark}
		border-color: transparent;
	}
`

/**
 * For company pages, only used on two-line icon buttons
 */
export const buttonStyleWhiteYellow = css`
	${buttonColorWhite}
	border: 1px solid var(--color-black);
	&:hover {
		${colorYellow}
		border: 1px solid var(--color-black);
	}
	&:focus {
		${buttonColorDark}
		border-color: transparent;
	}
`

export const buttonReset = css`
	border: none;
	text-decoration: none;
	background-color: transparent;
	color: var(--color-black);
	cursor: pointer;
	padding: 0;
	margin: 0;
`

export const companyButtonStyle = css`
	${buttonStyleLightYellow}
	border: none;
	text-decoration: none;
	color: var(--color-black);
	padding-inline: 25px;
	padding-block: 7px;
	min-width: 120px;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0.1px 0.5px 4px rgb(0, 0, 0, 25%);

	&:focus {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	& > * {
		margin-block: 4px;
	}
`

export const privateButtonStyle = css`
	${buttonStyleDark}
	border: none;
	text-decoration: none;
	padding-inline: 20px;
	padding-block: 5px;
	min-width: 120px;
	max-width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0.1px 0.5px 4px rgb(0, 0, 0, 25%);

	&:focus {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	& > * {
		margin-block: 0;
	}

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		padding-block: 7px;
		height: 50px;
	}
`
