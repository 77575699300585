import { useEffect, useRef, useState } from 'react'

/**
 * Timer which will work like a stopclock
 *
 * Current issue is that if you call stopTimer() and resetTimer()
 * back to back, resetTimer will see the old value of stopTimer and
 * throw an Error
 * @param initialTime Time to count down from, in seconds
 * @param onTimeRanOut Callback executed when time runs out
 * @param debug Whether to show log output
 * @returns
 */
export const useTimer = (
	initialTime: number,
	onTimeRanOut: () => void = () => {},
	debug: boolean = false
) => {
	const [time, setTime] = useState(initialTime)
	const [running, setRunning] = useState(false)
	const interval = useRef<any | null>(null)
	const [timeRanOut, setTimeRanOut] = useState(false)

	//removes the interval reference and stops it from executing
	const clear = () => {
		clearInterval(interval.current)
		interval.current = null
	}

	const resetTimer = () => {
		clear()
		setRunning(false)
		setTime(initialTime)
		setTimeRanOut(false)
		if (debug) console.log(`Reset the timer`)
	}

	const startTimer = () => {
		//had to dump this entire thing inside the setstate callback so I could work
		//with a fresh running value. Otherwise, it was desynced and everything broke.
		setRunning((freshRunning) => {
			if (freshRunning || interval.current) clear()
			interval.current = setInterval(() => setTime((time) => time - 1), 1000)
			return true
		})
		if (debug) console.log(`Started the timer`)
	}

	//stop timer if time runs out
	useEffect(() => {
		//if we haven't timed out yet, but should:
		if (!timeRanOut && time <= 0) {
			setTimeRanOut(true)
			setRunning(false)
			clear()
			onTimeRanOut()
			debug && console.log('Time ran out!')
		}
	}, [timeRanOut, time, debug, onTimeRanOut])

	/**
	 * When initially rendered, start the timer if time had changed before that time
	 * and it hadn't ran out already
	 *
	 * When component demounts, stop the interval
	 */
	useEffect(() => {
		if (time !== initialTime && !timeRanOut) startTimer()
		return () => clear()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		debug && console.log(time)
	}, [time, debug])

	return { time, running, timeRanOut, startTimer, resetTimer }
}
