/**
 * The type of address
 */
export interface ContactType {
	id: number
	createdOn: Date
	streetAddress: string | null
	city: string | null
	country: string | null
	postcode: string | null
	emailAddress: string | null
	phoneNumber: string | null
}

/**
 * Empty contact for initial types when loading
 */
export const initialEmptyContact: ContactType = {
	id: -1,
	createdOn: new Date(),
	streetAddress: null,
	city: null,
	country: null,
	postcode: null,
	emailAddress: null,
	phoneNumber: null
}
