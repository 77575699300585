import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { displayFormatNationalIdentityNumber } from 'utils/displayFormatNationalIdentityNumber'

import { IconButton as BaseIconButton } from 'components/icon/IconButton'

import { InvalidListElementType } from 'types/InvalidListElementType'

import { ValidateLCModal } from 'pages/company-pages/ValidateLCModal'

import { TableRow } from './TableRow'
import { TableData } from './TableData'

const TableDataNin = styled(TableData)`
	padding-left: 10px;
	border-left: 10px;
`

const TableDataIcon = styled(TableData)`
	display: flex;
	align-items: center;
	justify-content: center;
`

const IconButton = styled(BaseIconButton)`
	font-size: 25px;
	color: var(--color-main-blue_green);
`

interface Props {
	element: InvalidListElementType
	onRegisterSuccess: () => void
}

/**
 * Component to display each row with data in the table
 *
 * @param props.element the element with data in the row
 * @param props.onRegisterSuccess function to handle the success of registering LCs
 */
export const TableDataRow = ({
	element,
	onRegisterSuccess,
	...rest
}: Props) => {
	const [modalOpen, setModalOpen] = useState(false)

	const { t } = useTranslation('company')

	// Create the file information
	const fileName = `leveattest_ytelsesmottaker_${element.nationalIdentityNumber}.pdf`

	return (
		<TableRow {...rest}>
			<TableDataNin
				text={displayFormatNationalIdentityNumber(
					element.nationalIdentityNumber
				)}
			/>
			<TableData text={element.name} />
			<TableData text={element.date} />
			<TableDataIcon>
				<IconButton
					icon="VerifyDocument"
					screenReaderText={t('dash.list.table.verify.alt')}
					onClick={() => setModalOpen(true)}
				/>
			</TableDataIcon>
			<ValidateLCModal
				modalOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				base64String={element.documentBase64}
				fileName={fileName}
				beneficiaryName={element.name}
				nin={element.nationalIdentityNumber}
				onRegisterSuccess={onRegisterSuccess}
			/>
		</TableRow>
	)
}
