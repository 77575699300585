import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import {
	getAllBeneficiariesByNin,
	getBeneficiaryByNationalIdentityNumber
} from 'api/services/BeneficiaryService'

import { LOGIN_PATH, YTELSESMOTTAKER_PATH } from 'navigation/navigationURLs'

import { Button } from 'components/company-components/button/Button'
import { Icon as BaseIcon } from 'components/icon/Icon'

import { DashboardBox } from './DashboardBox'

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;

	> * {
		min-width: 300px;
	}
`

const InputWrapper = styled.div`
	position: relative;
	margin-bottom: 30px;
`

const Icon = styled(BaseIcon)`
	position: absolute;
	color: var(--color-main-blue_green);
	min-height: 2rem;
	min-width: 2rem;
	padding: 4px;
	box-sizing: border-box;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
	font-size: 30px;
	margin-left: 10px;
`

const Label = styled.label`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	margin: 0;
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-box-ingress);
	text-align: center;
	margin-bottom: 30px;
`

const InputField = styled.input`
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	min-width: 300px;
	min-height: 50px;
	border-radius: 5px;
	border: solid 1px var(--color-main-blue_green);
	box-sizing: border-box;
	padding-left: 3.2rem;
	padding-top: 10px;
	padding-bottom: 10px;
	&:hover {
		outline: 1px solid var(--color-main-blue_green);
	}
	&:focus-visible {
		outline: 2px solid var(--color-main-blue_green);
		outline-offset: 2px;
	}
`

const ButtonWrapper = styled.div``

interface InputTypes {
	nationalIdentityNumber: string
}

/**
 * Component to show the search box where you can search on national identity numbers
 */
export const SearchBox = () => {
	const { register, handleSubmit, setError } = useForm<InputTypes>()
	const { t } = useTranslation('company')

	const { isAdmin } = useIsAdminRole()

	const navigate = useNavigate()

	const setCustomError = (message: string, isDismissable: boolean = true) => {
		console.log(
			`Setting custom error for ${
				isDismissable ? 'dismissable' : 'undismissable'
			} toast!`,
			message
		)
		setError('nationalIdentityNumber', { type: 'custom', message })
		if (!isDismissable) return toast.error(message)
		toast.error(<div className="dismissToast">{message}</div>)
	}

	const [isLoading, setIsLoading] = useState(false)

	/**
	 * Function to handle the onSubmit of the form.
	 *
	 * @param data	The data sent from the form
	 */
	const onSubmit = (data: unknown) => {
		// Convert the unknown data to its correct type
		const inputNin: number = (data as { nationalIdentityNumber: number })
			.nationalIdentityNumber

		setIsLoading(true)

		/**
		 * If we are logged in as FNF get the list, otherwise get a single beneficiary
		 */
		if (isAdmin === undefined) toast.error(t('dash.search.errors.admin'))
		if (isAdmin) {
			getBeneficiariesByNinFNF(inputNin.toString())
		} else {
			getBeneficiaryByNinCompany(inputNin.toString())
		}
	}

	const onError = (errors: Object) => {
		console.log({ errors })
		console.error(errors)
		Object.values(errors).forEach((error) => {
			toast.error(<div className="dismissToast">{error.message}</div>)
		})
	}

	/**
	 * Gets a single beneficiary by its nin if the logged in user is from another company than FNF
	 *
	 * @param inputNin the nin to look up
	 */
	const getBeneficiaryByNinCompany = (inputNin: string) => {
		getBeneficiaryByNationalIdentityNumber(inputNin)
			.then((res) => {
				setIsLoading(false)
				navigateOnSearchSuccess(
					res.data.id,
					res.data.nationalIdentityNumber,
					'dashboard'
				)
			})
			.catch((err) => {
				setIsLoading(false)
				console.log({ err })
				console.error(err)
				setResponseErrors(err.response.status)
			})
	}

	/**
	 * Gets a list of beneficiaries by its nin if the logged in user is from FNF
	 *
	 * @param inputNin the nin to look up
	 */
	const getBeneficiariesByNinFNF = (inputNin: string) => {
		getAllBeneficiariesByNin(inputNin)
			.then((res) => {
				setIsLoading(false)
				if (!res.data.beneficiaries.length)
					return setCustomError(t('dash.search.errors.404'))
				const ids: string[] = res.data.beneficiaries.map(
					(r: any) => r.id as string
				)
				navigateOnSearchSuccess(
					'admin/' + ids.join('+'),
					res.data.beneficiaries[0].nationalIdentityNumber
				)
			})
			.catch((err) => {
				setIsLoading(false)
				console.log({ err })
				console.error(err)
				setResponseErrors(err.response.status)
			})
	}

	/**
	 * Navigates to the correct page based on the function that calls it
	 */
	const navigateOnSearchSuccess = (id: string, nin: string, from?: string) => {
		navigate(`${YTELSESMOTTAKER_PATH}/${id}`, {
			state: {
				nin: nin,
				from: from
			}
		})
	}

	const setResponseErrors = (code: any) => {
		// eslint-disable-next-line eqeqeq
		if (code == 401) {
			navigate(LOGIN_PATH)
			return setCustomError(t('dash.search.errors.401'), false)
		}
		// eslint-disable-next-line eqeqeq
		if (code == 404) {
			return setCustomError(t('dash.search.errors.404'))
		}
		return setCustomError(t('dash.search.errors.generic'))
	}

	return (
		<DashboardBox title={t('dash.search.title')} minHeight="240px">
			<Label htmlFor="searchBox">{t('dash.search.text')}</Label>
			<FormContainer
				onSubmit={handleSubmit(onSubmit, onError)}
				id="ninForm"
				title="National identity number search box"
			>
				<InputWrapper>
					<Icon name="PersonCircle" />
					<InputField
						id="searchBox"
						{...register('nationalIdentityNumber', {
							required: t('dash.search.errors.required'),
							minLength: {
								value: 11,
								message: t('dash.search.errors.min')
							},
							maxLength: {
								value: 11,
								message: t('dash.search.errors.max')
							},
							pattern: {
								value: /^\d*$/,
								message: t('dash.search.errors.number')
							}
						})}
						placeholder={t('dash.search.placeholder')}
					/>
				</InputWrapper>
				<ButtonWrapper>
					<Button
						type="submit"
						text={
							isLoading
								? t('dash.search.loading')
								: t('dash.search.button')
						}
						hoverColor="blue"
						icon={{
							name: 'Search'
						}}
						fillWidth
					/>
				</ButtonWrapper>
			</FormContainer>
		</DashboardBox>
	)
}
