import { ReactNode, FC } from 'react'

interface WrapperProps {
	children: ReactNode
}

const DefaultElement = ({ children }: WrapperProps) => <span>{children}</span>

/**
 * Takes a list of strings, adds conjunctions
 * (commas between elements and an and between
 * the last two elements) to that list, then returns
 * the new conjunction-added list wrapped in provided
 * JSX elements.
 *
 * Elements (non-conjunctions) are wrapped with
 * the elementWrapper, while conjuctions are wrapped
 * with the conjunctionWrapper element.
 *
 * The conjunctionWrapper is optional - it defaults
 * to a span
 * @param text
 * @param elementWrapper
 * @param conjunctionWrapper
 *
 * @example
 * const Bold = ({ children }: { children: ReactNode }) => <b>{children}</b>
 *
 * mapListToFormattedJSX(['Soppstuing', 'Kakerull', 'Potetstappe'], Bold)}
 *
 * [
 * 	<b>Soppstuing</b>,
 * 	<span>, </span>,
 * 	<b>Kakerull</b>,
 * 	<span> og </span>,
 * 	<b>Potetstappe</b>
 * ]
 *
 */
export const mapListToFormattedJSX = (
	text: Iterable<string>,
	elementWrapper: FC<WrapperProps>,
	conjunctionWrapper?: FC<WrapperProps>
) => {
	const Conjunction = conjunctionWrapper ?? DefaultElement
	const Element = elementWrapper

	return new Intl.ListFormat('no', { style: 'long', type: 'conjunction' })
		.formatToParts(text)
		.map(({ type, value }) => {
			if (type === 'element') {
				return <Element>{value}</Element>
			}
			return <Conjunction>{value}</Conjunction>
		})
}
