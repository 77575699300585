// Source: https://no.wikipedia.org/wiki/MOD11 && https://no.wikipedia.org/wiki/F%C3%B8dselsnummer
const controlNumber1List = [3, 7, 6, 1, 8, 9, 4, 5, 2]
const controlNumber2List = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]

const NIN_LENGTH = 11

/**
 * Function to get the checksum to be used to validate national identity numbers (NIN)
 *
 * @param nin the national identity number to get the checksum from
 * @param controlNumberList the list of control numbers
 */
const getCheckSum = (nin: string, controlNumberList: number[]) => {
	let checkSum = 0

	controlNumberList.forEach(
		(num: number, pos: number) =>
			(checkSum += parseInt(nin.charAt(pos), 10) * num)
	)
	return checkSum
}

/**
 * Function to validate if a national identity number is valid or not
 * @param nin
 */
export const isValidNIN = (nin: string | null) => {
	if (nin === null || nin.length !== NIN_LENGTH) return false

	let controlNumber1 =
		NIN_LENGTH - (getCheckSum(nin, controlNumber1List) % NIN_LENGTH)
	if (controlNumber1 === NIN_LENGTH) controlNumber1 = 0

	let controlNumber2 =
		NIN_LENGTH - (getCheckSum(nin, controlNumber2List) % NIN_LENGTH)
	if (controlNumber2 === NIN_LENGTH) controlNumber2 = 0

	return (
		controlNumber1 === parseInt(nin.charAt(9), 10) &&
		controlNumber2 === parseInt(nin.charAt(10), 10)
	)
}
