import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getUuidByNIN } from 'api/services/BeneficiaryService'

import { Button as BaseButton } from 'components/private-components/button/Button'
import { Text as BaseText } from 'components/text/Text'
import { Icon as BaseIcon } from 'components/icon/Icon'
import { Spinner } from 'components/Spinner'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'
import { buttonColorLight, buttonStyleLight } from 'styles/button'

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--color-box);
	border-radius: 10px;
	min-height: 120px;
	width: 100%;
	padding-block: 20px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 800px;
		padding: 20px;
		min-height: 240px;
		margin-left: 12%;
		margin-right: 12%;
	}
`

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-top: 30px;
		width: auto;
		min-width: 450px;
	}
`

const InputAndButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding-inline: 20px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		flex-direction: row;
	}
`

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`

const Icon = styled(BaseIcon)`
	position: absolute;
	color: var(--color-main-blue_green);
	min-height: 1.75rem;
	min-width: 1.75rem;
	padding: 2px;
	margin-left: 10px;
	box-sizing: border-box;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		min-height: 2rem;
		min-width: 2rem;
	}
`

const InputField = styled.input`
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	border-radius: 5px;
	border: solid 1px var(--color-main-blue_green);
	box-sizing: border-box;
	padding-left: 3rem;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;

	&::placeholder {
		font-size: var(--font-size-button-text);
	}

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		padding-left: 3.2rem;
		min-width: 300px;
		min-height: 50px;
	}
`

const ErrorWrapper = styled.div`
	display: flex;
	padding-top: 30px;
	padding-bottom: 5px;
	padding-inline: 20px;
	min-height: 60px;
`

const ErrorIcon = styled(BaseIcon)`
	height: 25px;
	width: 50px;
	font-size: 25px;
	color: var(--color-error);
	margin-right: 5px;
`

const ErrorText = styled(BaseText)`
	color: var(--color-error);
	text-align: left;
`

const ButtonWrapper = styled.div`
	width: 100%;
	margin-top: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-top: 0;
	}
`

const Button = styled(BaseButton)`
	${buttonStyleLight}
	width: 100%;

	${(props) =>
		props?.disabled &&
		css`
			opacity: 0.5;
			&:hover {
				${buttonColorLight}
			}
		`};

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: auto;
		min-width: 75px;
		min-height: 50px;
		margin-left: 10px;
		padding-left: 20px;
	}
`

const Label = styled.label`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-title2);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-normal);
	margin: 0;
`

interface InputTypes {
	nationalIdentityNumber: string
}

interface Props {
	onSuccess: (data: unknown, nin: string) => void
	onError: () => void
}

const IS_DIGIT = /^[\d]*$/
const IS_DIGIT_OR_WHITESPACE = /^[\d\s]*$/

/**
 * Component to display a search box for national identity number
 *
 * @param props.onSuccess Function to handle the successfully search on a NIN
 * @param props.onError function to handle errors
 */
export const NINSearchBox = ({ onSuccess, onError }: Props) => {
	const { t } = useTranslation('public')

	const {
		formState: { errors },
		handleSubmit,
		setError,
		watch,
		control
	} = useForm<InputTypes>()

	// To handle the errors
	const hasError = Object.values(errors).length > 0

	// To handle the loading
	const [isLoading, setIsLoading] = useState(false)

	const setCustomError = (message: string) => {
		setError('nationalIdentityNumber', { type: 'custom', message })
	}

	const onSubmit = (data: unknown) => {
		// Convert the unknown data to its correct type
		const inputNIN: string = (data as { nationalIdentityNumber: string })
			.nationalIdentityNumber

		setIsLoading(true)

		// Get the beneficiary
		getUuidByNIN(inputNIN)
			.then((res) => {
				setIsLoading(false)
				if (res.data.uuid) {
					onSuccess(res.data, inputNIN)
				} else {
					setCustomError(t('nin.errors.backend.nonexistant'))
				}
			})
			.catch((err) => {
				onError()
				setIsLoading(false)
				if (err.response.status === 404) {
					return setCustomError(t('nin.errors.backend.nonexistant2'))
				}
				if (err.response.status === 409) {
					return setCustomError(t('nin.errors.backend.uploadLimit'))
				}
				return setCustomError(t('nin.errors.backend.generic'))
			})
	}

	const getErrorMessage = (error: FieldError | undefined) => {
		if (!error) return
		const { type = '', message = '' } = error ?? {}
		if (type === 'required') return t('nin.errors.validation.required')
		if (type === 'minLength') return t('nin.errors.validation.short')
		if (type === 'pattern') return t('nin.errors.validation.type')
		if (type === 'custom') {
			return message ?? ''
		}
	}

	const ninValue = watch('nationalIdentityNumber')

	//TODO - Fix spinner so it looks like the design
	const displayContent = () => {
		if (isLoading) {
			return <Spinner />
		}
		return (
			<>
				<Label htmlFor="search">{t('nin.title')}</Label>
				<FormContainer
					onSubmit={handleSubmit(onSubmit)}
					id="ninForm"
					title="National identity number search box"
				>
					<InputAndButtonWrapper>
						<InputWrapper>
							<Icon name="PersonCircle" />
							<Controller
								name="nationalIdentityNumber"
								defaultValue=""
								control={control}
								rules={{
									required: true,
									minLength: 11,
									maxLength: 11,
									pattern: {
										value: /^\d*$/,
										message: t('nin.errors.validation.type')
									}
								}}
								render={({ field: { value, onChange, name } }) => (
									<InputField
										id="search"
										name={name}
										value={value}
										{...(hasError
											? { 'aria-errormessage': 'input_error' }
											: {})}
										placeholder={t('nin.placeholder')}
										onChange={(e) => {
											if (
												IS_DIGIT_OR_WHITESPACE.test(e.target.value)
											) {
												const trimmedValue = e.target.value.replace(
													/\s/g,
													''
												)
												if (trimmedValue.length <= 11) {
													onChange(trimmedValue)
												}
											}
											if (
												IS_DIGIT.test(e.target.value) &&
												e.target.value.length <= 11
											)
												onChange(e.target.value)
										}}
									/>
								)}
							/>
						</InputWrapper>
						<ButtonWrapper>
							<Button
								type="submit"
								text={t('nin.button')}
								rightIcon={{ name: 'ArrowRight' }}
								fillWidth
								disabled={!ninValue}
							/>
						</ButtonWrapper>
					</InputAndButtonWrapper>
				</FormContainer>
				{hasError && (
					<ErrorWrapper id="input_error">
						<ErrorIcon name="ErrorCircle" />
						<ErrorText role="alert">
							{getErrorMessage(errors?.nationalIdentityNumber)}
						</ErrorText>
					</ErrorWrapper>
				)}
			</>
		)
	}

	return <Wrapper>{displayContent()}</Wrapper>
}
