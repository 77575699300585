import styled, { css } from 'styled-components'

import { IconButton as BaseIconButton } from 'components/icon/IconButton'

const commonStyle = css`
	width: 35px;
	height: 35px;
	font-size: var(--font-size-text);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-bold);
	border: solid 1px var(--color-main-dark_blue);
	color: var(--color-main-dark_blue);
	background-color: var(--color-white);
`

const commonButtonHover = css`
	&:hover {
		background-color: var(--color-main-turquoise);
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 35px;
`

const IconButtonLeft = styled(BaseIconButton)`
	${commonStyle};
	border-bottom-left-radius: 10px;
	border-top-left-radius: 10px;
	border-right: none;

	${commonButtonHover};

	&:focus {
		border-radius: 0;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const IconButtonRight = styled(BaseIconButton)`
	${commonStyle};
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;

	${commonButtonHover};

	&:focus {
		border-radius: 0;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const NumberButton = styled.button`
	${commonStyle};
	border-right: none;
	cursor: pointer;

	${commonButtonHover};

	&:focus {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const CurrentPageBox = styled.div`
	${commonStyle};
	background-color: var(--color-main-blue_green);
	color: var(--color-white);
	border-right: none;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 5px;
`

const DotText = styled.div`
	${commonStyle};
	border-right: none;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 5px;
`

interface Props {
	totalPages: number
	currentPageNumber: number
	onClickPageNumber: (pageNumber: number) => void
}

/**
 * Navigationbuttons to be used for selecting pages in a list/table.
 *
 * @param props.totalPages the number of total pages
 * @param props.currentPageNumber	the page number of the current page
 * @param props.onClickPageNumber function to handle the click of a page number
 * @constructor
 */
export const NavigationButtons = ({
	totalPages,
	currentPageNumber,
	onClickPageNumber
}: Props) => {
	/**
	 * Displays the left side of current page number
	 */
	const displayLeftSide = (
		prevPageNumber: number,
		diffBetweenPrevAndStart: number
	) => {
		return (
			<>
				{diffBetweenPrevAndStart > 1 && <DotText>...</DotText>}
				{prevPageNumber > 1 && (
					<NumberButton onClick={() => onClickPageNumber(prevPageNumber)}>
						{prevPageNumber}
					</NumberButton>
				)}
			</>
		)
	}

	/**
	 * Displays the right side of current page number
	 */
	const displayRightSide = (
		nextPageNumber: number,
		diffBetweenNextAndLast: number
	) => {
		return (
			<>
				{nextPageNumber < totalPages && (
					<NumberButton onClick={() => onClickPageNumber(nextPageNumber)}>
						{nextPageNumber}
					</NumberButton>
				)}
				{diffBetweenNextAndLast > 1 && <DotText>...</DotText>}
			</>
		)
	}

	/**
	 * Displays and handles the logic for which page numbers to display
	 */
	const displayNumberButtons = () => {
		const prevPageNumber = currentPageNumber - 1
		const nextPageNumber = currentPageNumber + 1
		const diffBetweenPrevAndStart = prevPageNumber - 1
		const diffBetweenNextAndLast = totalPages - nextPageNumber

		return (
			<>
				{currentPageNumber !== 1 && (
					<NumberButton onClick={() => onClickPageNumber(1)}>
						1
					</NumberButton>
				)}
				{displayLeftSide(prevPageNumber, diffBetweenPrevAndStart)}
				<CurrentPageBox>{currentPageNumber}</CurrentPageBox>
				{displayRightSide(nextPageNumber, diffBetweenNextAndLast)}
				{currentPageNumber !== totalPages && (
					<NumberButton onClick={() => onClickPageNumber(totalPages)}>
						{totalPages}
					</NumberButton>
				)}
			</>
		)
	}

	const onClickPrevious = () => {
		const prevNo = currentPageNumber - 1

		if (prevNo < 1) return null
		return onClickPageNumber(prevNo)
	}

	const onClickNext = () => {
		const nextNo = currentPageNumber + 1

		if (nextNo > totalPages) return null
		return onClickPageNumber(nextNo)
	}

	return (
		<Wrapper>
			<IconButtonLeft
				icon="NavigationBack"
				screenReaderText="forrige side"
				onClick={onClickPrevious}
			/>
			{displayNumberButtons()}
			<IconButtonRight
				icon="NavigationNext"
				screenReaderText="neste side"
				onClick={onClickNext}
			/>
		</Wrapper>
	)
}
