import { CommunicationType } from '../types/CommunicationType'

/**
 * Function to get the date from a list of communication types, at a requested
 * position when the list of communications are sorted
 *
 * @param communication - the list of communications
 * @param pos			- the position in the sorted list we want to find
 */
export const getSortedDateAtPosition = (
	communication: CommunicationType[],
	pos: number
): Date =>
	communication.sort((a: CommunicationType, b: CommunicationType) => {
		return a.createdOn > b.createdOn
			? Date.parse(a.createdOn.toDateString())
			: Date.parse(b.createdOn.toDateString())
	})[pos].createdOn
