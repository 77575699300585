import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

import { ButtonText as BaseButtonText } from 'components/company-components/text/ButtonText'
import { Icon, IconProps } from 'components/icon/Icon'

import { companyButtonStyle } from 'styles/button'

const ButtonIcon = styled(BaseButtonText)`
	color: inherit;
`

const ButtonText = styled(BaseButtonText)`
	margin-left: 5px;
	color: inherit;
`

interface IStyledButton {
	fillWidth?: boolean
}

export const StyledAButton = styled.a<IStyledButton>`
	${companyButtonStyle}

	${(props) =>
		props.fillWidth
			? css`
					width: 100%;
			  `
			: css`
					width: auto;
			  `}

	&:hover {
		${({ color }) =>
			color === 'blue'
				? css`
						background-color: var(--color-main-blue_green);
						color: var(--color-white);
				  `
				: css`
						background-color: var(--color-yellow);
						color: var(--color-black);
				  `};
	}

	&:focus {
		background-color: var(--color-main-dark_blue);
		color: var(--color-white);
	}
`

interface Props extends React.ComponentPropsWithoutRef<'a'> {
	text?: string
	hoverColor?: string
	icon?: IconProps
	fillWidth?: boolean
	openInNewTab?: boolean
	children?: ReactNode
}

/**
 * AButton component
 *
 * @param props.text The text on the button
 * @param props.icon Icon of the button
 * @param props.fillWidth To control the width of the button
 * @param props.hoverColor To control the hover color - default is yellow
 * @param props.openInNewTab if the href should open in new tab
 */
export const AButton = ({
	text,
	icon,
	fillWidth,
	hoverColor,
	openInNewTab,
	children,
	...rest
}: Props) => {
	// To control the button colours
	const [isActive, setIsActive] = useState(false)

	const getIsWhite = (): boolean => {
		return isActive && hoverColor === 'blue'
	}

	return (
		<StyledAButton
			onMouseEnter={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
			color={hoverColor}
			fillWidth={fillWidth}
			{...(openInNewTab
				? { target: '_blank', rel: 'noopener noreferrer' }
				: {})}
			{...rest}
		>
			{text ? (
				<>
					{icon && (
						<ButtonIcon color={getIsWhite() ? 'white' : undefined}>
							<Icon name={icon.name} />
						</ButtonIcon>
					)}
					<ButtonText color={getIsWhite() ? 'white' : undefined}>
						{text}
					</ButtonText>
				</>
			) : (
				children
			)}
		</StyledAButton>
	)
}
