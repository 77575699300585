import { backend } from 'api/backend'

/**
 * Create bank id session
 *
 * @param uuid uuid of the request to start the bank id session
 */
export const postCreateBankIDSession = (uuid: string) =>
	backend.post('/startbankidsession', {
		uuid: uuid
	})

/**
 * Function to fetch details about a BankId session
 *
 * @param handoverId the handover id of the session
 */
export const getBankIdSessionDetails = (handoverId: string) =>
	backend.get('/bankidsession/' + handoverId)
