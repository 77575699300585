/**
 * URL to the page where you can read more about the BankId application
 */
export const LES_MER_OM_BANKID_APPEN_URL =
	'https://www.bankid.no/privat/bankid-app/'

/**
 * URL to where you can read more about the ID-check in the BankId application
 */
export const LES_MER_OM_BANKID_ID_SJEKK_URL =
	'https://www.bankid.no/privat/los-mitt-bankid-problem/idsjekk-pass/'
