import React, { memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Icon as BaseIcon } from 'components/icon/Icon'
import { Text as BaseText } from 'components/text/Text'
import { IconButton as BaseIconButton } from 'components/icon/IconButton'

const Wrapper = styled.div`
	margin-left: 5px;
	margin-right: 5px;
`

const FileWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--color-white);
	padding: 10px;
	border-radius: 5px;
	margin-block: 5px;

	&:hover {
		background-color: var(--color-grey_dark);

		> .iconButton {
			color: var(--color-black);
		}
	}

	&:focus-within {
		background-color: var(--color-grey_dark);
	}
`

const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
`

const Text = styled(BaseText)`
	margin-left: 10px;
`

const Icon = styled(BaseIcon)`
	font-size: 25px;
`

const IconButton = styled(BaseIconButton)`
	color: var(--color-main-blue_green);

	&:focus {
		border-radius: 5px;
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 2px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

interface Props {
	files: File[]
	onClickDeleteFile: (index: number) => void
}

/**
 * Displays the file(s) selected by the user
 *
 * @param props.files the files to display
 * @param props.onClickDeleteFile function to remove the file
 */
export const FileList = memo(({ files, onClickDeleteFile }: Props) => {
	const { t } = useTranslation('common')
	/**
	 * Function to map the files to correct format
	 */
	const displayFiles = files.map((file: File, i: number) => (
		<FileWrapper
			className="fileWrapper"
			key={file.name + '_' + file.lastModified}
		>
			<LeftWrapper>
				<Icon name="PDFFile" />
				<Text>{file.name}</Text>
			</LeftWrapper>
			<IconButton
				icon="Trash"
				className="iconButton"
				screenReaderText={t('components.fileList.delete')}
				onClick={() => onClickDeleteFile(i)}
			/>
		</FileWrapper>
	))

	return <Wrapper>{displayFiles}</Wrapper>
})
