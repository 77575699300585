import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/common/Modal'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Button as BaseButton } from 'components/private-components/button/Button'

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-block: 60px;
`

const HeaderText2 = styled(BaseHeaderText2)`
	text-align: center;
	margin-bottom: 40px;
	padding-inline: 30px;
`

const CancelButton = styled(BaseButton)`
	width: 320px;
	margin-bottom: 20px;
	padding-block: 7px;
`

const NewButton = styled(BaseButton)`
	width: 270px;
	padding-block: 7px;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	onClickNewCode: () => void
}

/**
 * Displays a modal to make sure that the user really wants to start the session over again.
 *
 * @param props.isOpen flag for if modal is open
 * @param props.onClose function to close modal
 * @param props.onClickNewCode function to handle click of new QR-code
 */
export const NewQRCodeModal = ({ isOpen, onClose, onClickNewCode }: Props) => {
	const { t } = useTranslation('private')

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<Content>
				<HeaderText2>{t('qr.modal.text')}</HeaderText2>
				<CancelButton
					text={t('qr.modal.cancel')}
					onClick={onClose}
					color="white"
				/>
				<NewButton text={t('qr.modal.new')} onClick={onClickNewCode} />
			</Content>
		</Modal>
	)
}
