import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Text as BaseText } from 'components/text/Text'
import { Icon as BaseIcon } from 'components/icon/Icon'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

const FooterContentWrapper = styled.div`
	padding-block: 15px;
	padding-inline: 40px;
	display: flex;
	justify-content: space-between;
	background-color: var(--color-main-dark_blue);
	width: 100%;

	@media only screen and (max-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: none;
	}
`

const SubHeaderText = styled(BaseSubHeaderText)`
	font-weight: var(--font-weight-almost-bold);
	font-size: var(--font-size-field-text);
`

const Text = styled(BaseText)`
	font-weight: var(--font-weight-normal);
	font-family: var(--font-family-opensans);
	font-size: var(--font-size-field-text);
	line-height: 22px;
`

const Icon = styled(BaseIcon)`
	color: var(--color-white);
	font-size: 22px;
	margin-right: 10px;
`

const ContactWrapper = styled.div`
	margin-top: 10px;
`

const AText = styled.a`
	color: var(--color-white);
	text-decoration: none;
`

const RightSideWrapper = styled.div`
	display: flex;
`

const Bold = styled.strong`
	font-weight: var(--font-weight-almost-bold);
`

export const FooterContent = () => {
	const { t } = useTranslation('common')

	const email = process.env.REACT_APP_CONTACT_EMAIL ?? ''
	const phone = process.env.REACT_APP_CONTACT_PHONE ?? ''

	return (
		<FooterContentWrapper>
			<div>
				<SubHeaderText as="h1" color="white">
					{t('footer.title')}
				</SubHeaderText>
				<ContactWrapper>
					<Text color="white">
						<Trans i18nKey="common:footer.phone">
							Telefon:
							<AText href={`tel:${phone.split(' ').join('')}`}>
								{{ phone } as any}
							</AText>
						</Trans>
					</Text>
					<Text color="white">
						<Trans i18nKey="common:footer.email">
							Epost:
							<AText href={`mailto:${email}`}>{{ email } as any}</AText>
						</Trans>
					</Text>
				</ContactWrapper>
			</div>
			<RightSideWrapper>
				<Icon name="CustomerService" />
				<div>
					<Text color="white">
						<Bold>{t('footer.customerService')}</Bold>
					</Text>
					<ContactWrapper>
						<Text color="white">{t('footer.openingHours')}</Text>
					</ContactWrapper>
				</div>
			</RightSideWrapper>
		</FooterContentWrapper>
	)
}
