import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import loginPageImage from 'images/login_page_image_full_1920.jpg'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { Layout } from 'components/company-components/layout'

import { LoginButton } from './LoginButton'
import { useIsAuthenticated } from '@azure/msal-react'
import { useOnce } from 'hooks/useOnce'
import { useNavigate } from 'react-router-dom'
import { OPPGAVEBEHANDLING_PATH } from 'navigation/navigationURLs'

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--color-background-login_page);
	background-image: url(${loginPageImage});
`

const LoginBox = styled.div`
	background-color: var(--color-box);
	border-radius: 10px;
	width: 700px;
	max-width: 45%;
	padding: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 30px;
	text-align: center;
`

/**
 * Login page, triggers a login window using Active Directory
 */
export const LoginPage = () => {
	const { t } = useTranslation('company')
	const navigate = useNavigate()
	const isLoggedIn = useIsAuthenticated()

	useOnce(() => {
		if (isLoggedIn) navigate(OPPGAVEBEHANDLING_PATH)
	})
	return (
		<Layout>
			<Wrapper>
				<LoginBox>
					<HeaderText1>{t('login.title')}</HeaderText1>
					<LoginButton />
				</LoginBox>
			</Wrapper>
		</Layout>
	)
}
