import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { buttonColorDark, buttonColorWhite } from 'styles/button'
import { SR_ONLY } from 'styles'

const Wrapper = styled.div`
	display: flex;
	min-width: 300px;
	justify-content: space-between;
`

const Label = styled.label`
	border: none;
	text-decoration: none;
	padding-left: 20px;
	padding-right: 20px;
	min-width: 120px;
	max-width: 100%;
	padding-block: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0.1px 0.5px 4px rgb(0, 0, 0, 25%);
	${buttonColorWhite}
	border: 1px solid var(--color-black);
	font-size: var(--font-size-button-text);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-semi_bold);
`

const Input = styled.input`
	${SR_ONLY}
	&:checked + ${Label} {
		${buttonColorDark}
	}
	&:hover + ${Label} {
		background-color: var(--color-main-turquoise);
	}

	&:focus + ${Label} {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:not(:focus-visible) + ${Label} {
		outline: 0;
	}

	&:focus-visible + ${Label} {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}
`

interface Props {
	isValid: boolean | undefined
	setIsValid: (v: boolean) => void
	color?: string
}

/**
 *
 * @param props.isValid if the button is valid
 * @param props.setIsValid handle click of the valid button
 */
export const ValidationButtons = ({ isValid, setIsValid, ...rest }: Props) => {
	const { t } = useTranslation('company')

	return (
		<Wrapper {...rest}>
			<div>
				<Input
					id="invalid"
					type="radio"
					name="status"
					onChange={() => setIsValid(false)}
					color="blue"
				/>
				<Label htmlFor="invalid">
					{t('dash.validate.buttons.invalid')}
				</Label>
			</div>
			<div>
				<Input
					id="valid"
					type="radio"
					name="status"
					onChange={() => setIsValid(true)}
					color="blue"
				/>
				<Label htmlFor="valid">{t('dash.validate.buttons.valid')}</Label>
			</div>
		</Wrapper>
	)
}
