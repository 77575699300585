import { ReactNode } from 'react'
import styled from 'styled-components'

import { Text as BaseText } from 'components/text/Text'

const StyledTableData = styled.td`
	padding-block: 10px;
`

const Text = styled(BaseText)`
	font-weight: var(--font-weight-light);
`

interface Props {
	text?: string
	children?: ReactNode
}

/**
 * Component to display table data
 *
 * @param text text to display
 * @param children React children
 */
export const TableData = ({ text, children, ...rest }: Props) => {
	return (
		<StyledTableData {...rest}>
			{text ? <Text>{text}</Text> : children}
		</StyledTableData>
	)
}
