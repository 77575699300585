import React, { useState } from 'react'
import styled from 'styled-components'
import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import { BankIDSessionType, BankIDType } from 'types/BankIDType'

import successImage from 'images/BankIDSuccess.svg'

import { Text as BaseText } from 'components/text/Text'
import { Button as BaseButton } from 'components/private-components/button/Button'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Spinner } from 'components/Spinner'
import { NewQRCodeModal } from './NewQRCodeModal'

const BoxWrapper = styled.div`
	background-color: var(--color-box);
	width: 500px;
	max-width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
`

interface IContentWrapper {
	isQrCode: boolean
}

const ContentWrapper = styled.div<IContentWrapper>`
	width: ${(props) => (props.isQrCode ? '200px' : '400px')};
	height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 2vh;
`

const Button = styled(BaseButton)`
	padding-inline: 50px;
	padding-block: 7px;
`

const ButtonWrapper = styled.div``

const CounterText = styled(BaseText)``

const CounterWrapper = styled.div`
	margin-bottom: 30px;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	text-align: center;
	white-space: pre-line;
`

const BankIDErrorText = styled(BaseText)`
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: center;
	color: var(--color-error);
`

const Image = styled.img`
	min-height: 200px;
	max-height: 100%;
`

interface Props {
	bankIdDetails: BankIDType
	isLoading: boolean
	errorText?: string
	onClickButton: () => void
	countDownText: string
	bankIdSessionDetails: BankIDSessionType
}

/**
 * Component to display the QR code, or error messages
 *
 * @param props.bankIdDetails Details about the bank id session
 * @param props.isLoading To handle if it should display loading
 * @param props.errorText Text to display instead of contents
 * @param props.onClickButton To request a new QR code
 * @param props.countDownText The countdown text
 * @param props.bankIdSessionDetails the state of the bank id session
 */
export const QRCodeBox = ({
	bankIdDetails,
	isLoading,
	errorText,
	onClickButton,
	countDownText,
	bankIdSessionDetails
}: Props) => {
	const { t } = useTranslation('private')

	const [modalOpen, setModalOpen] = useState(false)

	const processIsStarted =
		bankIdSessionDetails.state === 'started' ||
		bankIdSessionDetails.state === 'mrz_completed' ||
		bankIdSessionDetails.state === 'nfc_completed'

	const displayBoxContent = () => {
		if (isLoading) {
			return <Spinner title={t('qr.box.loading')} />
		}
		if (errorText || bankIdDetails.intentUrl === '') {
			return (
				<BankIDErrorText>
					{errorText ?? t('qr.box.errors.bankID')}
				</BankIDErrorText>
			)
		}
		if (bankIdSessionDetails.state === 'created') {
			return (
				<QRCodeSVG
					value={bankIdDetails.intentUrl}
					width={200}
					height={200}
				/>
			)
		}
		if (processIsStarted) {
			return <SubHeaderText>{t('qr.box.state.started')}</SubHeaderText>
		}
		if (bankIdSessionDetails.state === 'face_match_retry') {
			return <SubHeaderText>{t('qr.box.state.faceIDError')}</SubHeaderText>
		}
		if (bankIdSessionDetails.state === 'completed') {
			return <Image src={successImage} alt={t('qr.box.states.completed')} />
		}
		if (bankIdSessionDetails.state === 'failed') {
			return <SubHeaderText>{t('qr.box.state.failed')}</SubHeaderText>
		}
		if (
			bankIdSessionDetails.state === 'expired' ||
			countDownText === '00:00'
		) {
			return <SubHeaderText>{t('qr.box.state.expired')}</SubHeaderText>
		}
		return null
	}

	/**
	 * Hide counter text when the state is anything else than created
	 */
	const displayCounterTextOrNull = () => {
		if (
			bankIdSessionDetails.state === 'created' &&
			countDownText !== '00:00' &&
			errorText !== t('qr.errors.generic')
		) {
			return <CounterText>{countDownText}</CounterText>
		}
		return null
	}

	/**
	 * If the process is still ongoing, display the modal, else get new QR-code
	 */
	const onClickNewQRCode = () => {
		if (
			bankIdSessionDetails.state === 'face_match_retry' ||
			processIsStarted
		) {
			setModalOpen(true)
		} else {
			onClickButton()
		}
	}

	/**
	 * Hide button when state is completed
	 */
	const displayButtonOrNull = () => {
		if (bankIdSessionDetails.state === 'completed') {
			return null
		}
		return <Button text={t('qr.box.getNewCode')} onClick={onClickNewQRCode} />
	}

	return (
		<BoxWrapper>
			<ContentWrapper
				isQrCode={
					bankIdSessionDetails.state === 'created' &&
					errorText !== t('qr.errors.generic')
				}
			>
				{displayBoxContent()}
			</ContentWrapper>
			<CounterWrapper>{displayCounterTextOrNull()}</CounterWrapper>
			<ButtonWrapper>{displayButtonOrNull()}</ButtonWrapper>
			<NewQRCodeModal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				onClickNewCode={() => {
					onClickButton()
					setModalOpen(false)
				}}
			/>
		</BoxWrapper>
	)
}
