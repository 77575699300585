import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Text } from 'components/text/Text'
import { HeaderText1 } from 'components/text/HeaderText1'
import { Icon, IconProps } from 'components/icon/Icon'

import { COLOR_MAIN_TURQUOISE } from 'styles/baseColors'

const Wrapper = styled.button`
	width: 100px;
	height: 100px;
	border: solid var(--color-black);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 1px 1px 4px rgb(0, 0, 0, 25%);
	background-color: ${(props) => props?.color ?? 'var(--color-white)'};
	cursor: pointer;

	&:hover {
		background-color: var(--color-yellow);
	}

	&:focus {
		outline: 2px solid var(--color-main-dark_blue);
		outline-offset: 4px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}
`

const SelectedIconWrapper = styled.div`
	color: var(--color-black);
	font-size: var(--font-size-title2);
	position: absolute;
	margin-left: 65px;
	margin-bottom: 65px;
`

const ChannelIcon = styled(Icon)`
	font-size: 38px;
`

interface Props {
	channelName: 'sms' | 'email' | 'post'
	channelIcon: IconProps
	selectedChannels: ('sms' | 'email' | 'post')[]
	setSelectedChannels: React.Dispatch<
		React.SetStateAction<('sms' | 'email' | 'post')[]>
	>
	isOnlyOneBox: boolean
}

/**
 * Component to display a box with channel name and icon
 *
 * @param props.channelName The name of the communication channel
 * @param props.channelIcon The icon to represent the communication type
 * @param props.selectedChannels The selected channels
 * @param props.setSelectedChannels Function to update the array of selected channels
 * @param props.isOnlyOneBox To handle the selecting when it is only one box
 */
export const ChannelBox = ({
	channelName,
	channelIcon,
	selectedChannels,
	setSelectedChannels,
	isOnlyOneBox
}: Props) => {
	const [isSelected, setIsSelected] = useState(isOnlyOneBox)

	const { t } = useTranslation('company')

	/**
	 * Function to handle the on click of a box
	 */
	const onClickBox = () => {
		if (!isOnlyOneBox) {
			// If it is selected, remove from list, else add it
			if (isSelected) {
				setSelectedChannels(
					selectedChannels.filter((s) => s !== channelName)
				)
			} else {
				setSelectedChannels([...selectedChannels, channelName])
			}
			setIsSelected(!isSelected)
		} else {
			// Handle error
			toast.error(
				<div className="dismissToast">
					{t('beneficiary.cert.send.channel.error')}
				</div>
			)
		}
	}

	return (
		<Wrapper
			onClick={onClickBox}
			color={isSelected ? COLOR_MAIN_TURQUOISE : ''}
		>
			{isSelected && (
				<SelectedIconWrapper>
					<Icon name="CheckCircle" />{' '}
				</SelectedIconWrapper>
			)}
			<HeaderText1>
				<ChannelIcon name={channelIcon.name} />
			</HeaderText1>
			<Text>
				{t('beneficiary.cert.send.channel.name', { context: channelName })}
			</Text>
		</Wrapper>
	)
}
