import { BulkFileDetailsType } from 'types/BulkFileDetailsType'

/**
 * Gets the details about how many occurrences in a uploaded bulk files that was ok or not ok.
 *
 * @param blobPart the blobparts containing the file
 * @return object of BulkFileDetailsType
 */
export const getFileDetails = (blobPart: BufferSource): BulkFileDetailsType => {
	const enc = new TextDecoder('utf-8')

	const blobAsString = enc.decode(blobPart)

	const numberOfOK = (blobAsString.match(/,OK,/g) || []).length
	const numberOfNotOK = (blobAsString.match(/Not OK/g) || []).length
	const total = numberOfOK + numberOfNotOK

	return {
		bulksOK: numberOfOK,
		bulksNotOK: numberOfNotOK,
		totalBulks: total
	}
}
