//company
export const BASE_COMPANY_PATH = '/bedrift'
export const OPPGAVEBEHANDLING_PATH = '/bedrift/oppgavebehandling'
export const YTELSESMOTTAKER_PATH = '/bedrift/ytelsesmottaker'
export const COMPANYLISTPAGE_PATH = '/bedrift/selskap'

export const LOGIN_PATH = '/bedrift/login'

// Private
export const ELECTRONIC_PROCESS_START_PAGE_PATH = '/privat'
export const WALK_THROUGH_PAGE_PATH = '/privat/gjennomgang'
export const QRCODE_PAGE_PATH = '/privat/bankid'
export const SUCCESSFULLY_UPLOADED_PATH = '/privat/suksess'
export const FAILED_TO_UPLOAD_PATH = '/privat/feilet'
