import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal as BaseModal } from 'components/common/Modal'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { ReceiptButton } from 'components/FileUploader/BulkFileUploader/ReceiptButton'
import { ConfirmationModal } from 'components/common/ConfirmationModal'

import { RegisterBox } from './RegisterBox'
import { AddBulkBeneficiaries } from './AddBulkBeneficiaries'
import { AddSingleBeneficiary } from './AddSingleBeneficiary'

interface IModal {
	isSingle: boolean
}

const Modal = styled(BaseModal)<IModal>`
	width: ${(props) => (props.isSingle ? '1000px' : '800px')};
	max-width: calc(100% - 40px);
`

const ModalContent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 30px;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 80%;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	companyName: string
}

/**
 * Component to show the modal where a user can add single beneficiaries, or
 * upload multiple as a bulk
 *
 * @param props.isOpen if the modal is open
 * @param props.onClose function to handle on close
 * @param props.companyName name of the company
 */
export const AddModal = ({ isOpen, onClose, companyName }: Props) => {
	const [displayReceiptText, setDisplayReceiptText] = useState(false)
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
	const [isGoBack, setIsGoBack] = useState(false)

	const [viewType, setViewType] =
		useState<'start' | 'single' | 'bulk'>('start')

	const { t } = useTranslation('company')

	const reset = () => {
		setViewType('start')
		if (isGoBack) {
			setIsGoBack(false)
		} else {
			onClose()
		}
		setIsConfirmationOpen(false)
	}

	const displayBulkContent = () => {
		if (displayReceiptText) {
			return (
				<ReceiptButton
					text={t('dash.bulk.new.text')}
					buttonText={t('dash.bulk.new.button')}
					onStartAgain={() => setDisplayReceiptText(false)}
				/>
			)
		}
		return (
			<AddBulkBeneficiaries
				onSuccess={() => {
					setDisplayReceiptText(true)
				}}
			/>
		)
	}

	const displayContent = () => {
		if (viewType === 'single') {
			return (
				<>
					<HeaderText1>
						{t('dash.register.modal.single.page.title')}
					</HeaderText1>
					<AddSingleBeneficiary companyName={companyName} />
				</>
			)
		}
		if (viewType === 'bulk') {
			return (
				<>
					<HeaderText1>
						{t('dash.register.modal.multiple.page.title')}
					</HeaderText1>
					{displayBulkContent()}
				</>
			)
		}
		return (
			<>
				<HeaderText1>{t('dash.register.modal.title')}</HeaderText1>
				<Wrapper>
					<RegisterBox
						icon="Person"
						title={t('dash.register.modal.single.title')}
						text={t('dash.register.modal.single.text')}
						onClick={() => setViewType('single')}
					/>
					<RegisterBox
						icon="PersonsGroup"
						title={t('dash.register.modal.multiple.title')}
						text={t('dash.register.modal.multiple.text')}
						onClick={() => setViewType('bulk')}
					/>
				</Wrapper>
			</>
		)
	}

	const closeModal = () => {
		viewType === 'single' ? setIsConfirmationOpen(true) : reset()
	}

	const goBack = () => {
		if (viewType === 'single') {
			setIsConfirmationOpen(true)
			setIsGoBack(true)
		} else {
			setViewType('start')
		}
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={closeModal}
				onGoBack={
					viewType !== 'start' && !displayReceiptText
						? () => goBack()
						: undefined
				}
				shouldCloseOnOverlayClick={viewType !== 'single'}
				isSingle={viewType === 'single'}
			>
				<ModalContent>{displayContent()}</ModalContent>
			</Modal>
			<ConfirmationModal
				title={t('confirmationModal.add.title')}
				text={t('confirmationModal.add.text')}
				isOpen={isConfirmationOpen}
				onClose={() => setIsConfirmationOpen(false)}
				onYes={reset}
				onCancel={() => setIsConfirmationOpen(false)}
			/>
		</>
	)
}
