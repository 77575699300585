import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link as BaseLink } from 'react-router-dom'

import { CompanyListElementType } from 'types/CompanyListElementType'

import { displayFormatNationalIdentityNumber } from 'utils/displayFormatNationalIdentityNumber'

import { YTELSESMOTTAKER_PATH } from 'navigation/navigationURLs'

import { Icon as BaseIcon } from 'components/icon/Icon'
import { Text } from 'components/text/Text'

import { TableData } from './TableData'

const TableRowLink = styled(BaseLink)`
	display: table-row;
	margin-bottom: 40px;
	text-decoration: none;
	&:hover {
		> * {
			background-color: #00b7bd33;
			cursor: pointer;
		}
	}

	.left {
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
	}

	.right {
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}
`

const TableDataNin = styled(TableData)`
	padding-left: 20px;
`

const TableDataStatus = styled(TableData)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const IconWrapper = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const Icon = styled(BaseIcon)`
	color: var(--color-main-blue_green);
	font-size: 25px;
`

interface Props {
	element: CompanyListElementType
}

/**
 * Component to display each row with data in the table
 *
 * @param props.element the element whose data this row should display
 */
export const TableDataRow = ({ element, ...rest }: Props) => {
	const { t } = useTranslation('company')

	const displaysDataStatus = () => {
		if (element.status === 'valid') {
			return (
				<>
					<IconWrapper>
						<Icon name="ValidLC" />
					</IconWrapper>
					<Text>{t('companyList.list.table.statuses.valid')}</Text>
				</>
			)
		}
		if (element.status === 'invalid') {
			return (
				<>
					<IconWrapper>
						<Icon name="InvalidLC" />
					</IconWrapper>
					<Text>{t('companyList.list.table.statuses.invalid')}</Text>
				</>
			)
		}
		if (element.status === 'processStarted') {
			return (
				<>
					<IconWrapper>
						<Icon name="RequestSent" />
					</IconWrapper>
					<Text>{t('companyList.list.table.statuses.started')}</Text>
				</>
			)
		}
	}

	return (
		<>
			<TableRowLink
				to={`${YTELSESMOTTAKER_PATH}/${element.id}`}
				state={{
					nin: element.nationalIdentityNumber,
					from: 'list'
				}}
				{...rest}
			>
				<TableDataNin
					text={displayFormatNationalIdentityNumber(
						element.nationalIdentityNumber
					)}
					className="row left"
				/>
				<TableData className="row" text={element.name} />
				<TableDataStatus className="row right">
					{displaysDataStatus()}
				</TableDataStatus>
			</TableRowLink>
		</>
	)
}
