import styled from 'styled-components'

/**
 * Title component that contains the styling for a title 2 element.
 *
 * Color: #051D2E or #FFFFFF
 * Size: 22px
 * Weight: 400
 * Family: Open sans
 */
export const HeaderText2 = styled.h2`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-title2);
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-normal);
	margin: 0;
`
