import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useOnce } from 'hooks/useOnce'

import { getInvalidLifeCertificates } from 'api/services/LifeCertificateService'

import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Text as BaseText } from 'components/text/Text'
import { Spinner } from 'components/Spinner'

import { InvalidListElementType } from 'types/InvalidListElementType'

import { Table } from './Table'
import { NavigationButtons } from './NavigationButtons'
import { SelectRows } from './SelectRows'

const Wrapper = styled.div`
	margin-bottom: 40px;
	width: 100%;
`

const BodyWrapper = styled.div`
	background-color: var(--color-box);
	border-radius: 10px;
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0.5px 4px rgb(0, 0, 0, 25%);
`

const HeaderText2 = styled(BaseHeaderText2)`
	text-align: center;
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-normal);
	margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
`

const TopTextWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`

const TopTextWrapperRight = styled.div`
	display: flex;
	align-items: center;
`

const Text = styled(BaseText)`
	font-weight: var(--font-weight-light);
	margin-right: 20px;
`

const Label = styled.label`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-light);
	margin: 0;
	margin-right: 20px;
`

/**
 * Component to display the box around the list of invalid life certificates
 */
export const LCList = () => {
	// To show a spinner when loading, and flag to stop spinner if error
	const [showSpinner, setShowSpinner] = useState(false)
	const [error, setError] = useState(false)

	// To store the values needed to display the list
	const [invalidLCList, setInvalidLCList] =
		useState<InvalidListElementType[]>()
	const [totalElements, setTotalElements] = useState(0)

	// To update the list with new values
	const [pageSize, setPageSize] = useState<25 | 50 | 100>(25)

	// To handle the sort direction of the date, total pages, and current page
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
	const [totalPages, setTotalPages] = useState(0)
	const [currentPageNumber, setCurrentPageNumber] = useState(1)

	const { t } = useTranslation('company')

	/**
	 * Get the list of invalid LCs when page loads first time
	 */
	useOnce(() => {
		setShowSpinner(true)
		getInvalidLCsList(pageSize, sortDirection, currentPageNumber)
	})

	/**
	 * Update the list when inputs changes
	 */
	useEffect(() => {
		if (showSpinner) {
			getInvalidLCsList(pageSize, sortDirection, currentPageNumber)
		}
		// eslint-disable-next-line
	}, [showSpinner, pageSize])

	/**
	 * Function to get the list of invalid life certificates from backend
	 *
	 * @param pageSize the size of the page to load
	 * @param sortDir the sort direction
	 * @param pageNumber the page number to display
	 */
	const getInvalidLCsList = (
		pageSize: number,
		sortDir: 'asc' | 'desc',
		pageNumber: number
	) => {
		getInvalidLifeCertificates(pageSize, sortDir, pageNumber - 1)
			.then((res) => {
				setInvalidLCList(
					res.data.invalidLifeCertificates as InvalidListElementType[]
				)
				setTotalElements(res.data.totalElements as number)
				setTotalPages(res.data.totalPages as number)
				setShowSpinner(false)
			})
			.catch((err) => {
				setShowSpinner(false)
				setError(true)
				console.error(err)
				console.log({ err })
			})
	}

	/**
	 * Loads the table again with the given page size and page number
	 * @param size page size
	 * @param sortDir the direction
	 * @param number page number
	 */
	const loadNewTableContent = (
		size: number,
		sortDir: 'asc' | 'desc',
		number: number
	) => {
		setShowSpinner(true)
		getInvalidLCsList(size, sortDir, number)
	}

	/**
	 * Function to update the table elements when a change is made
	 */
	const updateTable = () => {
		loadNewTableContent(pageSize, sortDirection, currentPageNumber)
	}

	/**
	 * Function to update the table elements when the page size increases
	 * It also sets the current page number back to first page.
	 */
	const updateTableSize = (newPageSize: 25 | 50 | 100) => {
		setPageSize(newPageSize)
		setCurrentPageNumber(1)
		loadNewTableContent(newPageSize, sortDirection, 1)
	}

	/**
	 * Function to update the table elements when the page size increases
	 */
	const updateTableSorted = (dir: 'asc' | 'desc') => {
		setSortDirection(dir)
		loadNewTableContent(pageSize, dir, currentPageNumber)
	}

	/**
	 * Function to update the table elements when the page changes
	 */
	const updateTablePage = (newPageNumber: number) => {
		setCurrentPageNumber(newPageNumber)
		loadNewTableContent(pageSize, sortDirection, newPageNumber)
	}

	const displayContent = () => {
		if (error) {
			return <Text>{t('dash.list.error')}</Text>
		}
		if (invalidLCList === undefined || showSpinner) {
			return <Spinner />
		}
		if (invalidLCList.length > 0) {
			return (
				<>
					<TopTextWrapper>
						<Text>
							{t('dash.list.total', {
								count: totalElements,
								totalElements
							})}
						</Text>
						<TopTextWrapperRight>
							<Label htmlFor="selectPageSize">
								{t('dash.list.lines')}
							</Label>
							<SelectRows
								onSelect={(num) => {
									updateTableSize(num)
								}}
								currentPageSize={pageSize}
								id="selectPageSize"
							/>
						</TopTextWrapperRight>
					</TopTextWrapper>
					<Table
						list={invalidLCList}
						onClickSortHeader={(dir) => {
							updateTableSorted(dir)
						}}
						isSortAscending={sortDirection === 'asc'}
						onTableChange={updateTable}
					/>
					<ButtonWrapper>
						<NavigationButtons
							totalPages={totalPages}
							currentPageNumber={currentPageNumber}
							onClickPageNumber={(pageNumber: number) => {
								updateTablePage(pageNumber)
							}}
						/>
					</ButtonWrapper>
				</>
			)
		}
		return <Text>{t('dash.list.none')}</Text>
	}

	return (
		<Wrapper>
			<HeaderText2>{t('dash.list.title')}</HeaderText2>
			<BodyWrapper>{displayContent()}</BodyWrapper>
		</Wrapper>
	)
}
