import { backend } from 'api/backend'

/**
 * Function to post an uploaded LC to backend based on the uuid of the request
 *
 * @param uuid the uuid of the request
 * @param pdfs the pdfs to send
 */
export const postUploadFileByUUID = (uuid: string, pdfs: File[]) => {
	const formData = new FormData()

	formData.append('pdf', pdfs[0])
	if (pdfs.length === 2) {
		formData.append('pdfOptional', pdfs[1])
	}

	return backend.post('/lifecertificatepdf/' + uuid, formData, {
		maxRedirects: 0,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

/**
 * Gets a PDF file with a given company string from backend
 * @param company name of the company to print on the pdf, in string format
 */
export const fetchPaperCertificate = (company: string) =>
	backend.get(`/lifecertificatepdfschema/${company}`, {
		responseType: 'arraybuffer'
	})

/**
 * Function to set a life certificate as valid
 *
 * @param nin the national identity number of the beneficiary
 */
export const putValidateLC = (nin: string) => {
	return backend.put('/portal/validatelifecertificate', {
		nationalIdentityNumber: nin
	})
}

/**
 * Function to set a life certificate as invalid
 *
 * @param nin the national identity number of the beneficiary
 * @param comment the comment of why the LC was marked as invalid
 */
export const putInvalidateLC = (nin: string, comment: string) => {
	return backend.put('/portal/invalidatelifecertificate', {
		comment: comment,
		nationalIdentityNumber: nin
	})
}

/**
 * Function to get the list of invalid LCs from backend
 *
 * @param pageSize the size of the page - the number of elements to show
 * @param sortDirection - the direction to sort the elements
 * @param pageNumber the number of the page. There is no usage of this now, but it might be used in the future depending on future work
 */
export const getInvalidLifeCertificates = (
	pageSize: number = 25,
	sortDirection: 'asc' | 'desc' = 'asc',
	pageNumber: number = 0
) => {
	return backend.get('/portal/invalidlifecertificates', {
		params: { pageNumber, pageSize, sortDirection }
	})
}

/**
 * Deletes a life certificate by its beneficiary's national identity number. Can only be done by FNF.
 *
 * @param nin the national identity number of the beneficiary
 */
export const deleteLCByNin = (nin: string) => {
	return backend.delete(`/portal/lifecertificate/${nin}`)
}

/**
 * Function to post a LC with validation status and comment from FNF
 *
 * @param uuid uuid of the request
 * @param pdfs the pdfs to send
 * @param isValid false when from public page and true when its from company pages
 * @param comment the comment of why the LC was marked as invalid
 */
export const postUploadAndValidateFileAsFnf = (
	uuid: string,
	pdfs: File[],
	isValid: boolean,
	comment: string
) => {
	const formData = new FormData()

	formData.append('pdf', pdfs[0])
	if (pdfs.length === 2) {
		formData.append('pdfOptional', pdfs[1])
	}
	formData.append('isValid', String(isValid))
	formData.append('comment', comment)

	return backend.post('portal/lifecertificatepdf/' + uuid, formData, {
		maxRedirects: 0,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
