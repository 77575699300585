import React from 'react'
import styled from 'styled-components'
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useIsAuthenticated
} from '@azure/msal-react'
import { useTranslation } from 'react-i18next'

import { useGetCompanyNameOfUser } from 'hooks/useGetCompanyNameOfUser'
import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { TopOfPageContent } from 'components/company-components/utility/TopOfPageContent'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'

import { SearchBox } from './SearchBox'
import { BulkUpload } from './BulkUpload'
import { LCList } from './LCList'
import { AddBeneficiaryContent } from './AddBeneficiaryContent'
import { MyCompany } from './MyCompany'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 100px;
`

const MainContentWrapper = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const BodyWrapper = styled.div`
	width: 100%;
	padding-inline: 15%;
`

const FnfOrCompanyWrapper = styled.div`
	margin-top: 60px;
	display: flex;
	justify-content: center;
`

const RowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-top: 40px;
	margin-bottom: 30px;
`

const HeaderText2 = styled(BaseHeaderText2)`
	margin-bottom: 30px;
`

const SearchBoxWrapperFNF = styled.div`
	width: 100%;
`

const SmallWrapper = styled.div`
	width: 45%;
`

export const CompanyDashboardPage = () => {
	const { companyName } = useGetCompanyNameOfUser()
	const { t } = useTranslation('company')
	const isAuthenticated = useIsAuthenticated()
	const { isAdmin } = useIsAdminRole()

	const displayBody = () => {
		if (isAdmin === undefined) {
			return null
		}
		if (isAdmin) {
			return <LCList />
		} else {
			return (
				<RowWrapper>
					<SmallWrapper>
						<AddBeneficiaryContent companyName={companyName} />
					</SmallWrapper>
					<SmallWrapper>
						<MyCompany />
					</SmallWrapper>
				</RowWrapper>
			)
		}
	}

	const displaySearchBoxAndBulk = () => {
		if (isAdmin === undefined) return null
		if (isAdmin) {
			return (
				<SearchBoxWrapperFNF>
					<SearchBox />
				</SearchBoxWrapperFNF>
			)
		}
		return (
			<RowWrapper>
				<SmallWrapper>
					<SearchBox />
				</SmallWrapper>
				<SmallWrapper>
					<BulkUpload />
				</SmallWrapper>
			</RowWrapper>
		)
	}

	if (!isAuthenticated) return null

	return (
		<Wrapper>
			<TopOfPageContent />
			<MainContentWrapper>
				<HeaderText1>{t('dash.title')}</HeaderText1>
				<AuthenticatedTemplate>
					<BodyWrapper>
						{displaySearchBoxAndBulk()}
						<FnfOrCompanyWrapper>{displayBody()}</FnfOrCompanyWrapper>
					</BodyWrapper>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<HeaderText2>{t('dash.unauthorized')}</HeaderText2>
				</UnauthenticatedTemplate>
			</MainContentWrapper>
		</Wrapper>
	)
}
