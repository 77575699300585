import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { WALK_THROUGH_PAGE_PATH } from 'navigation/navigationURLs'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'
import { Button as BaseButton } from 'components/private-components/button/Button'
import { Text } from 'components/text/Text'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { isValidUUID } from 'utils/validators/isValidUUID'

const Wrapper = styled.div`
	text-align: center;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-top: 20px;
	margin-bottom: 30px;
`

const ButtonWrapper = styled.div`
	margin-top: 30px;
	display: flex;
	justify-content: center;
`

const Button = styled(BaseButton)`
	width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		padding-left: 100px;
		padding-right: 100px;
		width: auto;
	}
`

const HeaderText2 = styled(BaseHeaderText2).attrs({ as: 'p' })`
	white-space: pre-line;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-top: 5vh;
		line-height: 27px;
	}
`

/**
 * Page to show the electronic process
 * If there is company name and beneficiaryID in the URL, display the welcome message,
 * Otherwise, it displays a search box where the user can search for their national identity number
 *
 * @Example link to use: http://localhost:3000/privat/KLP/623b3b7c-4931-11ed-b878-0242ac120002
 */
export const ElectronicProcessStartPage = () => {
	const navigate = useNavigate()
	const { t } = useTranslation('private')

	// Get company name and uuid from the url data
	const { companyName, uuid } = useParams()

	// Check that the data exists and that the uuid is valid
	const shouldShowError = !companyName || !uuid || !isValidUUID(uuid)
	if (shouldShowError) {
		return (
			<Wrapper>
				<HeaderText1>{t('welcome.errors.title')}</HeaderText1>
				<Text>{t('welcome.errors.text')}</Text>
			</Wrapper>
		)
	}
	return (
		<Wrapper>
			<HeaderText1>{t('welcome.title')}</HeaderText1>
			<HeaderText2>
				<Trans i18nKey={'private:welcome.text'}>
					Hvis du er bosatt i utlandet og mottar ytelser fra{' '}
					{{ company: companyName }}, ber vi deg om å levere leveattest.
					<br />
					<br />
					Vi gjennomfører periodisk leveattestkontroller for å sikre at
					utbetalingene går til riktig mottaker. Etter fullført innhenting
					av leveattest vil leveattesten registreres i Finans Norge
					Forsikringsdrift sitt leveattestregister. Dette vil gjøre det
					enklere for deg, dersom du mottar ytelser fra andre selskaper.
					<br />
					<br />
					Dersom leveattest ikke blir returnert digitalt eller via post,
					eller skjemaet er mangelfullt utfylt, vil utbetalingen av din
					ytelse bli stanset.
				</Trans>
			</HeaderText2>
			<ButtonWrapper>
				<Button
					text={t('welcome.button')}
					rightIcon={{ name: 'ArrowRightBig' }}
					onClick={() =>
						navigate(`${WALK_THROUGH_PAGE_PATH}/${companyName}/${uuid}/1`)
					}
				/>
			</ButtonWrapper>
		</Wrapper>
	)
}
