import { BankIDType } from 'types/BankIDType'

interface BankIdResponseType {
	data: {
		intent_url: string
		handover_id: string
	}
}

/**
 * Function to map from a JSON object from backend to bankId type
 *
 * @param responseJSON the object to map
 *
 * @returns the mapped bankId object
 */
export const mapJsonObjectToBankIdType = (
	responseJSON: unknown
): BankIDType => {
	const bankIdDetails: BankIdResponseType = responseJSON as BankIdResponseType

	return {
		intentUrl: bankIdDetails.data.intent_url,
		handoverId: bankIdDetails.data.handover_id
	}
}
