import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { Text as BaseText } from 'components/text/Text'
import { SubHeaderText } from 'components/text/SubHeaderText'
import { Button as BaseButton } from 'components/company-components/button/Button'

import { buttonStyleWhiteMedium } from 'styles/button'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 30px;
`

const Text = styled(BaseText)`
	text-align: center;
	margin-bottom: 30px;
`

const ItalicText = styled(BaseText)`
	text-align: center;
	font-style: italic;
	margin-bottom: 30px;
`

const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	padding-inline: 20px;
	justify-content: space-between;
`

const BackButton = styled(BaseButton)`
	${buttonStyleWhiteMedium}
	min-width: 200px;
	height: 50px;
`

const DeleteButton = styled(BaseButton)`
	min-width: 200px;
	height: 50px;
`

interface Props {
	onClose: () => void
	onDelete: () => void
	name: string
	title: string
	type: 'LC' | 'beneficiary'
	italicText?: string
	company?: string
}

/**
 * Displays the content in the delete modals
 *
 * @param props.onClose function to handle the closing of modal
 * @param props.onDelete function to handle the delete
 * @param props.name the name of the beneficiary
 * @param props.title title in the modal
 * @param props.type LC or beneficiary
 * @param props.italicText optional italic text in the modal
 */
export const DeleteModalContent = ({
	onClose,
	onDelete,
	name,
	title,
	type,
	italicText,
	company
}: Props) => {
	const { t } = useTranslation('company')

	const { isAdmin } = useIsAdminRole()

	return (
		<>
			<HeaderText1>{title}</HeaderText1>
			<Text>
				<Trans
					i18nKey={
						type === 'LC'
							? 'company:beneficiary.delete.LC.text'
							: 'company:beneficiary.delete.beneficiary.text'
					}
				>
					{type === 'LC' ? (
						<span>
							Er du sikker på at du ønsker å slette den gyldige
							leveattesten til{' '}
						</span>
					) : (
						<span>Er du sikker på at du ønsker å slette </span>
					)}
					<SubHeaderText as="span">{{ name: name } as any}</SubHeaderText>
				</Trans>
				{isAdmin && company && (
					<Trans i18nKey="company:beneficiary.delete.beneficiary.textCompany">
						<span> fra selskapet</span>
						<SubHeaderText as="span">
							{{ company: company } as any}
						</SubHeaderText>
					</Trans>
				)}
				?
			</Text>
			{italicText && <ItalicText>{italicText}</ItalicText>}
			<ButtonWrapper>
				<BackButton text={t('beneficiary.delete.dont')} onClick={onClose} />
				<DeleteButton
					text={
						type === 'LC'
							? t('beneficiary.delete.LC.delete')
							: t('beneficiary.delete.beneficiary.delete')
					}
					onClick={onDelete}
				/>
			</ButtonWrapper>
		</>
	)
}
