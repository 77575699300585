import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { getAllBeneficiariesByNin } from 'api/services/BeneficiaryService'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { TopOfPageContent } from 'components/company-components/utility/TopOfPageContent'
import { Spinner } from 'components/Spinner'
import { Text as BaseText } from 'components/text/Text'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'

import { useOnce } from 'hooks/useOnce'

import { LOGIN_PATH, OPPGAVEBEHANDLING_PATH } from 'navigation/navigationURLs'

import { mapJsonObjectToBeneficiaryType } from 'utils/type-mappers/mapJsonObjectToBeneficiaryType'

import { LifeCertificateContainer } from './LifeCertificateContainer'
import { BeneficiaryContainer } from './BeneficiaryContainer'
import { SelectBeneficiaryByCompany } from './SelectBeneficiaryByCompany'
import { BottomButtons } from './BottomButtonts'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
`

const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-block: 20px;
`

const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
`

const Label = styled.label`
	color: var(--color-text);
	${({ color }) =>
		color === 'white' ? `color: var(--color-white)` : `color: ${color}`};
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-light);
	margin: 0;
	margin-right: 20px;
`

const Text = styled(BaseText)`
	text-align: center;
`

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
	width: 100%;
`

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 1000px;
	margin-top: 10px;
	margin-inline: 15%;
`

const BodyContainerWrapper = styled.div`
	background-color: var(--color-box);
	display: flex;
	border-radius: 20px;
	max-width: 100%;
	box-shadow: 0 1px 4px rgb(0, 0, 0, 25%);
`

const NoBeneficiaryWrapper = styled.div`
	margin: 0 20% 5%;
	background-color: var(--color-box);
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	padding: 55px;
	align-items: center;
`

/**
 * Interface to control the state being sent
 */
interface LocationState {
	nin: string
}

/**
 * Page which displays details about a beneficiary and the life certificate
 */
export const BeneficiaryPageFNF = () => {
	const { nin } = useLocation().state as LocationState

	const [isLoading, setIsLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	const [isError, setIsError] = useState(false)

	useEffect(() => {
		console.log({ errorMsg })
	}, [errorMsg])

	const navigate = useNavigate()

	/**
	 * useState to hold the list of each occurrence of the selected beneficiary on all companies.
	 * 	- empty list when it is initial state
	 * 	- beneficiary when found
	 * 	This is done to get the beneficiary in one call in a useEffect
	 */
	const [beneficiaries, setBeneficiaries] = useState<BeneficiaryType[]>([])
	const [selectedBeneficiary, setSelectedBeneficiary] =
		useState<BeneficiaryType>()

	const [hasResponse, setHasResponse] = useState(false)
	const [hasBeneficiaryChanged, setHasBeneficiaryChanged] = useState(false)

	const { t } = useTranslation('company')

	/**
	 * Get the beneficiary when some values changes.
	 * Cases where this is possible:
	 * - When a request is sent - From Case 1 -> Case 2
	 * - When a reminder is sent - From Case 2 -> Case 3
	 * - When uploaded LC is marked as Valid - From Case 4 -> Case 6
	 * - When uploaded LC is marked as Invalid - From Case 4 -> Case 5
	 * - When Invalid LC is marked as Invalid - From Case 5 -> Case 6
	 */
	useEffect(() => {
		/**
		 * If nin does not exist or gets changed to undefined for some reason, send the user back to dashboard page,
		 */
		if (nin === undefined || nin === null) {
			navigate('/bedrift/oppgavebehandling/')
		}

		if (hasBeneficiaryChanged) {
			getBeneficiaries()
			setHasBeneficiaryChanged(false)
		}
		/**
		 * If we have a response from backend, and the selected
		 * beneficiary is undefined, then set it to the first one
		 */
		if (hasResponse && !selectedBeneficiary) {
			setSelectedBeneficiary(beneficiaries[0])
		}
		// eslint-disable-next-line
	}, [
		hasBeneficiaryChanged,
		setHasBeneficiaryChanged,
		hasResponse,
		selectedBeneficiary
	])

	useOnce(() => {
		/**
		 * Get the beneficiary by nin, or set the error
		 */
		if (!hasResponse) {
			getBeneficiaries()
		} else setErrorMsg(t('beneficiary.errors.fetch'))
	})

	/**
	 * Get the beneficiary by nin, or set the error
	 */
	const getBeneficiaries = () => {
		setErrorMsg('')
		setIsLoading(true)

		getAllBeneficiariesByNin(nin)
			.then((res) => {
				setSelectedBeneficiary(
					mapJsonObjectToBeneficiaryType(
						res.data.beneficiaries[0] as BeneficiaryType
					)
				)

				setBeneficiaries(
					res.data.beneficiaries.map((b: unknown) =>
						mapJsonObjectToBeneficiaryType(b as BeneficiaryType)
					)
				)
				setIsLoading(false)
				setHasResponse(true)
			})
			.catch((err) => {
				setIsLoading(false)
				setIsError(true)
				console.log({ err })
				console.error(err)
				// eslint-disable-next-line eqeqeq
				if (err.code == '401' || err.response.status == 401) {
					toast.error(t('beneficiary.errors.401'))
					return navigate(LOGIN_PATH)
				}
				toast.error(
					<div className="dismissToast">
						{t('beneficiary.errors.fetchAll')}
					</div>
				)
			})
	}

	const displayDropDown = () => {
		if (beneficiaries.length > 1) {
			return (
				<SelectContainer>
					<Label htmlFor="selectCompany">
						{t('beneficiary.chooseCompany')}
					</Label>
					<SelectBeneficiaryByCompany
						beneficiaries={beneficiaries}
						onSelect={(b) => setSelectedBeneficiary(b)}
						id="selectCompany"
					/>
				</SelectContainer>
			)
		}
		return (
			<SelectContainer>
				<Text>
					{t('beneficiary.company')}: {selectedBeneficiary?.companyName}
				</Text>
			</SelectContainer>
		)
	}

	const displayBody = () => {
		if (isError) {
			return (
				<NoBeneficiaryWrapper>
					<Text>{t('beneficiary.problem')}</Text>
				</NoBeneficiaryWrapper>
			)
		}
		if (isLoading || selectedBeneficiary === undefined) {
			return <Spinner title={t('beneficiary.loading')} />
		}
		if (!hasResponse || !selectedBeneficiary) {
			return (
				<NoBeneficiaryWrapper>
					<Text>{t('beneficiary.notFound')}</Text>
				</NoBeneficiaryWrapper>
			)
		}
		return (
			<>
				<TopWrapper>
					<HeaderText1>{t('beneficiary.title')}</HeaderText1>
					{displayDropDown()}
				</TopWrapper>
				<BodyContainerWrapper>
					<BeneficiaryContainer beneficiary={selectedBeneficiary} />
					<LifeCertificateContainer
						lifeCertificate={selectedBeneficiary.lifeCertificate}
						request={selectedBeneficiary.request}
						beneficiary={selectedBeneficiary}
						onBeneficiaryChange={() => setHasBeneficiaryChanged(true)}
					/>
				</BodyContainerWrapper>
				{hasResponse && (
					<BottomButtons
						beneficiary={selectedBeneficiary}
						companyName={selectedBeneficiary.companyName ?? ''}
						onBeneficiaryChange={() => setHasBeneficiaryChanged(true)}
						onDeleteBeneficiary={() => {
							if (beneficiaries.length > 1) {
								setHasBeneficiaryChanged(true)
							} else navigate(OPPGAVEBEHANDLING_PATH)
						}}
					/>
				)}
			</>
		)
	}

	return (
		<Wrapper>
			<TopOfPageContent
				displayBackButton
				navigateBackToLink={OPPGAVEBEHANDLING_PATH}
			/>
			<BodyWrapper aria-live="polite" aria-busy={isLoading}>
				{displayBody()}
			</BodyWrapper>
		</Wrapper>
	)
}
