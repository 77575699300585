/**
 * Creates a Blob of type text/csv, before it creates the download URL with the name
 * of what it is + the company name + when it was uploaded
 *
 * @param blobParts the blobparts content of the file
 * @param companyName the name of the company
 */
export const downloadCsvFile = (blobParts: BlobPart, companyName: string) => {
	const blob = new Blob([blobParts], { type: 'text/csv' })

	const url = URL.createObjectURL(blob)
	const f = document.createElement('a')
	f.href = url
	const d = new Date()
	const dateString =
		d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear().toString()
	f.download = `Bulk Request uploaded by ${companyName} at ${dateString}`

	f.click()
	f.remove()
}
