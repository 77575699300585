import styled from 'styled-components'

/**
 * Text component to display an a element for an external link
 *
 * Color: #051D2E or #FFFFFF
 * Size: 16px
 * Family: Merriweather Regular
 * Weight: 700
 */
export const ExternalLink = styled.a`
	color: ${({ color }) => color ?? 'var(--color-text)'};
	font-size: var(--font-size-text);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-bold);
	text-decoration: underline;
	margin: 0;

	&:hover {
		font-weight: var(--font-weight-extra-bold);
		color: var(--color-main-blue_green);
	}
`
