import React, { ComponentPropsWithoutRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { SelectType } from 'types/SelectType'

import { Label } from 'components/text/Label'

import countries from './countries.json'

const Wrapper = styled.div``

interface ICountryDropDown {
	selected: boolean
}

const StyledSelect = styled(Select)<ICountryDropDown>`
	width: 100%;
	margin-top: 5px;
	display: flex;

	.rs__control {
		width: 100%;
		height: 42px;
		background-color: var(--color-box);
		border-radius: 5px;
		font-size: var(--font-size-field-text);
		font-family: var(--font-family-merriweather);
		color: var(--color-text);
		cursor: pointer;

		opacity: ${(props) => !props.selected && 0.8};

		box-sizing: border-box;
		box-shadow: 0 0 0 1px var(--color-main-dark_blue) inset;
		border: none;

		&:hover {
			box-shadow: 0 0 0 2px var(--color-main-blue_green) inset;
		}
	}

	.rs__indicator-separator {
		display: none;
	}

	.rs__indicator-container {
		cursor: pointer;
	}

	.rs__value-container {
	}
`

interface Props {
	title: string
	value: string | null
	name: string
	id?: string
	onSelect: any
	hiddenLabel?: boolean
	wrapperProps?: ComponentPropsWithoutRef<'div'>
}

/**
 * Country selector input field to be used with react-hook-form
 *
 * @param props.title the title of the input
 * @param props.value the value
 * @param props.onChange the onchange function
 * @param props.id Optional.
 * @param props.name the name of the component
 * @param props.hiddenLabel Optional. Adds the title as a aria-label instead of as a label element
 * @param props.wrapperProps Props to add to the wrapper
 */
export const CountryInput = ({
	title,
	value,
	name,
	id,
	onSelect,
	hiddenLabel,
	wrapperProps,
	...rest
}: Props) => {
	const { t } = useTranslation('company')

	const options: SelectType[] = countries.countryList.map(
		(country: string) => {
			return {
				value: country,
				label: country
			}
		}
	)

	return (
		<Wrapper {...wrapperProps}>
			{!hiddenLabel && <Label htmlFor={id ?? name}>{title}</Label>}
			<StyledSelect
				inputId={id ?? name}
				name={name}
				options={options}
				isMulti={false}
				classNamePrefix="rs"
				onChange={(a: unknown) => {
					const val = a as SelectType
					if (val === null) onSelect('')
					else onSelect(val.value)
				}}
				isClearable
				placeholder={t('dash.register.modal.form.country.title')}
				value={value ? { label: value } : ''}
				selected={value !== ''}
				{...(hiddenLabel ? { 'aria-label': title } : {})}
				{...rest}
			/>
		</Wrapper>
	)
}
