export const FONT_FAMILY_MERRIWEATHER = 'Merriweather'
export const FONT_FAMILY_OPEN_SANS = 'Open Sans'

export const FONT_WEIGHT_LIGHT = 300
export const FONT_WEIGHT_NORMAL = 400
export const FONT_WEIGHT_SEMI_BOLD = 500
export const FONT_WEIGHT_ALMOST_BOLD = 600
export const FONT_WEIGHT_BOLD = 700
export const FONT_WEIGHT_EXTRA_BOLD = 900

export const FONT_SIZE_TITLE1 = '28px'
export const FONT_SIZE_TITLE2 = '22px'
export const FONT_SIZE_BUTTON_TEXT = '18px'
export const FONT_SIZE_TEXT = '18px'
export const FONT_SIZE_FIELD_TEXT = '16px'
export const FONT_SIZE_BOX_INGRESS = '16px'
export const FONT_SIZE_SMALL_TEXT = '14px'

// PRIVATE BROWSER
export const FONT_SIZE_BUTTON_TEXT_PRIVATE_BROWSER = '20px'

// PRIVATE MOBILE
export const FONT_SIZE_TITLE1_MOBILE = '20px'
export const FONT_SIZE_TITLE2_MOBILE = '16px'
export const FONT_SIZE_BUTTON_TEXT_PRIVATE_MOBILE = '16px'
export const FONT_SIZE_TEXT_MOBILE = '16px'
