import React, {
	memo,
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

const StyledDropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

interface Props {
	onDrag?: () => void
	onDrop?: () => void
	onFilesDrop?: (newFiles: File[], currentFiles: File[]) => void
	onClick: (event: any) => void
	errorMessage?: string
	currentFiles: File[]
	fileType: string
	children: ReactNode
}

/**
 * Dropzone component where files can be dropped
 *
 * @param props.onDrag function for dragging
 * @param props.onDrop function for dropping
 * @param props.onFilesDrop function for handling and updating the list when files are dropped
 * @param props.onClick function for clicking the drop zone
 * @param props.onWrongFileType function to handle wrong file type
 * @param props.currentFiles files currently selected
 * @param props.children React children
 */
export const DropZone = memo(
	({
		onDrag,
		onDrop,
		onFilesDrop,
		onClick,
		currentFiles,
		errorMessage,
		fileType,
		children
	}: Props) => {
		// to handle if the drop zone is active or not
		const [isDragActive, setIsDragActive] = useState(false)

		// To handle the drop zone ref
		const dropZoneRef = useRef<null | HTMLDivElement>(null)

		/**
		 * Hook to attach listeneres to dropzone
		 */
		useEffect(() => {
			const temporaryZoneRef = dropZoneRef?.current

			// Add the event listeners
			if (temporaryZoneRef) {
				temporaryZoneRef.addEventListener('dragover', handleDrag)
				temporaryZoneRef.addEventListener('drop', handleDrop)
			}

			// remove the listeners from the dropzone on unmount
			return () => {
				temporaryZoneRef?.removeEventListener('dragover', handleDrag)
				temporaryZoneRef?.removeEventListener('drop', handleDrop)
			}
			// eslint-disable-next-line
		}, [])

		const handleDrag = useCallback(
			(event: any) => {
				event.preventDefault()
				event.stopPropagation()

				// invoke any optional methods passed as "onDrag()"
				onDrag?.()

				if (!isDragActive) {
					setIsDragActive(true)
				}
			},
			[isDragActive, onDrag]
		)

		const handleDrop = useCallback(
			(event: any) => {
				event.preventDefault()
				event.stopPropagation()

				const selectedFiles = event.dataTransfer.files

				setIsDragActive(false)

				// invoke any optional methods passed as "onDrop()"
				onDrop?.()

				// If there are any files dropped
				if (selectedFiles && selectedFiles.length > 0) {
					const filesToUpload = []
					const errorFiles = []

					// Check if the files are valid
					for (let i = 0; i < selectedFiles.length; i++) {
						if (selectedFiles.item(i).type === fileType) {
							filesToUpload.push(selectedFiles.item(i))
						} else errorFiles.push(selectedFiles.item(i))
					}

					// Alert user that they must only select PDFs
					if (errorFiles.length > 0) {
						toast.error(
							<div className="dismissToast">{errorMessage}</div>
						)
					}

					// invoke any optional method passed as "onFilesDrop() with the files to upload
					onFilesDrop?.(filesToUpload, currentFiles)

					// Clear transfer data to prepare dropzone for another use
					event.dataTransfer.clearData()
				}
			},
			[onDrop, onFilesDrop, currentFiles, errorMessage, fileType]
		)

		return (
			<StyledDropZone ref={dropZoneRef} onClick={onClick} id="dropZone">
				{children}
			</StyledDropZone>
		)
	}
)
