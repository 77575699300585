import { ReactNode } from 'react'
import styled from 'styled-components'

import { Text as BaseText } from 'components/text/Text'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'

const Wrapper = styled.div`
	min-width: 160px;
`

const Text = styled(BaseText)`
	margin: 0;
	margin-bottom: 25px;
	word-wrap: break-word;
	font-weight: var(--font-weight-light);
	padding-right: 20px;
	white-space: pre-line;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	margin-top: 5px;
	margin-inline: 0;
	margin-bottom: 10px;
`

export interface ListTextItemProps {
	title: string
	text?: string | null
	children?: ReactNode
	color?: 'white' | undefined
}

/**
 * Component to display list items with title and text
 *
 * @param props.title The title of the item
 * @param props.text The text of the item
 * @param props.children React children
 * @param props.color Colour of the text
 */
export const ListTextItem = ({
	title,
	text,
	children,
	color,
	...rest
}: ListTextItemProps) => {
	return (
		<Wrapper {...rest}>
			<SubHeaderText color={color}>{title}</SubHeaderText>
			{text !== undefined ? (
				<Text color={color}>{text === null ? '-' : text}</Text>
			) : (
				children
			)}
		</Wrapper>
	)
}
