import styled from 'styled-components'

import successImage from 'images/BankIDSuccess.svg'
import errorImage from 'images/BankIDError.svg'

import { Text as BaseText } from 'components/text/Text'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-block: 2vh;
`

const Image = styled.img`
	width: 100px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-top: 20px;
	text-align: center;
`

const Text = styled(BaseText)`
	margin-top: 40px;
	text-align: center;
	white-space: pre-line;
`

interface Props {
	success: boolean
	title: string
	text: string
}

/**
 * Component to display the logo, title and text when the process with bank id is completed
 *
 * @param props.success If it is success or error
 * @param props.title Title of the page
 * @param props.text Text of the page
 */
export const CompletedBankIdContent = ({ success, title, text }: Props) => {
	return (
		<Wrapper>
			<Image
				src={success ? successImage : errorImage}
				alt={
					success
						? 'Du har suksessfullt gjennomført prosessen'
						: 'Det har skjedd en feil i prosessen'
				}
			/>
			<HeaderText1>{title}</HeaderText1>
			<Text>{text}</Text>
		</Wrapper>
	)
}
