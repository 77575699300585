import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import { Trans, useTranslation } from 'react-i18next'

import { fetchPaperCertificate } from 'api/services/LifeCertificateService'
import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { Text as BaseText } from 'components/text/Text'
import { ExternalLink } from 'components/text/ExternalLink'
import { SubHeaderText } from 'components/text/SubHeaderText'
import { ModalX as BaseModal } from 'components/common/ModalX'
import { Icon } from 'components/icon/Icon'

import { Button as BaseButton } from 'components/private-components/button/Button'
import { AButton as BaseAButton } from 'components/private-components/button/AButton'
import { ButtonText } from 'components/private-components/text/ButtonText'
import { Expandable as BaseExpandable } from 'components/private-components/Expandable'

import { InformationBox as BaseInformationBox } from './InformationBox'
import { LocationState } from './LocationState'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	margin-inline: auto;
	--alternative-box-margin: 25px;
	margin-bottom: var(--alternative-box-margin);
	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 700px;
		align-items: center;
		--alternative-box-margin: 15px;
	}
`

const Modal = styled(BaseModal)`
	position: relative;
	width: 100%;
	height: calc(100vh - (25px * 2));
	margin: 25px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		width: 100%;
		height: calc(100vh - (25px * 2));
		margin: 25px;
	}
`

const InformationBox = styled(BaseInformationBox)`
	margin-top: 0;
	margin-bottom: var(--alternative-box-margin);
	width: 100%;
`

const HeaderText1 = styled(BaseHeaderText1)`
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
`

const Numbers = styled.h3`
	font-size: var(--font-size-title1);
	font-family: var(--font-family-merriweather);
	font-weight: var(--font-weight-normal);
	line-height: 1.25;
	margin: 0;
	margin-right: 10px;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-left: 10px;
		margin-right: 15px;
	}
`

const Text = styled(BaseText)`
	white-space: pre-line;
	line-height: 27px;
`

const SmallTitle = styled(BaseText)`
	font-weight: var(--font-weight-bold);
	margin: 0;
	margin-bottom: 12px;
`

const buttonStyle = css`
	width: 100%;
	margin-bottom: var(--alternative-box-margin);

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		padding-inline: 40px;
	}
`

const AButton = styled(BaseAButton)`
	${buttonStyle}
	display: flex;
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}
`

const Button = styled(BaseButton)`
	margin-bottom: var(--alternative-box-margin);
	&:last-child {
		margin-bottom: 0;
	}
`

const LeftButton = styled(BaseButton)`
	margin-bottom: 25px;
	margin-top: 10px;
	padding-inline: 40px;
	height: 40px;

	&:last-child {
		margin-bottom: 0;
	}
	@media screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		margin-top: 50px;
		align-self: flex-start;
	}
`

const Embed = styled.embed`
	width: 100%;
	height: 100%;
	border: solid var(--color-black) 0.1px;
	margin-bottom: 25px;
`

const ContentBox = styled.div`
	margin-bottom: 16px;
`

const Expandable = styled(BaseExpandable)``

interface Props {
	onClickSwitchTab: (tabIndex: number, fromTab?: number) => void
}

/**
 * Component to display a page with alternative ways to send LC on
 *
 * @param onClickSwitchTab callback called when the back button is pressed.
 * @param onClickSwitchTab.tabIndex the index number of the tab to be displayed. (1=tab1, 2=tab2, 3=tab3, 4=tab4, 5=consent page, 6=alternative ways page)
 * @param onClickSwitchTab.fromTab Optional parameter that lets the browser know which page we came from so it moves back to the correct page
 */
export const AlternativeWaysToSendPage = ({ onClickSwitchTab }: Props) => {
	const [pdfUri, setPdfUri] = useState('')
	const [showPDF, setShowPDF] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const { companyName } = useParams()
	const { t } = useTranslation('private')

	const fromTab = (useLocation().state as LocationState)?.fromTab

	const onClickGoBack = () => {
		onClickSwitchTab(fromTab ?? 2)
	}

	const spawnPDFModal = async () => {
		try {
			console.log('Attempting to spawn modal', { companyName })
			if (!companyName) return

			setIsLoading(true)

			const response = await fetchPaperCertificate(companyName)
			console.log({ response })
			const pdfFile = new Blob([response.data], { type: 'application/pdf' })
			const url = URL.createObjectURL(pdfFile)
			setPdfUri(url)
			setShowPDF(true)
			setIsLoading(false)
		} catch (err: any) {
			console.log(err)
			setIsLoading(false)
			if (err.response.status === 404)
				toast.error(
					<div className="dismissToast">
						{t('alternative.errors.downloadNoCompany') as string}
					</div>
				)
			else
				toast.error(
					<div className="dismissToast">
						{t('alternative.errors.download') as string}
					</div>
				)
		}
	}

	return (
		<>
			<Wrapper>
				<Modal isOpen={showPDF} onClose={() => setShowPDF(false)}>
					<Embed as="object" aria-label="PDF" data={pdfUri} />
					<AButton
						download={`Leveattest ${companyName}.pdf`}
						href={pdfUri}
					>
						<Icon name="Download" style={{ marginRight: 8 }} />
						<ButtonText color="inherit">
							{t('alternative.download2')}
						</ButtonText>
					</AButton>
				</Modal>
				<HeaderText1>{t('alternative.title')}</HeaderText1>
				<SubHeaderText as="h2" mb="20px">
					{t('alternative.subtitle')}
				</SubHeaderText>
				<InformationBox
					text={t('alternative.step1')}
					leftChildren={<Numbers>1</Numbers>}
					justifyContentRight="center"
				/>
				<Button
					onClick={spawnPDFModal}
					text={t('alternative.download', {
						context: isLoading ? 'loading' : undefined
					})}
					leftIcon={isLoading ? undefined : { name: 'Download' }}
				/>
				<InformationBox
					text={t('alternative.step2')}
					leftChildren={<Numbers>2</Numbers>}
					justifyContentRight="center"
				/>
				<InformationBox
					text={t('alternative.step3')}
					leftChildren={<Numbers>3</Numbers>}
					justifyContentRight="center"
				/>
				<InformationBox
					rightChildren={
						<>
							<ContentBox>
								<SmallTitle as="h4">
									{t('alternative.step4.title')}
								</SmallTitle>
								<Text>
									<Trans i18nKey="private:alternative.step4.text">
										Når leveattesten er utfylt kan den leveres
										digitalt på{' '}
										<ExternalLink
											href="https://leveattest.finansnorge.no"
											target="_blank"
											rel="noopener noreferrer"
										>
											leveattest.finansnorge.no
										</ExternalLink>
										. Ved digital innsending sparer du kostnader til
										porto og du unngår forsinkelser i postgangen.
									</Trans>
								</Text>
							</ContentBox>
							<Expandable title={t('alternative.step4.expand.title')}>
								{t('alternative.step4.expand.text')}
							</Expandable>
						</>
					}
					leftChildren={<Numbers>4</Numbers>}
					justifyContentRight="center"
				/>
			</Wrapper>
			<LeftButton text={t('alternative.return')} onClick={onClickGoBack} />
		</>
	)
}
