import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { deleteLCByNin } from 'api/services/LifeCertificateService'

import { Modal as BaseModal } from 'components/common/Modal'
import { Spinner } from 'components/Spinner'

import { DeleteModalContent } from 'pages/company-pages/BeneficiaryPage/DeleteModalContent'

const Modal = styled(BaseModal)`
	min-width: 750px;
`

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-inline: 100px;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	name: string
	nin: string
	onBeneficiaryChange: () => void
}

/**
 * Display the modal where a user has to verify the deletion of a LC
 *
 * @param props.isOpen if the modal is open or not
 * @param props.onClose function to handle the closing of modal
 * @param props.name the name of the beneficiary
 * @param props.nin the nin of the beneficiary
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const DeleteLCModal = ({
	isOpen,
	onClose,
	name,
	nin,
	onBeneficiaryChange
}: Props) => {
	// To handle loading
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation('company')

	const onDelete = () => {
		setIsLoading(true)

		deleteLCByNin(nin)
			.then(() => {
				setIsLoading(false)
				onBeneficiaryChange()
				onClose()
				toast.success(
					<div className="dismissToast">
						{t('beneficiary.delete.LC.success')}
					</div>
				)
			})
			.catch((err) => {
				setIsLoading(false)
				console.log({ err })
				console.error(err)
				toast.error(
					<div className="dismissToast">
						{t('beneficiary.delete.LC.error') as string}
					</div>
				)
			})
	}

	const displayContent = () => {
		if (isLoading) {
			return <Spinner />
		}
		return (
			<>
				<DeleteModalContent
					onClose={onClose}
					onDelete={onDelete}
					name={name}
					title={t('beneficiary.delete.LC.title')}
					italicText={t('beneficiary.delete.LC.italic')}
					type="LC"
				/>
			</>
		)
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalContent>{displayContent()}</ModalContent>
		</Modal>
	)
}
