import { CommunicationType } from 'types/CommunicationType'

/**
 * Function to map from a JSON object from backend to communication type
 *
 * @param responseJSON the object to map
 *
 * @returns the mapped communication object
 */
export const mapJsonObjectToCommunicationType = (
	responseJSON: unknown
): CommunicationType => {
	const communication: CommunicationType = responseJSON as CommunicationType

	return {
		...communication,
		createdOn: new Date(communication.createdOn)
	}
}
