import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { postBulkRequest } from 'api/services/RequestService'

import exampleJson from 'files/exampleSendRequest.json'

import { BulkFileUploader } from 'components/FileUploader/BulkFileUploader'
import { Spinner } from 'components/Spinner'

import { downloadCsvFile } from 'utils/fileUtils/downloadCsvFile'
import { getFileDetails } from 'utils/fileUtils/getFileDetails'
import { displayBulkUploadToast } from 'utils/fileUtils/displayBulkUploadToast'

import { useGetCompanyNameOfUser } from 'hooks/useGetCompanyNameOfUser'
import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { ReceiptButton } from 'components/FileUploader/BulkFileUploader/ReceiptButton'

import { DashboardBox } from './DashboardBox'

/**
 * Component to upload a json file with bulk for requesting life certificates
 */
export const BulkUpload = () => {
	const { isAdmin } = useIsAdminRole()
	const { companyName } = useGetCompanyNameOfUser()

	// Handle loading
	const [isLoading, setIsLoading] = useState(false)
	const [displayReceiptText, setDisplayReceiptText] = useState(false)

	const { t } = useTranslation('company')

	// Handle the files
	const [files, setFiles] = useState<File[]>([])

	// TODO - 	in the future, find a way to add the following comments so that
	// 			the user can see the meaning of the fields
	// 			// nationalIdentityNumber is "fødselsnummer"
	// 			// channelNames is the list with the channels you want to send the request. This can either be 1, 2, or all 3 of the following: "email", "sms", "post".
	const blob = new Blob([JSON.stringify(exampleJson, null, 4)], {
		type: 'application/json'
	})

	const reset = () => {
		setFiles([])
	}

	const onUpload = () => {
		if (!isAdmin) {
			setIsLoading(true)
			postBulkRequest(files[0])
				.then((res) => {
					// Download csv file
					downloadCsvFile(res.data, companyName)

					displayBulkUploadToast(
						getFileDetails(res.data),
						t('dash.bulk.upload.sent'),
						t('dash.bulk.upload.sentCSV')
					)
					setDisplayReceiptText(true)
					reset()
					setIsLoading(false)
				})
				.catch((err) => {
					console.log({ err })
					console.error(err)
					toast.error(
						<div className="dismissToast">
							{t('dash.bulk.upload.errors.generic')}
						</div>
					)
					setIsLoading(false)
				})
		}
	}

	const displayContent = () => {
		if (isLoading) {
			return <Spinner />
		}
		if (displayReceiptText) {
			return (
				<ReceiptButton
					text={t('dash.bulk.new.text')}
					buttonText={t('dash.bulk.new.button')}
					onStartAgain={() => setDisplayReceiptText(false)}
				/>
			)
		}
		return (
			<BulkFileUploader
				fileType="application/json"
				onUpload={onUpload}
				files={files}
				setFiles={setFiles}
				uploadButtonText={t('dash.bulk.button')}
				exampleFileAsBlob={blob}
				uploadFieldMinWidth="320px"
				uploadFieldColor="var(--color-white)"
				isRequest
			/>
		)
	}
	return (
		<DashboardBox title={t('dash.bulk.title')} minHeight="240px">
			{displayContent()}
		</DashboardBox>
	)
}
