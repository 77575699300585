import { LifeCertificateType } from './LifeCertificateType'
import { ContactType, initialEmptyContact } from './ContactType'
import { RequestType } from './RequestType'

/**
 * The type of beneficiary (person)
 */
export interface BeneficiaryType {
	id: number
	lifeCertificate: LifeCertificateType | null
	name: string
	nationalIdentityNumber: string
	birthdate: Date
	contact: ContactType
	request: RequestType | null
	createdOn: Date
	otherCompanyRequest: Date | null
	companyName?: string
	caseName:
		| 'initial'
		| 'requestedOrReminded'
		| 'uploaded'
		| 'markedInvalid'
		| 'valid'
		| 'expired'
}

/**
 * Empty beneficiary for initial types when loading
 */
export const initialEmptyBeneficiary: BeneficiaryType = {
	id: -1,
	lifeCertificate: null,
	name: '',
	nationalIdentityNumber: '',
	birthdate: new Date(),
	contact: initialEmptyContact,
	request: null,
	createdOn: new Date(),
	otherCompanyRequest: null,
	caseName: 'initial'
}
