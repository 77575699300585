import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/company-components/button/Button'
import { Modal } from 'components/common/Modal'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { LCFileUploader as BaseFileUploader } from 'components/FileUploader/LCFileUploader'

const Wrapper = styled.div``

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 20px;
`

const FileUploader = styled(BaseFileUploader)`
	min-width: 100%;
`

interface Props {
	uuid: string
	nin: string
	onBeneficiaryChange: () => void
}

/**
 * Component to register a LC manually that was received by paper post at the
 * company's office.
 *
 * @param props.uuid uuid of the request used to identify the specific request
 * @param props.nin national identity number of the beneficiary
 * @param props.onBeneficiaryChange function to update the beneficiary when a change is made to it
 */
export const LCReceivedOnPaper = ({
	uuid,
	nin,
	onBeneficiaryChange,
	...rest
}: Props) => {
	const [modalOpen, setModalOpen] = useState(false)
	const { t } = useTranslation('company')

	const onSuccess = () => {
		setModalOpen(false)
		onBeneficiaryChange()
	}

	return (
		<Wrapper {...rest}>
			<Button
				text={t('beneficiary.cert.paper.button')}
				onClick={() => setModalOpen(true)}
				icon={{
					name: 'FileSignature'
				}}
			/>
			<Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
				<ModalContent>
					<HeaderText1>{t('beneficiary.cert.paper.title')}</HeaderText1>
					<FileUploader
						uuid={uuid}
						nin={nin}
						onSuccess={onSuccess}
						usageType="company"
						fileType="application/pdf"
					/>
				</ModalContent>
			</Modal>
		</Wrapper>
	)
}
