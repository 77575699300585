import React, { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

import { Label } from 'components/text/Label'

const InputWrapper = styled.div``

const StyledInput = styled.input`
	width: 100%;
	padding: 10px;
	margin-top: 5px;
	background-color: var(--color-box);
	border-radius: 5px;
	font-size: var(--font-size-field-text);
	font-family: var(--font-family-merriweather);
	color: var(--color-text);
	box-sizing: border-box;
	box-shadow: 0 0 0 1px var(--color-main-dark_blue) inset;
	border: none;

	&:hover {
		box-shadow: 0 0 0 2px var(--color-main-blue_green) inset;
	}
`

interface Props extends ComponentPropsWithoutRef<'input'> {
	title: string
	placeholder: string
	id?: string
	onChange: (value: any) => void
	name: string
	hiddenLabel?: string
	wrapperProps?: ComponentPropsWithoutRef<'div'>
}

/**
 * Phone number input to be used with react hook form
 *
 * @param props.title the title of the input
 * @param props.placeholder the placeholder for the text field
 * @param props.id the id of the component
 * @param props.value the value of the fields
 * @param props.onChange function to handle the change of inputs
 * @param props.name the name of the field
 * @param props.hiddenLabel Optional. Adds the title as a aria-label instead of as a label element
 * @param props.wrapperProps Props to add to the wrapper
 */
export const PhoneNumberInput = ({
	title,
	placeholder,
	id,
	onChange,
	value,
	name,
	hiddenLabel,
	wrapperProps,
	...rest
}: Props) => {
	const isPhoneNumberWithCountryCode = /^\+[0-9]*$/
	const isOnlyNumberCharacters = /^[0-9]+$/

	return (
		<InputWrapper {...wrapperProps}>
			{!hiddenLabel && <Label htmlFor={id ?? name}>{title}</Label>}
			<StyledInput
				id={id ?? name}
				placeholder={placeholder}
				onChange={(e) => {
					if (
						!e.target.value ||
						isPhoneNumberWithCountryCode.test(e.target.value)
					) {
						return onChange(e.target.value)
					} else if (isOnlyNumberCharacters.test(e.target.value)) {
						return onChange(`+${e.target.value}`)
					}
				}}
				value={value ?? ''}
				name={name}
				{...(hiddenLabel ? { 'aria-label': title } : {})}
				{...rest}
			/>
		</InputWrapper>
	)
}
