import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { OPPGAVEBEHANDLING_PATH } from 'navigation/navigationURLs'

import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { TopOfPageContent } from 'components/company-components/utility/TopOfPageContent'

import { CompanyList } from './CompanyList'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 20px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-block: 20px;
`

export const CompanyListPage = () => {
	const { t } = useTranslation('company')

	return (
		<Wrapper>
			<TopOfPageContent
				displayBackButton
				navigateBackToLink={OPPGAVEBEHANDLING_PATH}
			/>
			<HeaderText1>{t('companyList.title')}</HeaderText1>
			<CompanyList />
		</Wrapper>
	)
}
