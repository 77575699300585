import { backend } from 'api/backend'

import { SendRequestType } from 'types/SendRequestType'

/**
 * Function to post (create) a new request to backend. It creates a new
 * request when a request is sent to the user.
 *
 * @param request	The request to send
 */
export const postCreateRequest = (request: SendRequestType) =>
	backend.post('/portal/request', request)

/**
 * Function to put (update) an existing request to backend. It updates a
 * request, from "requested" to "reminded", when a reminder is sent to the user.
 *
 * @param request	The request to send
 */
export const putUpdateRequest = (request: SendRequestType) =>
	backend.put('/portal/request', request)

/**
 * Function to post a bulk of requests to backend.
 *
 * @param jsonFile the json file containing the bulks
 */
export const postBulkRequest = (jsonFile: File) => {
	const formData = new FormData()
	formData.append('json', jsonFile)

	return backend.post(`/portal/bulkrequest`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		responseType: 'arraybuffer'
	})
}
