/**
 * Maps a base64 string to a blob object url
 *
 * @param base64String the string to map
 */
export const mapBase64StringToPdfUrl = (base64String: string) => {
	// Convert base64 to arraybuffer
	const binaryString = window.atob(base64String)
	const bytes = new Uint8Array(binaryString.length)
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}

	// Make the pdf
	const pdfFile = new Blob([bytes.buffer], { type: 'application/pdf' })
	return URL.createObjectURL(pdfFile)
}
