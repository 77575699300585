import React, { ReactNode, useRef, useState } from 'react'

import styled, { css } from 'styled-components'

import { companyButtonStyle } from 'styles/button'

import { ButtonText as BaseButtonText } from 'components/company-components/text/ButtonText'
import { Icon, IconProps } from 'components/icon/Icon'

const ButtonIcon = styled(BaseButtonText)`
	color: inherit;
	font-size: 20px;
`

const ButtonText = styled(BaseButtonText)`
	margin-left: 5px;
	color: inherit;
`

interface IStyledButton {
	fillWidth?: boolean
}

const StyledButton = styled.button<IStyledButton>`
	${companyButtonStyle}

	${(props) => props?.disabled && 'opacity: 0.5;'};

	${(props) =>
		props.fillWidth
			? css`
					width: 100%;
			  `
			: css`
					width: auto;
			  `}

	&:hover {
		${({ color }) =>
			color === 'blue'
				? css`
						background-color: var(--color-main-blue_green);
						color: var(--color-white);
				  `
				: css`
						background-color: var(--color-yellow);
						color: var(--color-black);
				  `};
	}

	&:focus {
		background-color: var(--color-main-dark_blue);
		color: var(--color-white);
	}
`

interface Props extends React.ComponentPropsWithoutRef<'button'> {
	text?: string
	type?: 'button' | 'submit' | 'reset' | undefined
	onClick?: () => void
	hoverColor?: string
	icon?: IconProps
	fillWidth?: boolean
	children?: ReactNode
}

/**
 * Button component
 *
 * @param props.text The text on the button
 * @param props.type The type of button
 * @param props.onClick Function to handle button click
 * @param props.hoverColor To control the hover color - default is yellow
 * @param props.icon Icon of the button
 * @param props.fillWidth To control the width of the button
 * @param props.children React children
 */
export const Button = ({
	text,
	type = 'button',
	onClick,
	hoverColor,
	icon,
	fillWidth,
	children,
	...rest
}: Props) => {
	// To control the button colours
	const [isActive, setIsActive] = useState(false)
	const ref = useRef<any>(null)

	const getIsWhite = (): boolean => {
		return isActive && hoverColor === 'blue'
	}

	const color = getIsWhite() ? 'white' : undefined

	return (
		<StyledButton
			type={type}
			ref={ref}
			onClick={() => {
				ref.current && ref.current.blur()
				onClick && onClick()
			}}
			onMouseEnter={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
			color={hoverColor}
			fillWidth={fillWidth}
			{...rest}
		>
			{text ? (
				<>
					<ButtonIcon color={color}>
						{icon && <Icon name={icon.name} />}
					</ButtonIcon>
					<ButtonText color={color}>{text}</ButtonText>
				</>
			) : (
				children
			)}
		</StyledButton>
	)
}
