import styled from 'styled-components'
import { ReactNode } from 'react'

import { HeaderText2 as BaseHeaderText2 } from 'components/text/HeaderText2'

const Wrapper = styled.div``

interface IBodyWrapper {
	minHeight: string
}

const BodyWrapper = styled.div<IBodyWrapper>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: ${(props) => props.minHeight};
	background-color: var(--color-box);
	border-radius: 10px;
	padding-block: 20px;
	box-shadow: 0 0.5px 4px rgb(0, 0, 0, 25%);
`

const HeaderText2 = styled(BaseHeaderText2)`
	font-family: var(--font-family-opensans);
	font-weight: var(--font-weight-normal);
	text-align: center;
	margin-bottom: 20px;
`

interface Props {
	minHeight: string
	title: string
	children: ReactNode
}

/**
 * Displays a gray box around a child component
 *
 * @param props.minHeight the height of the box
 * @param props.title the title of the box
 * @param props.children react children
 */
export const DashboardBox = ({
	minHeight,
	title,
	children,
	...rest
}: Props) => {
	return (
		<Wrapper>
			<HeaderText2>{title}</HeaderText2>
			<BodyWrapper minHeight={minHeight} {...rest}>
				{children}
			</BodyWrapper>
		</Wrapper>
	)
}
