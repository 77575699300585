import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

import { loginRequest } from 'auth/authConfig'

import { LOGIN_PATH, OPPGAVEBEHANDLING_PATH } from 'navigation/navigationURLs'

import { Button as BaseButton } from 'components/company-components/button/Button'

const Button = styled(BaseButton)`
	padding-inline: 100px;
`

/**
 * Login page, triggers a login window using Active Directory
 */
export const LoginButton = () => {
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0])
	const isAuthenticated = useIsAuthenticated()
	const { t } = useTranslation('company')

	// To handle navigation
	const navigate = useNavigate()

	const handleLogout = async (instance: IPublicClientApplication) => {
		try {
			instance.logoutPopup({ account })
			console.log('Logged out successfully! Navigating to login page')
			navigate(LOGIN_PATH)
		} catch (e) {
			console.error(e)
			toast.error(<div className="dismissToast">Kunne ikke logge ut</div>)
		}
	}

	/**
	 * Function to handle login
	 * @param instance
	 */
	const handleLogin = (instance: IPublicClientApplication) => {
		instance
			.loginPopup(loginRequest)
			.then(() => {
				navigate(OPPGAVEBEHANDLING_PATH)
			})
			.catch((e) => {
				console.log({ e })
				console.error(e)
				if (e?.errorCode === 'interaction_in_progress') {
					toast.error(
						<div className="dismissToast">
							Du kan ikke logge inn mens du holder på med inn- eller
							utlogging
						</div>
					)
				} else {
					toast.error(
						<div className="dismissToast">Kunne ikke logge inn</div>
					)
				}
			})
	}

	/**
	 * Function to handle the click
	 * @param instance
	 * @param isAuthenticated
	 */
	const handleClick = (
		instance: IPublicClientApplication,
		isAuthenticated: boolean
	) => {
		isAuthenticated ? handleLogout(instance) : handleLogin(instance)
	}

	return (
		<Button
			text={t('login.button', { context: isAuthenticated ? 'out' : 'in' })}
			onClick={() => handleClick(instance, isAuthenticated)}
			hoverColor="blue"
			icon={
				!isAuthenticated
					? {
							name: 'Person'
					  }
					: undefined
			}
		/>
	)
}
