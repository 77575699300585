import React, { useEffect, useState } from 'react'
import { useMsal, useAccount } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { AxiosRequestConfig } from 'axios'
import { useNavigate } from 'react-router-dom'

import { backend } from 'api/backend'
import { loginRequest } from 'auth/authConfig'
import { LOGIN_PATH } from 'navigation/navigationURLs'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

interface RequestInterceptorProps {
	children: JSX.Element
}

/**
 * Component that wraps the entire app to intercept axios requests and add a token to the requests.
 * @param props.children React children
 */
export const RequestInterceptor: React.FC<RequestInterceptorProps> = ({
	children
}: RequestInterceptorProps) => {
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0])
	const [requestInterceptor, setRequestInterceptor] =
		useState<number | null>(null)
	const [responseInterceptor, setResponseInterceptor] =
		useState<number | null>(null)
	const navigate = useNavigate()
	const { t } = useTranslation('company')

	/**
	 * Sets up an interceptor, but also removes the interceptor again when we navigate to a non-auth page.
	 *
	 * This could potentially be a bit wonky when combined with pages that instantly call an endpoint, because that call could happen before the useEffect of the interceptor has been called. To mitigate this, we could do something weird, like providing context with a interceptorActive or something that can be checked for in those
	 */
	useEffect(() => {
		if (!account) {
			console.log('No active account yet! Trying to get the signed in user.')
		} else {
			const newRequestInterceptor = backend.interceptors.request.use(
				async (config: AxiosRequestConfig) => {
					console.log('Interceptor - bearer is now added to requests!')

					let accessToken = ''
					//const response =
					account &&
						(await instance
							.acquireTokenSilent({
								...loginRequest,
								account
							})
							.then((res) => {
								accessToken = res.idToken
							})
							.catch((err) => {
								if (err instanceof InteractionRequiredAuthError) {
									instance
										.acquireTokenSilent({ ...loginRequest, account })
										.then((res) => {
											accessToken = res.idToken
										})
										.catch((e) => {
											console.log({ e })
										})
								}
								console.log({ err })
							}))
					//Add bearer token to this request
					config.headers = config.headers ?? {}
					if (accessToken !== '')
						config.headers.Authorization = `Bearer ${accessToken}`

					return config
				}
			)
			setRequestInterceptor(newRequestInterceptor)
			const newResponseInterceptor = backend.interceptors.response.use(
				(response) => {
					return response
				},
				(error) => {
					/**
					 * If we encounter an error response, we check if it is a 401. If so, we must
					 * invalidate login status and navigate back to the login page
					 */
					console.log({ error })
					// eslint-disable-next-line eqeqeq
					if (error.code == '401' || error.response?.status == 401) {
						toast.error(t('login.timedOut'))
						instance.logoutRedirect({
							onRedirectNavigate: () => {
								navigate(LOGIN_PATH)
								return false
							}
						})
					}
					return Promise.reject(error)
				}
			)
			setResponseInterceptor(newResponseInterceptor)
			return () => {
				if (requestInterceptor !== null) {
					backend.interceptors.request.eject(requestInterceptor)
					setRequestInterceptor(null)
				}
				if (responseInterceptor !== null) {
					backend.interceptors.request.eject(responseInterceptor)
					setResponseInterceptor(null)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, accounts, instance])

	if (requestInterceptor == null) return null
	return children
}
