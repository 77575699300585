import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button as BaseButton } from 'components/company-components/button/Button'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'
import { Text as BaseText } from 'components/text/Text'

import { buttonStyleWhiteMedium } from 'styles/button'

import { Modal as BaseModal } from './Modal'

const Modal = styled(BaseModal)`
	z-index: 1000;
	width: 600px;
`

const ContentWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-around;
`

const Button = styled(BaseButton)`
	min-width: 200px;
`

const CancelButton = styled(BaseButton)`
	${buttonStyleWhiteMedium}
	min-width: 200px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	margin-bottom: 20px;
	text-align: center;
`

const Text = styled(BaseText)`
	margin-bottom: 30px;
	text-align: center;
`

interface Props {
	isOpen: boolean
	onClose: () => void
	onYes: () => void
	onCancel: () => void
	title: ReactNode
	text: ReactNode
}

/**
 * Reusable modal component
 * @param props.isOpen Whether to show the modal or not
 * @param props.onClose Function that is called from the modal when it should be closed
 * @param props.onGoBack Function that is called from the modal when it should go back to the previous content
 * @param props.shouldCloseOnOverlayClick flag to prevent close onclick on the outside of the modal
 */
export const ConfirmationModal = ({
	title,
	text,
	onYes,
	onCancel,
	...rest
}: Props) => {
	const { t } = useTranslation('company')

	return (
		<Modal {...rest}>
			<HeaderText1>{title}</HeaderText1>
			<Text>{text}</Text>
			<ContentWrapper>
				<CancelButton
					onClick={onCancel}
					text={t('confirmationModal.cancel')}
				/>
				<Button onClick={onYes} text={t('confirmationModal.ok')} />
			</ContentWrapper>
		</Modal>
	)
}
