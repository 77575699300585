import React, { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { Footer } from './Footer'
import { Header } from './Header'

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`

const Body = styled.main`
	flex: 1;
	display: grid;
	grid-template-columns: minmax(0, 1fr);
`

interface Props {
	children: ReactNode
}

/**
 * Layout component to display the Header, body and footer of a page
 *
 * @param props.children React children
 */
export const Layout = ({ children }: Props) => {
	const { t } = useTranslation('company')
	return (
		<Wrapper>
			<Helmet>
				<title>{t('header.titlebar')}</title>
			</Helmet>
			<Header />
			<Body>{children}</Body>
			<Footer />
		</Wrapper>
	)
}

export const LayoutOutlet = () => {
	return (
		<Layout>
			<Outlet />
		</Layout>
	)
}
