import { useState } from 'react'

import { BeneficiaryType } from 'types/BeneficiaryType'

import { SendRequestContainer } from './SendRequestContainer'
import { SuccessfullySentContainer } from './SuccessfullySentContainer'

interface ModalContentProps {
	type: 'request' | 'remind'
	beneficiary: BeneficiaryType
	onSuccess: () => void
	isSent: boolean
	time: number
	onlyOneChannel: 'sms' | 'email' | 'post' | undefined
}

/**
 * The contents of the modal on the life certificate page.
 *
 * @param props.type 'request' or 'remind', determines how to render some text strings
 * @param props.beneficiary object following the BeneficiaryType interface
 * @param props.closeModal callback that should close the modal when called
 * @param props.isSent flag for if the request/reminder has been sent
 * @param props.time the time for counter to count down before the modal is closed
 * @param props.onlyOneChannel the name of the channel if there only exists one channel type
 */
export const ModalContents = ({
	type,
	beneficiary,
	onSuccess,
	isSent,
	time,
	onlyOneChannel,
	...rest
}: ModalContentProps) => {
	const [selectedChannels, setSelectedChannels] = useState<
		('email' | 'sms' | 'post')[]
	>(onlyOneChannel ? [onlyOneChannel] : [])

	const commonProps = {
		type,
		selectedChannels,
		...rest
	}
	if (isSent)
		return (
			<>
				<SuccessfullySentContainer
					beneficiaryName={beneficiary.name}
					time={time}
					{...commonProps}
				/>
			</>
		)
	return (
		<SendRequestContainer
			beneficiary={beneficiary}
			onSuccess={onSuccess}
			onFailure={() => {}} //no need to handle this here currently - error is shown in the component
			setSelectedChannels={setSelectedChannels}
			{...commonProps}
		/>
	)
}
