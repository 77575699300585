import styled from 'styled-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextButton as BaseTextButton } from 'components/common/TextButton'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { LanguageDropDown } from 'components/common/LanguageDropDown'
import { HeaderText2 } from 'components/text/HeaderText2'
import { Icon as BaseIcon } from 'components/icon/Icon'

import mobileLogo from 'images/mobileLogo.svg'

import { BASE_BREAKPOINT_MOBILE } from 'styles/baseBreakpoints'

import { HelpModal } from './HelpModal'

const Wrapper = styled.header`
	position: sticky;
	top: -100px;
	overflow-y: visible;
	width: 100%;

	@media only screen and (min-width: ${BASE_BREAKPOINT_MOBILE}) {
		display: none;
	}
`

const InnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 20px;
	filter: drop-shadow(0 0 20px white) drop-shadow(0 0 10px white)
		drop-shadow(0 0 5px white);

	:before {
		content: '';
		position: absolute;
		top: -100px;
		left: -25%;
		bottom: 4px;
		width: 150%;
		background-color: var(--color-main-dark_blue);
		border-bottom-left-radius: 100%;
		border-bottom-right-radius: 100%;
		z-index: -1;
		box-shadow: 1px 1px 4px rgb(0, 0, 0, 25%);
	}
`

const TopWrapper = styled.div`
	position: absolute;
	top: 0;
	z-index: 2;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	/*margin-top: -30px;
	padding-top: 10px;*/
	padding-inline: 10px;
`

const TextButton = styled(BaseTextButton)`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const Image = styled.img`
	height: 22px;
	margin-right: 5px;
`

const Icon = styled(BaseIcon)`
	color: var(--color-main-turquoise);
	margin-right: 5px;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	font-size: var(--font-size-small-text);
`

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
`

/**
 * Component to display the header when the application is opened on a mobile phone
 */
export const PhoneHeader = () => {
	const [modalOpen, setModalOpen] = useState(false)
	const { t } = useTranslation('common')

	return (
		<Wrapper>
			<TopWrapper>
				<TextButton onClick={() => setModalOpen(true)}>
					<Icon name="QuestionMarkCircle" />
					<SubHeaderText as="p" color="white">
						{t('header.phone.help')}
					</SubHeaderText>
				</TextButton>
				<LanguageDropDown />
			</TopWrapper>
			<InnerWrapper>
				<LogoWrapper>
					<Image src={mobileLogo} alt={t('header.phone.finans_alt')} />
					<HeaderText2 as="h1" color="white">
						{t('header.phone.leveattest')}
					</HeaderText2>
				</LogoWrapper>
				<HelpModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
			</InnerWrapper>
		</Wrapper>
	)
}
