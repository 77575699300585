import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
	postCreateRequest,
	putUpdateRequest
} from 'api/services/RequestService'

import { BeneficiaryType } from 'types/BeneficiaryType'
import { SendRequestType } from 'types/SendRequestType'

import { useIsAdminRole } from 'hooks/useIsAdminRole'

import { buttonColorLight } from 'styles/button'

import { Spinner } from 'components/Spinner'
import { Button as BaseButton } from 'components/company-components/button/Button'
import { SubHeaderText as BaseSubHeaderText } from 'components/text/SubHeaderText'
import { Text as BaseText } from 'components/text/Text'
import { HeaderText1 as BaseHeaderText1 } from 'components/text/HeaderText1'

import { getPhoneNumberExists } from '../contactInformationValidators/getPhoneNumberExists'
import { getEmailAddressExists } from '../contactInformationValidators/getEmailAddressExists'
import { getPostAddressExists } from '../contactInformationValidators/getPostAddressExists'

import { ChannelBox } from './ChannelBox'

const Wrapper = styled.div`
	margin: 0 20%;
`

const Button = styled(BaseButton)`
	&:hover {
		${(props) => props.disabled && buttonColorLight}
	}
`

interface IChannelWrapper {
	length: number
}

/**
 * Channel wrapper which displays the boxes differently
 * depending on if there is 1, 2 or 3 boxes
 */
const ChannelWrapper = styled.div<IChannelWrapper>`
	display: flex;
	${(props) =>
		props.length === 1
			? css`
					justify-content: center;
			  `
			: props.length === 2
			? css`
					justify-content: space-around;
			  `
			: props.length === 3
			? css`
					justify-content: space-between;
			  `
			: null}
`

const RowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
`

const HeaderText1 = styled(BaseHeaderText1)`
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
`

const SubHeaderText = styled(BaseSubHeaderText)`
	margin: 10px 0 10px 0;
`

const Text = styled(BaseText)`
	margin: 10px 0 0 0;
`

interface Props {
	type: 'request' | 'remind'
	beneficiary: BeneficiaryType
	onSuccess: () => void
	onFailure: () => void
	selectedChannels: ('sms' | 'email' | 'post')[]
	setSelectedChannels: React.Dispatch<
		React.SetStateAction<('email' | 'sms' | 'post')[]>
	>
}

/**
 * Component to render the content of the modal before the request is sent
 *
 * @param props.type 'request' or 'remind', determines how to render some text strings
 * @param props.beneficiary object following the BeneficiaryType interface
 * @param props.onSuccess callback called when the request has succeeded
 * @param props.onFailure callback called when the request has failed
 * @param props.selectedChannels Which channels have been selected on the send request view
 * @param props.setSelectedChannels setState function to update the channel selections
 */
export const SendRequestContainer = ({
	type,
	beneficiary,
	onSuccess,
	onFailure,
	selectedChannels,
	setSelectedChannels
}: Props) => {
	const { isAdmin } = useIsAdminRole()
	const { t } = useTranslation('company')

	const typeContext = {
		context: type === 'remind' ? 'reminder' : ''
	}

	const [isLoading, setIsLoading] = useState(false)

	const onClickSendRequest = () => {
		// Check that at least one option is selected
		if (selectedChannels.length > 0) {
			sendRequest(type)
		} else {
			toast.error(
				<div className="dismissToast">
					{t('beneficiary.cert.send.errors.channel')}
				</div>
			)
		}
	}

	/**
	 * Function to send request
	 * @param type 'request' or 'remind
	 */
	const sendRequest = (type: 'request' | 'remind') => {
		if (type === 'request') {
			return postSendRequest()
		}
		if (type === 'remind') {
			return putSendReminder()
		}
	}

	// TODO - Handle the other cases
	/**
	 * Function to set the error message based on different status codes
	 * @param statusCode
	 */
	const getErrorBasedOnCode = (statusCode: number): ReactNode => {
		// TODO - 401 should log the user out
		if (statusCode === 401) return t('beneficiary.cert.send.errors.401')
		return (
			<div className="dismissToast">
				{t('beneficiary.cert.send.errors.generic')}
			</div>
		)
	}

	/**
	 * Ask backend to send a request
	 */
	const postSendRequest = () => {
		const sendRequest: SendRequestType = {
			beneficiaryId: beneficiary.id.toString(),
			channelNames: selectedChannels
		}
		if (!isAdmin) {
			setIsLoading(true)
			postCreateRequest(sendRequest)
				.then(() => {
					onSuccess()
					setIsLoading(false)
				})
				.catch((err) => {
					onFailure()
					setIsLoading(false)
					toast.error(getErrorBasedOnCode(err.response.status))
				})
		}
	}

	/**
	 * Ask backend to send a reminder
	 */
	const putSendReminder = () => {
		const sendRequest: SendRequestType = {
			beneficiaryId: beneficiary.id.toString(),
			channelNames: selectedChannels
		}
		if (!isAdmin) {
			setIsLoading(true)
			putUpdateRequest(sendRequest)
				.then(() => {
					onSuccess()
					setIsLoading(false)
				})
				.catch((err) => {
					onFailure()
					setIsLoading(false)
					toast.error(getErrorBasedOnCode(err.response.status))
				})
		}
	}

	/**
	 * Function to get the length of a channel.
	 * This is used to update the style of the channelboxes based on how
	 * many options there are
	 */
	const getChannelLength = (): number => {
		let lst: number[] = []
		if (getPhoneNumberExists(beneficiary.contact)) {
			lst.push(1)
		}
		if (getEmailAddressExists(beneficiary.contact)) {
			lst.push(2)
		}
		if (getPostAddressExists(beneficiary.contact)) {
			lst.push(3)
		}
		return lst.length
	}

	const channelBoxProps = {
		selectedChannels: selectedChannels,
		setSelectedChannels: setSelectedChannels,
		isOnlyOneBox: getChannelLength() === 1
	}

	/**
	 * If loading, display spinner, else display content
	 */
	const displayModalContent = () => {
		if (isLoading) {
			return <Spinner />
		}
		return (
			<>
				<SubHeaderText>
					{t('beneficiary.cert.send.channel.title')}
				</SubHeaderText>
				<ChannelWrapper length={getChannelLength()}>
					{getPhoneNumberExists(beneficiary.contact) && (
						<ChannelBox
							channelName="sms"
							channelIcon={{
								name: 'PhoneCompany'
							}}
							{...channelBoxProps}
						/>
					)}
					{getEmailAddressExists(beneficiary.contact) && (
						<ChannelBox
							channelName="email"
							channelIcon={{
								name: 'Mail'
							}}
							{...channelBoxProps}
						/>
					)}
					{getPostAddressExists(beneficiary.contact) && (
						<ChannelBox
							channelName="post"
							channelIcon={{
								name: 'MailBox'
							}}
							{...channelBoxProps}
						/>
					)}
				</ChannelWrapper>
				<ButtonWrapper>
					<Button
						text={t('beneficiary.cert.send.button', typeContext)}
						onClick={onClickSendRequest}
						icon={{ name: 'SendPlane' }}
						fillWidth
						disabled={selectedChannels.length === 0}
					/>
				</ButtonWrapper>
			</>
		)
	}

	return (
		<Wrapper aria-live="polite" aria-busy={isLoading}>
			<HeaderText1>
				{t('beneficiary.cert.send.title', typeContext)}
			</HeaderText1>
			<RowWrapper>
				<SubHeaderText>{t('beneficiary.cert.send.subtitle')}</SubHeaderText>
				<Text>{beneficiary.name}</Text>
			</RowWrapper>
			{displayModalContent()}
		</Wrapper>
	)
}
